import React, {Component} from 'react'
import styles from './Colleges.css'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Banner from '../../components/Banner/Banner'
import '../../config'
import {connect} from 'react-redux';
import {getSchoolList} from '../../model/actions/actionCreator'

class Colleges extends Component {
  constructor(props){
    super(props)
    this.state = {
      school:[],
      degree:[], // 学位
      major:[], // 专业
      school_type:[], // 学校类型
      dIndex:0,
      degree_ids:[],
      school_types:[],
      major_id:0,
      country_id:0,
      area_id:0,
      page:1,
      countryArr:[],
      provinceArr:[],
      country:'国家',
      province:'省或州',
      counShow:false,
      provShow:false,
      last_page:1,
      current_page:1,
      pageList: [],
      bannerArr: []
    }
    this.toChoose = this.toChoose.bind(this)
    this.areaFn = this.areaFn.bind(this)
    this.counChoose = this.counChoose.bind(this)
    this.provShow = this.provShow.bind(this)
    this.provChoose = this.provChoose.bind(this)
    this.provItemChoose = this.provItemChoose.bind(this)
  }
  componentDidMount(){
    // banner
    fetch(global.constants.api_url + '/v1/banner/list?location=12', {
      method: 'GET'
  })
  .then( response => {
      response.json().then( data => {
      this.setState({
          bannerArr: data.data
      })
      })
  })
  .catch( error => {
      console.log(error)
  })
    //this.props.getSchool()
    let {country_id, area_id, degree_ids, school_types, major_id, page} = this.state
    window.scrollTo(0, 0)
    document.addEventListener('click', ()=>{
      this.setState({
        provShow: false,
        counShow: false
      })
    }, false)
    this.schoolList(country_id, area_id, degree_ids, school_types, major_id, page)
    
    // 学校筛选条件
    fetch(global.constants.api_url+'/v1/school/condition',{
      method: 'GET',
    })
    .then((response)=>{
      response.json().then((data) => {
        this.setState({
          degree: data.data.degree,
          major: data.data.major,
          school_type: data.data.school_type
        })
      })
    })
    .catch((error)=>{
      console.log(error)
    })
  }
  schoolList(country_id, area_id, degree_ids, school_types, major_id, page){
    // 学校列表
    let p = new Promise((resolve, reject) => {
      fetch(global.constants.api_url+'/v1/school/query?country_id='+ country_id +'&area_id='+ area_id +'&degree_ids='+ degree_ids +'&school_types='+ school_types +'&major_id='+ major_id +'&page='+ page,{
        method: 'GET'
      })
      .then((response) => {
        response.json().then((data) => {
          this.setState({
            school: data.data.data,
            current_page: data.data.current_page,
            last_page: data.data.last_page
          })
          let li = []
          for( var i=0; i<this.state.last_page; i++){
            li.push(i+1)
          }
          this.setState({
            pageList: li
          })
          resolve()
        })
      })
      .catch((error) => {
        console.log(error)
      })
    })
    return p
  }
  toChoose(e){
    const { degree_ids, school_types } = this.state
    if(e.target.getAttribute('data-did')){
      let dIndex = parseInt(e.target.getAttribute('data-did'))
      if(degree_ids.indexOf(dIndex) > -1){
        degree_ids.splice(degree_ids.indexOf(dIndex), 1)
      }else{
        degree_ids.push(dIndex)
      }
      this.setState({
        degree_ids
      }, ()=>{
        const { country_id, area_id, degree_ids, school_types, major_id, page } = this.state
        this.schoolList(country_id, area_id, degree_ids, school_types, major_id, page)
      })
    }else if(e.target.getAttribute('data-sid')){
      let sIndex = parseInt(e.target.getAttribute('data-sid'))
      if(school_types.indexOf(sIndex) > -1){
        school_types.splice(school_types.indexOf(sIndex), 1)
      }else{
        school_types.push(sIndex)
      }
      this.setState({
        school_types
      }, ()=>{
        const { country_id, area_id, degree_ids, school_types, major_id, page } = this.state
        this.schoolList(country_id, area_id, degree_ids, school_types, major_id, page)
      })
    }else if(e.target.getAttribute('data-mid')){
      let mIndex = parseInt(e.target.getAttribute('data-mid'))
      this.setState({
        major_id: mIndex
      }, ()=>{
        const { country_id, area_id, degree_ids, school_types, major_id, page } = this.state
        this.schoolList(country_id, area_id, degree_ids, school_types, major_id, page)
      })
    }
    
  }
  counChoose(){
    this.areaFn(0).then((data) => {
      this.setState({
        countryArr: data,
        counShow: true
      })
    })
  }
  provShow(e){
    let id = e.target.getAttribute('data-id'),
        name = e.target.getAttribute('data-name')
    this.areaFn(id).then((data) => {
      this.setState({
        provinceArr: data,
        country: name,
        country_id: id,
        counShow: false,
        province: '省或州'
      }, () => {
        const { country_id, area_id, degree_ids, school_types, major_id, page } = this.state
        this.schoolList(country_id, area_id, degree_ids, school_types, major_id, page)
      })
    })
  }
  provItemChoose(e){
    let name = e.target.getAttribute('data-name'),
        id = e.target.getAttribute('data-id')
    this.setState({
      province: name,
      area_id: id,
      provShow: false
    }, () => {
      const { country_id, area_id, degree_ids, school_types, major_id, page } = this.state
      this.schoolList(country_id, area_id, degree_ids, school_types, major_id, page)
    })
  }
  provChoose(e){
    e.nativeEvent.stopImmediatePropagation()
    this.setState({
      provShow: true
    })
  }
  areaFn(id){
    var p = new Promise((resolve, reject) => {
      fetch(global.constants.api_url+'/v1/school/area?id='+id,{
        method: 'GET'
      })
      .then( response => {
        response.json().then((data) => {
          resolve(data.data)
        })
      })
      .catch((error)=>{
        console.log(error)
      })
    })
    return p
  }
  pageHandle(e){
    let p = parseInt(e.target.getAttribute('data-index'))+1
    this.setState({
      page: p
    }, () => {
      const {country_id, area_id, degree_ids, school_types, major_id, page} = this.state
      this.schoolList(country_id, area_id, degree_ids, school_types, major_id, page)
    })
    
  }
  prevPage(){
    let p = this.state.page
    if(p>1){
      p --
      this.setState({
        page: p
      }, () => {
        const {country_id, area_id, degree_ids, school_types, major_id, page} = this.state
        this.schoolList(country_id, area_id, degree_ids, school_types, major_id, page)
      })
    }
  }
  nextPage(){
    let p = this.state.page
    if(p < this.state.last_page){
      p ++
      this.setState({
        page: p
      }, () => {
        const {country_id, area_id, degree_ids, school_types, major_id, page} = this.state
        this.schoolList(country_id, area_id, degree_ids, school_types, major_id, page)
      })
    }
  }
  getOne(){
    this.props.store.dispatch((dispatch, state) => {
      setTimeout(function(){
        dispatch({
          type: 'ADD_ONE',
          payload: 1
        })
      },2000)
    })
  }
  render () {
    const { school, degree, major, school_type, countryArr, provinceArr, counShow, provShow, pageList, page, last_page, current_page } = this.state
    //console.log(this.props.school)
    return (
      <div className={styles.bgff}>
        <Header />
        {/* banner */}
        <div className='banner'>
        <Banner banner={this.state.bannerArr}/>
        </div>
        <div className={styles.filter}>
          <div className={styles.wrap}>
            <div className={`${styles['filter-item']}`}>
              <div className={styles.left}>院校位置:</div>
              <div className={styles.right}>
                <div className={styles.place}>
                  <div className={styles.name} onClick={this.counChoose}>{this.state.country}</div>
                  <ul style={{'display': counShow ? 'block' : 'none'}}>
                    {
                      countryArr.map((item, index)=>{
                        return <li onClick={this.provShow} data-id={item.id} data-name={item.name} key={index}>{item.name}</li>
                      })
                    }
                  </ul>
                </div>
                <div className={styles.place}>
                  <div className={styles.name} onClick={this.provChoose}>{this.state.province}</div>
                  <ul style={{'display': provShow ? 'block' : 'none'}}>
                    {
                      provinceArr.map((item, index) => {
                        return <li onClick={this.provItemChoose} key={index} data-id={item.id} data-name={item.name}>{item.name}</li>
                      })
                    }
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles['filter-item']}>
              <div className={styles.left}>学 位:</div>
              <div className={styles.right}>
                {
                  degree.map((item, index)=>{
                    return (
                      <span onClick={this.toChoose} style={{'border': this.state.degree_ids.indexOf(item.id) > -1 ? '1px solid #fc9a30' : ''}} key={index} data-did={item.id}>{item.degree_name}</span>
                    )
                  })
                }
              </div>
            </div>
            <div className={styles['filter-item']}>
              <div className={styles.left}>学校类型:</div>
              <div className={styles.right}>
                {
                  school_type.map((item, index)=>{
                    return (
                      <span onClick={this.toChoose} key={index} style={{'border': this.state.school_types.indexOf(item.id) > -1 ? '1px solid #fc9a30' : ''}} data-sid={item.id}>{item.name}</span>
                    )
                  })
                }
              </div>
            </div>
            <div className={styles['filter-item']}>
              <div className={styles.left}>专 业:</div>
              <div className={styles.right}>
                {
                  major.map((item, index)=>{
                    return <span onClick={this.toChoose} key={index} style={{'border': this.state.major_id === item.id ? '1px solid #fc9a30' : ''}} data-mid={item.id}>{item.name}</span>
                  })
                }
              </div>
            </div>
          </div>
        </div>
        <div className={styles['school-list']}>
          {
            school.map((item, index)=>{
              return (
                <div className={styles.school} key={index}>
                  <div className={styles.info}>
                    <div className={styles.head}>
                      <img src={item.logo} alt=""/>
                      <div className={styles.name}>
                        <p>{item.name}</p>
                        <span>{item.en_name}</span>
                      </div>
                    </div>
                    <p className={styles.line}><span>学校类型：</span><strong>{item.school_type}</strong></p>
                    <p className={styles.line}><span>所在位置：</span><strong>{item.area}</strong></p>
                    <p className={styles.line}><span>学校网址：</span><strong><a href={item.school_url} target="_blank" rel="noopener noreferrer">{item.school_url}</a></strong></p>
                  </div>
                  <div className={styles.pic}>
                    <img src={item.image} alt=""/>
                  </div>
                </div>
              )
            })
          }
        </div>
        <div className="page">
          <div className="page-num">
            <span className={`pn-prev ${page === 1 ? 'on' : ''}`} onClick={this.prevPage.bind(this)}>上一页</span>
            <ul>
              {
                pageList.map((item, index) => {
                  return <li onClick={this.pageHandle.bind(this)} key={index} className={parseInt(current_page) === index+1 ? 'on' : ''} data-index={index}>{item}</li>
                })
              }
            </ul>
            <span className={`pn-next ${page === last_page ? 'on' : ''}`} onClick={this.nextPage.bind(this)}>下一页</span>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

// mapStateToProps
const mapStateToProps =(state)=> {
  return {
      school: state.schoolData
  }
}
// mapDispatchToProps
const mapDispatchToProps =(dispatch)=> {
  return {
      getSchool: ()=>{
        dispatch(getSchoolList())
      }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Colleges)