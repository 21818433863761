import React, { Component } from 'react'
import styles from './Offer.css'
import Swiper from 'swiper/dist/js/swiper.js'
import 'swiper/dist/css/swiper.min.css'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Banner from '../../components/Banner/Banner'

class Offer extends Component {
  constructor(props){
    super(props)
    this.state = {
      videoUrl:'',
      videoIsShow:false,
      bannerArr: []
    }
  }
  componentDidMount(){
    window.scrollTo(0, 0)
    // banner
    fetch(global.constants.api_url + '/v1/banner/list?location=9', {
      method: 'GET'
    })
    .then( response => {
        response.json().then( data => {
        this.setState({
            bannerArr: data.data
        })
        })
    })
    .catch( error => {
        console.log(error)
    })
    // 学校OFFER
    new Swiper('.swiper-container-s1', {
      slidesPerView: 4,
      slidesPerColumn: 2,
      spaceBetween: 20,
      pagination: {
        el: '.swiper-pagination-s1',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    })
    // 学员OFFER
    new Swiper('.swiper-container-stu', {
      slidesPerView: 5,
      slidesPerColumn: 2,
      spaceBetween: 20,
      pagination: {
        el: '.swiper-pagination-stu',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next-stu',
        prevEl: '.swiper-button-prev-stu',
      }
    })
    new Swiper('.swiper-container-xyvideo', {
      centeredSlides: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }
  toVideo(e){
    let url = e.target.getAttribute('data-video')
    this.setState({
      videoUrl: url,
      videoIsShow:true
    })
  }
  closeVideo(e){
    this.setState({
      videoUrl: '',
      videoIsShow: false
    })
  }
  render(){
    const { videoUrl, videoIsShow } =  this.state
    return(
      <div className="bgff">
        {/* 头部 */}
        <Header />
        {/* banner */}
        <div className='banner'>
        <Banner banner={this.state.bannerArr}/>
        </div>
        <div className={styles.bg333}>
        <div className="main">
          <div className="module-title">
              <strong>名校OFFER榜</strong>
              <p className="subhead">BEST MUSIC SCHOOL OFFERS</p>
          </div>
        </div>
        </div>
        {/* 名校 */}
        <div className={styles['school-bg']}>
          <div className={styles.school}>
            <div className="main">
              <div className={`list-four list4`}>
                <div className="swiper-container-s1">
                  <ul className="swiper-wrapper">
                    <li className="swiper-slide">
                      <div className="pic">
                        <img src={require('../../assets/images/sch1.jpg')} alt=""/>
                      </div>
                      <div className="info">
                        <img className="logo" src={require('../../assets/images/sch-logo1.jpg')} alt=""/>
                        <p>曼哈顿音乐学院</p>
                        <p>Manhattan School of Music</p>
                        <span className="line"></span>
                        <p>录取人数12人</p>
                      </div>
                    </li>
                    <li className="swiper-slide">
                      <div className="pic">
                        <img src={require('../../assets/images/sch2.jpg')} alt=""/>
                      </div>
                      <div className="info">
                        <img className="logo" src={require('../../assets/images/sch-logo2.jpg')} alt=""/>
                        <p>新英格兰音乐学院</p>
                        <p>The New England Conservatory of Music</p>
                        <span className="line"></span>
                        <p>录取人数12人</p>
                      </div>
                    </li>
                    <li className="swiper-slide">
                      <div className="pic">
                        <img src={require('../../assets/images/sch3.jpg')} alt=""/>
                      </div>
                      <div className="info">
                        <img className="logo" src={require('../../assets/images/sch-logo3.jpg')} alt=""/>
                        <p>旧金山音乐学院</p>
                        <p>San Francisco Conservatory of Music</p>
                        <span className="line"></span>
                        <p>录取人数13人</p>
                      </div>
                    </li>
                    <li className="swiper-slide">
                      <div className="pic">
                        <img src={require('../../assets/images/sch4.jpg')} alt=""/>
                      </div>
                      <div className="info">
                        <img className="logo" src={require('../../assets/images/sch-logo4.jpg')} alt=""/>
                        <p>茱莉亚学院</p>
                        <p>The Juilliard School</p>
                        <span className="line"></span>
                        <p>录取人数8人</p>
                      </div>
                    </li>
                    <li className="swiper-slide">
                      <div className="pic">
                        <img src={require('../../assets/images/sch5.jpg')} alt=""/>
                      </div>
                      <div className="info">
                        <img className="logo" src={require('../../assets/images/sch-logo5.jpg')} alt=""/>
                        <p>伯克利音乐学院</p>
                        <p>Berklee College of Music</p>
                        <span className="line"></span>
                        <p>录取人数12人</p>
                      </div>
                    </li>
                    <li className="swiper-slide">
                      <div className="pic">
                        <img src={require('../../assets/images/sch6.jpg')} alt=""/>
                      </div>
                      <div className="info">
                        <img className="logo" src={require('../../assets/images/sch-logo6.jpg')} alt=""/>
                        <p>波士顿大学</p>
                        <p>Boston University</p>
                        <span className="line"></span>
                        <p>录取人数12人</p>
                      </div>
                    </li>
                    <li className="swiper-slide">
                      <div className="pic">
                        <img src={require('../../assets/images/sch7.jpg')} alt=""/>
                      </div>
                      <div className="info">
                        <img className="logo" src={require('../../assets/images/sch-logo7.jpg')} alt=""/>
                        <p>纽约大学音乐学院</p>
                        <p>New York University Music and Performing Arts Professions</p>
                        <span className="line"></span>
                        <p>录取人数9人</p>
                      </div>
                    </li>
                    <li className="swiper-slide">
                      <div className="pic">
                        <img src={require('../../assets/images/sch8.jpg')} alt=""/>
                      </div>
                      <div className="info">
                        <img className="logo" src={require('../../assets/images/sch-logo8.jpg')} alt=""/>
                        <p>耶鲁大学</p>
                        <p>Yale University</p>
                        <span className="line"></span>
                        <p>录取人数13人</p>
                      </div>
                    </li>
                  </ul>
                  <div className={['swiper-pagination swiper-pagination-s1']}></div>
                  <div className="swiper-button-next"></div>
                  <div className="swiper-button-prev"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wrap">
          {/* 访谈视频 */}
          <div className="module-title">
            <strong className="col333">访谈视频</strong>
            <p className="subhead">VIDEO INTERVIEW</p>
          </div>
        </div>
        <div className={styles['video-list']}>
          <div className="swiper-container-xyvideo">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className={styles.videos}>
                  <div className={styles.nr}>
                    <div className={styles.pic}>
                      <img src='http://imagecdn.yinyuewujie.com/FjVtoddK_i1JT5RISvfUgxRXpb6Q.png' data-video='http://cdn.yinyuewujie.com/lg3tBSUBhvGpoK39Qv71aNMxl-q-.mp4' onClick={this.toVideo.bind(this)} alt='img'/>
                    </div>
                    <div className={styles.bz}>
                      <span>罗斯福大学芝加哥表演艺术学院-亚当·内曼（Adam Neiman）-访谈视频</span>
                      <div className={styles.playbtn} data-video='http://cdn.yinyuewujie.com/lg3tBSUBhvGpoK39Qv71aNMxl-q-.mp4' onClick={this.toVideo.bind(this)}></div>
                    </div>
                  </div>
                  <div className={styles.nr}>
                    <div className={styles.pic}>
                      <img src='http://imagecdn.yinyuewujie.com/FsgB6fOWz3miCJyc-DiHprwI-tkj.png' data-video='http://cdn.yinyuewujie.com/lrwR0KnZP4clGr_ABm6Lvfpm0JW-.mp4' onClick={this.toVideo.bind(this)} alt='img'/>
                    </div>
                    <div className={styles.bz}>
                      <span>德克萨斯基督大学（沃思堡）-塔玛什·翁格（Tamás Ungár）-访谈视频</span>
                      <div className={styles.playbtn} data-video='http://cdn.yinyuewujie.com/lrwR0KnZP4clGr_ABm6Lvfpm0JW-.mp4' onClick={this.toVideo.bind(this)}></div>
                    </div>
                  </div>
                  <div className={styles.nr}>
                    <div className={styles.pic}>
                      <img src='http://imagecdn.yinyuewujie.com/FlNt1oQH1SGNtaUkXo1lRuVq1uJh.png' data-video='http://cdn.yinyuewujie.com/lvErcjAgDOj7XK3roYkH7Hdl9wjq.mp4' onClick={this.toVideo.bind(this)} alt='img'/>
                    </div>
                    <div className={styles.bz}>
                      <span>波士顿大学音乐学院-吉拉·戈德斯坦（Gila Goldstein）-访谈视频</span>
                      <div className={styles.playbtn} data-video='http://cdn.yinyuewujie.com/lvErcjAgDOj7XK3roYkH7Hdl9wjq.mp4' onClick={this.toVideo.bind(this)}></div>
                    </div>
                  </div>
                  <div className={styles.nr}>
                    <div className={styles.pic}>
                      <img src={require('../../assets/images/v1.png')} data-video='http://cdn.yinyuewujie.com/lpjEjrdMZn7zL7rksmwNRiVmXyBc.mp4' onClick={this.toVideo.bind(this)} alt='img'/>
                    </div>
                    <div className={styles.bz}>
                      <span>中佛罗里达州立大学-许韵玲-访谈视频</span>
                      <div className={styles.playbtn} data-video='http://cdn.yinyuewujie.com/lpjEjrdMZn7zL7rksmwNRiVmXyBc.mp4' onClick={this.toVideo.bind(this)}></div>
                    </div>
                  </div>
                  <div className={styles.nr}>
                    <div className={styles.pic}>
                      <img src={require('../../assets/images/v2.png')} data-video='http://cdn.yinyuewujie.com/lvcABAcwyaCimE-0XwCzJjf1eAA4.mp4' onClick={this.toVideo.bind(this)} alt='img'/>
                    </div>
                    <div className={styles.bz}>
                      <span>俄克拉荷马大学-韩贞媛-访谈视频</span>
                      <div className={styles.playbtn} data-video='http://cdn.yinyuewujie.com/lvcABAcwyaCimE-0XwCzJjf1eAA4.mp4' onClick={this.toVideo.bind(this)}></div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
            <div className="swiper-button-next"></div>
            <div className="swiper-button-prev"></div>
          </div>
          
        </div>
        {/* 学员 */}
        <div className={styles.bgf0}>
        <div className="main">
          <div className="module-title">
              <strong className='col333'>更多学员offer</strong>
              <p className="subhead">MORE OFFER</p>
          </div>  
        </div>
        </div>
        <div className={styles['student-bg']}>
          <div className="main">
            <div className={`list-five list2`}>
              <div className="swiper-container-stu">
                <ul className="swiper-wrapper">
                  <li className="swiper-slide">
                    <div className="pic">
                      <img src={require('../../assets/images/stu13.jpg')} alt=""/>
                    </div>
                    <div className="info">
                      <p className="border-bot">刘**</p>
                      <p>专业：伯克利波士顿音乐学院</p>
                      <p>学校：声乐本科</p>
                    </div>
                  </li>
                  <li className="swiper-slide">
                    <div className="pic">
                      <img src={require('../../assets/images/stu14.jpg')} alt=""/>
                    </div>
                    <div className="info">
                      <p className="border-bot">冯**</p>
                      <p>专业：曼尼斯音乐学院</p>
                      <p>学校：小提琴本科</p>
                    </div>
                  </li>
                  <li className="swiper-slide">
                    <div className="pic">
                      <img src={require('../../assets/images/stu15.jpg')} alt=""/>
                    </div>
                    <div className="info">
                      <p className="border-bot">李**</p>
                      <p>专业：曼哈顿音乐学院</p>
                      <p>学校：钢琴本科</p>
                    </div>
                  </li>
                  <li className="swiper-slide">
                    <div className="pic">
                      <img src={require('../../assets/images/stu16.jpg')} alt=""/>
                    </div>
                    <div className="info">
                      <p className="border-bot">王**</p>
                      <p>专业：纽约大学</p>
                      <p>学校：钢琴本科</p>
                    </div>
                  </li>
                  <li className="swiper-slide">
                    <div className="pic">
                      <img src={require('../../assets/images/stu17.jpg')} alt=""/>
                    </div>
                    <div className="info">
                      <p className="border-bot">李**</p>
                      <p>专业：南加州大学</p>
                      <p>学校：大提琴本科</p>
                    </div>
                  </li>
                  <li className="swiper-slide">
                    <div className="pic">
                      <img src={require('../../assets/images/st8.jpg')} alt=""/>
                    </div>
                    <div className="info">
                      <p className="border-bot">乔**</p>
                      <p>专业：克利夫兰音乐学院</p>
                      <p>学校：声乐本科</p>
                    </div>
                  </li>
                  <li className="swiper-slide">
                    <div className="pic">
                      <img src={require('../../assets/images/stu19.jpg')} alt=""/>
                    </div>
                    <div className="info">
                      <p className="border-bot">易**</p>
                      <p>专业：伯克利音乐学院</p>
                      <p>学校：作曲本科</p>
                    </div>
                  </li>
                  <li className="swiper-slide">
                    <div className="pic">
                      <img src={require('../../assets/images/st1.jpg')} alt=""/>
                    </div>
                    <div className="info">
                      <p className="border-bot">张**</p>
                      <p>专业：茱莉亚音乐学院</p>
                      <p>学校：钢琴预科</p>
                    </div>
                  </li>
                  <li className="swiper-slide">
                    <div className="pic">
                      <img src={require('../../assets/images/st2.jpg')} alt=""/>
                    </div>
                    <div className="info">
                      <p className="border-bot">左**</p>
                      <p>专业：伯克利波士顿音乐学院</p>
                      <p>学校：声乐硕士</p>
                    </div>
                  </li>
                  <li className="swiper-slide">
                    <div className="pic">
                      <img src={require('../../assets/images/st3.jpg')} alt=""/>
                    </div>
                    <div className="info">
                      <p className="border-bot">张**</p>
                      <p>专业：新英格兰音乐学院</p>
                      <p>学校：声乐本科</p>
                    </div>
                  </li>
                </ul>
                <div className={['swiper-pagination swiper-pagination-stu']}></div>
                <div className={styles.button}>
                  <div className={['swiper-button-prev swiper-button-prev-stu']}></div>
                  <div className={['swiper-button-next swiper-button-next-stu']}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {/* 底部 */}
        <Footer />

        {/* 视频弹层 */}
        <div className={styles.popbg} hidden={!videoIsShow}>
          <div className={styles.videobox}>
            <video src={videoUrl} controls="controls"></video>
            <div className={styles['video-close']} onClick={this.closeVideo.bind(this)}></div>
          </div>
        </div>
      </div>
    )
  }
}
export default Offer