import React, { Component } from 'react'
// import Slider from "react-slick"
import styles from './Service.css'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Banner from '../../components/Banner/Banner'

import Swiper from 'swiper/dist/js/swiper.js'
import 'swiper/dist/css/swiper.min.css'

class Service extends Component {
  constructor(props){
    super(props)
    this.state = {
      vipSrvice: 'zuixiang',
      bannerArr: [],
      offerUrl: '',
      offerShow: false
    }
  }
  componentDidMount(){
    window.scrollTo(0, 0)
    // banner
    fetch(global.constants.api_url + '/v1/banner/list?location=7', {
      method: 'GET'
    })
    .then( response => {
      response.json().then( data => {
        this.setState({
          bannerArr: data.data
        })
      })
    })
    .catch( error => {
      console.log(error)
    })
    new Swiper('.swiper-container-offer',{
      //autoplay: true,
      speed:1000,
      autoplayDisableOnInteraction : false,
      loop:true,
      spaceBetween: 35,
      centeredSlides : true,
      pagination: {
        el: '.swiper-pagination-offer',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    })
  }
  handleVipService(e){
    let type = e.currentTarget.getAttribute('data-type')
    this.setState({
      vipSrvice: type
    })
  }
  handleOffer(e){
    let url = e.currentTarget.getAttribute('data-offer')
    this.setState({
      offerUrl: url,
      offerShow: true
    })
    console.log(url)
  }
  closeOffer(){
    this.setState({
      offerShow: false
    })
  }
  render(){
    const { vipSrvice, offerShow, offerUrl } = this.state
    return (
      <div className={styles.bgff}>
        {/* 头部 */}
        <Header />
        {/* banner */}
        <div className='banner'>
        <Banner banner={this.state.bannerArr}/>
          {/* <Slider {...settings}>
            <div className={styles.banner1}>
            </div>
            <div className={styles.banner2}>
            </div>
          </Slider> */}
        </div>
        {/* 办理国家 */}
        <div className={styles.serbg1}>
          <div className={styles.main}>
            <div className="module-title">
              <strong className='col333'>申请国家</strong>
              <p className="subhead">APPLY FOR COUNTRY</p>
            </div>
            <div className={styles.countries}>
              <img src={require('../../assets/images/country1.png')} alt='img'/>
              <img src={require('../../assets/images/country2.png')} alt='img'/>
              <img src={require('../../assets/images/country3.png')} alt='img'/>
            </div>
          </div>
        </div>
        <div className={styles.main}>
          <div className="module-title">
            <strong className='col333'>申请学位</strong>
            <p className="subhead">APPLY FOR DEGREE</p>
          </div>
          <div className={styles.recom}>
            <div className={styles.row}>
              <div className={styles.item}>
                <div className={styles.pic}>
                  <img src={require('../../assets/images/xw1.png')} alt='img'/>
                  <div className={styles.mark}>
                    <p>音乐高中</p>
                    <span>Music high school</span>
                  </div>
                  <div className={styles.btn}>音乐高中</div>
                </div>
                <div className={styles.line}></div>
              </div>
              <div className={styles.item}>
                <div className={styles.pic}>
                  <img src={require('../../assets/images/xw2.png')} alt='img'/>
                  <div className={styles.mark}>
                    <p>预科</p>
                    <span>Preparatory course</span>
                  </div>
                  <div className={styles.btn}>预科</div>
                </div>
                <div className={styles.line}></div>
              </div>
              <div className={styles.item}>
                <div className={styles.pic}>
                  <img src={require('../../assets/images/xw3.png')} alt='img'/>
                  <div className={styles.mark}>
                    <p>本科</p>
                    <span>Undergraduate</span>
                  </div>
                  <div className={styles.btn}>本科</div>
                </div>
                <div className={styles.line}></div>
              </div>
              <div className={styles.item}>
                <div className={styles.pic}>
                  <img src={require('../../assets/images/xw4.png')} alt='img'/>
                  <div className={styles.mark}>
                    <p>硕士</p>
                    <span>master</span>
                  </div>
                  <div className={styles.btn}>硕士</div>
                </div>
                <div className={styles.line}></div>
              </div>
              <div className={styles.item}>
                <div className={styles.pic}>
                  <img src={require('../../assets/images/xw5.png')} alt='img'/>
                  <div className={styles.mark}>
                    <p>博士</p>
                    <span>doctor</span>
                  </div>
                  <div className={styles.btn}>博士</div>
                </div>
                <div className={styles.line}></div>
              </div>
            </div>
          </div>
          {/* LBM留学服务 */}
          <div className={styles.lxser}>
            <div className={styles.left}>
              <img src={require('../../assets/images/lx.png')} alt='img'/>
            </div>
            <div className={styles.right}>
              <div className={styles.tit}>
                <img src={require('../../assets/images/ser-tit1.png')} alt='img'/>
              </div>
              <div className={styles.serlist}>
                <ul>
                  <li className={styles.item1}></li>
                  <li className={styles.item2}></li>
                  <li className={styles.item3}></li>
                  <li className={styles.item4}></li>
                  <li className={styles.item5}></li>
                  <li className={styles.item6}></li>
                  <li className={styles.item7}></li>
                  <li className={styles.item8}></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* 音乐留学专业推荐 */}
        <div className={styles.major}>
          <div className={styles.main}>
            <div className="module-title">
              <strong>音乐留学专业推荐</strong>
              <p className="subhead">RECOMMENDATION FOR AREAS OF STUDY</p>
            </div>
            <div className={styles.majorList}>
              <div className={styles.row}>
                <div className={styles.item}>
                  <div className={styles.pic}>
                    <img src={require('../../assets/images/mjimg1.png')} alt='img'/>
                    <div className={styles.btn}>声乐</div>
                  </div>
                  <div className={styles.line}></div>
                </div>
                <div className={styles.item}>
                  <div className={styles.pic}>
                    <img src={require('../../assets/images/mjimg2.png')} alt='img'/>
                    <div className={styles.btn}>音乐剧</div>
                  </div>
                  <div className={styles.line}></div>
                </div>
                <div className={styles.item}>
                  <div className={styles.pic}>
                    <img src={require('../../assets/images/mjimg3.png')} alt='img'/>
                    <div className={styles.btn}>钢琴</div>
                  </div>
                  <div className={styles.line}></div>
                </div>
                <div className={styles.item}>
                  <div className={styles.pic}>
                    <img src={require('../../assets/images/mjimg4.png')} alt='img'/>
                    <div className={styles.btn}>流行表演</div>
                  </div>
                  <div className={styles.line}></div>
                </div>
                <div className={styles.item}>
                  <div className={styles.pic}>
                    <img src={require('../../assets/images/mjimg5.png')} alt='img'/>
                    <div className={styles.btn}>管弦乐</div>
                  </div>
                  <div className={styles.line}></div>
                </div>
                <div className={styles.item}>
                  <div className={styles.pic}>
                    <img src={require('../../assets/images/mjimg6.png')} alt='img'/>
                    <div className={styles.btn}>爵士</div>
                  </div>
                  <div className={styles.line}></div>
                </div>
                <div className={styles.item}>
                  <div className={styles.pic}>
                    <img src={require('../../assets/images/mjimg7.png')} alt='img'/>
                    <div className={styles.btn}>作曲</div>
                  </div>
                  <div className={styles.line}></div>
                </div>
                <div className={styles.item}>
                  <div className={styles.pic}>
                    <img src={require('../../assets/images/mjimg8.png')} alt='img'/>
                    <div className={styles.btn}>音乐制作</div>
                  </div>
                  <div className={styles.line}></div>
                </div>
                <div className={styles.item}>
                  <div className={styles.pic}>
                    <img src={require('../../assets/images/mjimg9.png')} alt='img'/>
                    <div className={styles.btn}>音乐教育</div>
                  </div>
                  <div className={styles.line}></div>
                </div>
                <div className={styles.item}>
                  <div className={styles.pic}>
                    <img src={require('../../assets/images/mjimg10.png')} alt='img'/>
                    <div className={styles.btn}>艺术管理</div>
                  </div>
                  <div className={styles.line}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* LBM OFFER展示 */}
        <div className={styles.offershow}>
          <div className={styles.main}>
            <div className="module-title">
              <strong>LBM OFFER展示</strong>
              <p className="subhead">LBM OFFER SHOW</p>
            </div>
            <div className={styles.offerlist}>
            <div className='swiper-container-offer'>
                <div className='swiper-wrapper'>
                  <div className='swiper-slide'>
                    <ul>
                      
                      <li data-offer='http://imagecdn.yinyuewujie.com/offer_20190628_1.jpg' onClick={this.handleOffer.bind(this)}>
                        <div className='pic'>
                          <img src={require('../../assets/images/offer-big1.jpg')} alt=""/>
                        </div>
                        <div className={styles.jj}><strong>蔡同学</strong><span>纽约大学</span></div>
                        <div className={styles.xx}>纽约大学</div>
                        <div className={styles.info}>
                          <div className={styles.name}>蔡同学</div>
                          <span className={styles.line}></span>
                          <p>音乐剧硕士</p>
                        </div>
                      </li>
                      
                      <li className='licol4' data-offer='http://imagecdn.yinyuewujie.com/offer_20190628_2.jpg' onClick={this.handleOffer.bind(this)}>
                        <div className='pic'>
                          <div className='c-block'></div>
                          <img src={require('../../assets/images/offer-big2.jpg')} alt=""/>
                        </div>
                        <div className={styles.jj}><strong>陈同学</strong><span>新英格兰音乐学院</span></div>
                        <div className={styles.xx}>新英格兰音乐学院</div>
                        <div className={styles.info}>
                          <div className={styles.name}>陈同学</div>
                          <span className={styles.line}></span>
                          <p>声乐本科</p>
                          <p>$ 72800 奖学金</p>
                        </div>
                      </li>
                      
                      <li className='licol2' data-offer='http://imagecdn.yinyuewujie.com/offer_20190628_3.jpg' onClick={this.handleOffer.bind(this)}>
                        <div className='pic'>
                          <div className='c-block'></div>
                          <img src={require('../../assets/images/offer-big3.jpg')} alt=""/>
                        </div>
                        <div className={styles.jj}><strong>李同学</strong><span>曼哈顿音乐学院</span></div>
                        <div className={styles.xx}>曼哈顿音乐学院</div>
                        <div className={styles.info}>
                          <div className={styles.name}>李同学</div>
                          <span className={styles.line}></span>
                          <p>钢琴专业</p>
                        </div>
                      </li>
                      
                    </ul>
                  </div>
                  <div className='swiper-slide'>
                    <ul>
                      
                      <li className='licol2' data-offer='http://imagecdn.yinyuewujie.com/offer_20190628_4.jpg' onClick={this.handleOffer.bind(this)}>
                        <div className='pic'>
                          <div className='c-block'></div>
                          <img src={require('../../assets/images/offer-big4.jpg')} alt=""/>
                        </div>
                        <div className={styles.jj}><strong>郭同学</strong><span>新英格兰音乐学院</span></div>
                        <div className={styles.xx}>新英格兰音乐学院</div>
                        <div className={styles.info}>
                          <div className={styles.name}>郭同学</div>
                          <span className={styles.line}></span>
                          <p>声乐本科</p>
                          <p>$ 50400 奖学金</p>
                        </div>
                      </li>
                      
                      <li data-offer='http://imagecdn.yinyuewujie.com/offer_20190628_5.jpg' onClick={this.handleOffer.bind(this)}>
                        <div className='pic'>
                          <div className='c-block'></div>
                          <img src={require('../../assets/images/offer-big5.jpg')} alt=""/>
                        </div>
                        <div className={styles.jj}><strong>邱同学</strong><span>伯克利波士顿音乐学院</span></div>
                        <div className={styles.xx}>伯克利波士顿音乐学院</div>
                        <div className={styles.info}>
                          <div className={styles.name}>邱同学</div>
                          <span className={styles.line}></span>
                          <p>声乐硕士</p>
                        </div>
                      </li>
                      
                      <li data-offer='http://imagecdn.yinyuewujie.com/offer_20190628_6.jpg' onClick={this.handleOffer.bind(this)}>
                        <div className='pic'>
                          <div className='c-block'></div>
                          <img src={require('../../assets/images/offer-big6.jpg')} alt=""/>
                        </div>
                        <div className={styles.jj}><strong>王同学</strong><span>亚利桑那州立大学</span></div>
                        <div className={styles.xx}>亚利桑那州立大学</div>
                        <div className={styles.info}>
                          <div className={styles.name}>王同学</div>
                          <span className={styles.line}></span>
                          <p>音乐剧硕士</p>
                        </div>
                      </li>
                      
                    </ul>
                  </div>
                  <div className='swiper-slide'>
                    <ul>
                      <li data-offer='http://imagecdn.yinyuewujie.com/offer_20190628_7.jpg' onClick={this.handleOffer.bind(this)}>
                        <div className='pic'>
                          <div className='c-block'></div>
                          <img src={require('../../assets/images/offer-big7.jpg')} alt=""/>
                        </div>
                        <div className={styles.jj}><strong>李同学</strong><span>曼尼斯音乐学院</span></div>
                        <div className={styles.xx}>曼尼斯音乐学院</div>
                        <div className={styles.info}>
                          <div className={styles.name}>李同学</div>
                          <span className={styles.line}></span>
                          <p>声乐本科</p>
                          <p>$ 40000 奖学金</p>
                        </div>
                      </li>
                      <li data-offer='http://imagecdn.yinyuewujie.com/offer_20190628_8.jpg' onClick={this.handleOffer.bind(this)}>
                        <div className='pic'>
                          <div className='c-block'></div>
                          <img src={require('../../assets/images/offer-big8.jpg')} alt=""/>
                        </div>
                        <div className={styles.jj}><strong>沙同学</strong><span>隆基音乐学院</span></div>
                        <div className={styles.xx}>隆基音乐学院</div>
                        <div className={styles.info}>
                          <div className={styles.name}>沙同学</div>
                          <span className={styles.line}></span>
                          <p>爵士硕士</p>
                          <p>$ 50000 奖学金</p>
                        </div>
                      </li>
                      <li data-offer='http://imagecdn.yinyuewujie.com/offer_20190628_9.jpg' onClick={this.handleOffer.bind(this)}>
                        <div className='pic'>
                          <div className='c-block'></div>
                          <img src={require('../../assets/images/offer-big9.jpg')} alt=""/>
                        </div>
                        <div className={styles.jj}><strong>周同学</strong><span>费城艺术大学</span></div>
                        <div className={styles.xx}>费城艺术大学</div>
                        <div className={styles.info}>
                          <div className={styles.name}>周同学</div>
                          <span className={styles.line}></span>
                          <p>声乐本科</p>
                          <p>$ 40000 奖学金</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={['swiper-pagination swiper-pagination-offer']}></div>
                
              </div>
            </div>
          </div>
        </div>
        {/* VIP 增值服务 */}
        <div className={styles.vip}>
          <div className={styles.main}>
            <div className="module-title">
                <strong className='col333'>VIP增值服务</strong>
                <p className="subhead">OTHER MAJORS</p>
            </div>
            <div className={styles.other}>
              <div className={`${styles.item} ${styles.item1} ${vipSrvice === 'zuixiang' ? styles.on : ''}`} data-type='zuixiang' onClick={this.handleVipService.bind(this)}>
                <i className={styles.icon1}></i>
                <p>尊享计划</p>
                <div className={styles.line}></div>
              </div>
              <div className={`${styles.item} ${styles.item2} ${vipSrvice === 'youcai' ? styles.on : ''}`} data-type='youcai' onClick={this.handleVipService.bind(this)}>
                <i className={styles.icon2}></i>
                <p>优才计划</p>
                <div className={styles.line}></div>
              </div>
              <div className={`${styles.item} ${styles.item3} ${vipSrvice === 'yuanmeng' ? styles.on : ''}`} data-type='yuanmeng' onClick={this.handleVipService.bind(this)}>
                <i className={styles.icon3}></i>
                <p>圆梦计划</p>
                <div className={styles.line}></div>
              </div>
              <div className={`${styles.item} ${styles.item4} ${vipSrvice === 'qihang' ? styles.on : ''}`} data-type='qihang' onClick={this.handleVipService.bind(this)}>
                <i className={styles.icon4}></i>
                <p>启航计划</p>
                <div className={styles.line}></div>
              </div>
            </div>
            <div className={styles.otherlist} style={{display: vipSrvice === 'zuixiang' ? 'block' : 'none'}}>
              <div className={styles.row}>
                <div className={styles.item}>初期评估</div>
                <div className={styles.item}>名师试听课</div>
                <div className={styles.item}>美国纽约莫尔里国际音乐节</div>
              </div>
              <div className={styles.item}>结业评估</div>
                <div className={styles.item}>国际名校招生说明会</div>
                <div className={styles.item}>面试行程安排</div>
            </div>
            <div className={styles.otherlist} style={{display: vipSrvice === 'youcai' ? 'block' : 'none'}}>
              <div className={styles.row}>
                <div className={styles.item}>综合规划书</div>
                <div className={styles.item}>古典音乐家国际音乐比赛</div>
                <div className={styles.item}>奖学金申请</div>
              </div>
              <div className={styles.item}>国际名校招生说明会</div>
                <div className={styles.item}>作品集选取策略指导</div>
                <div className={styles.item}>面试行程安排</div>
            </div>
            <div className={styles.otherlist} style={{display: vipSrvice === 'yuanmeng' ? 'block' : 'none'}}>
              <div className={styles.row}>
                <div className={styles.item}>三期专业评估</div>
                <div className={styles.item}>社会实践活动规则</div>
                <div className={styles.item}>作品集打磨指导</div>
              </div>
              <div className={styles.item}>业内专家作品集审核</div>
                <div className={styles.item}>作品集终审</div>
                <div className={styles.item}>国际名校招生说明会</div>
            </div>
            <div className={styles.otherlist} style={{display: vipSrvice === 'qihang' ? 'block' : 'none'}}>
              <div className={styles.row}>
                <div className={styles.item}>专属方案</div>
                <div className={styles.item}>名师试听课</div>
                <div className={styles.item}>三期专业评估</div>
              </div>
              <div className={styles.item}>古典音乐家国际音乐比赛</div>
                <div className={styles.item}>美国纽约莫尔里国际音乐节</div>
                <div className={styles.item}>优质音乐厅作品集录制</div>
            </div>
          </div>
        </div>
        {/* 底部 */}
        <Footer />
        {/* offer弹层 */}
        <div className={styles.popbg} hidden={!offerShow}>
          <div className={styles.offerbox}>
            <img src={offerUrl} alt=""/>
          </div>
          <div className='pop-close' onClick={this.closeOffer.bind(this)}></div>
        </div>
      </div>
    )
  }
}
export default Service