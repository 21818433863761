import React, {Component} from 'react'
import styles from '../Personal/Personal.css'
import { Calendar, Badge, Table, Button } from 'antd';
import 'antd/dist/antd.css';
import zhCN from 'antd/lib/calendar/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

class PersonalTimetable extends Component {
  constructor(props){
    super(props)
    this.state = {
      dataArr: []
    }
    this.getProgress = this.getProgress.bind(this)
    this.onPanelChange = this.onPanelChange.bind(this)
    this.dateCellRender = this.dateCellRender.bind(this)
  }
  componentDidMount(){
    window.scrollTo(0, 0)
    let timeStr = moment().year() + '-' + (moment().month()+1 >= 10 ? moment().month()+1 : '0'+(moment().month()+1)) + '-' + (moment().date() >= 10 ? moment().date() : '0'+moment().date())
    this.getProgress(timeStr)
  }
  getProgress(date){
    let that = this
    var p = new Promise((resolve, reject)=>{
      fetch(global.constants.api_url + '/v2/progress/list?current_date='+date,{
        method:'GET',
        headers: {
          'Accept':'application/json',
          'token': this.operCookie().get('token')
        },
      })
      .then((response) => {
        response.json().then((data) => {
          if(data.code === 0){
            
            that.setState({
              dataArr: data.data
            },()=>{
              resolve(data.data)
              return data.data
            })
          }
        })
      })
      .catch((error) => {
        console.log(error)
      })
    })
    return p
  }
  getRoomUrl(e){
    let url = e.currentTarget.getAttribute('data-room')
    window.open(url)
  }
  onPanelChange = (value) => {
    console.log(value)
    if(value.year()){
      let dateStr = value.year() + '-' + (value.month()+1 >= 10 ? value.month()+1 : '0'+(value.month()+1)) + '-' + (value.date() >= 10 ? value.date() : '0'+value.date())
      this.getProgress(dateStr).then((data)=>{
        console.log(data)
        this.setState({
          dataArr: data
        })
      })
    }
    
  }
  
  dateCellRender(value){
    const list = this.state.dataArr
    if(list.length > 0){
      return (
        <ul className="events">
          {
            list.map(item => (
              <li key={item.progress_id} style={{display: value.format('YYYY-MM-DD') === moment(item.start_cn_time).format('YYYY-MM-DD') ? 'block' : 'none'}}>
                <Badge status={item.progress_id} text={item.planText} />
                <Badge status={item.progress_id} text={item.professorText} />
                <Badge status={item.progress_id} text={item.teacherText} />
                <Badge status={item.progress_id} text={moment(item.start_cn_time).format('HH:MM')} />
                <Badge status={item.progress_id} data-room={item.room_url} onClick={this.getRoomUrl.bind(this)} style={{display: item.room_url.length ? 'block' : 'none'}} text='进入课堂' />
              </li>
            ))
          }
        </ul>
      );
    }
    
  }
  operCookie(){
    var cookie = {
      set:function(key,val,time){//设置cookie方法
          var date=new Date(); //获取当前时间
          var expiresDays=time;  //将date设置为n天以后的时间
          date.setTime(date.getTime()+expiresDays*24*3600*1000); //格式化为cookie识别的时间
          document.cookie=key + "=" + val +";expires="+date.toGMTString();  //设置cookie
      },
      get:function(key){//获取cookie方法
          /*获取cookie参数*/
          var getCookie = document.cookie.replace(/[ ]/g,"");  //获取cookie，并且将获得的cookie格式化，去掉空格字符
          var arrCookie = getCookie.split(";")  //将获得的cookie以"分号"为标识 将cookie保存到arrCookie的数组中
          var tips;  //声明变量tips
          for(var i=0;i<arrCookie.length;i++){   //使用for循环查找cookie中的tips变量
              var arr=arrCookie[i].split("=");   //将单条cookie用"等号"为标识，将单条cookie保存为arr数组
              if(key===arr[0]){  //匹配变量名称，其中arr[0]是指的cookie名称，如果该条变量为tips则执行判断语句中的赋值操作
                  tips=arr[1];   //将cookie的值赋给变量tips
                  break;   //终止for循环遍历
              }
          }
        
          return tips;
      },
      delete:function(key){ //删除cookie方法
        var date = new Date(); //获取当前时间
        date.setTime(date.getTime()-10000); //将date设置为过去的时间
        document.cookie = key + "=v; expires =" +date.toGMTString();//设置cookie
       }
    }
    return cookie
  }
  render(){
    const columns = [
      {
        title: 'ID',
        dataIndex: 'progress_id',
        key: 'progress_id',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.progress_id - b.progress_id,
      },
      {
        title: '老师姓名',
        dataIndex: 'professorText',
        key: 'professorText'
      },
      {
        title: '助教姓名',
        dataIndex: 'teacherText',
        key: 'progress_id'
      },
      {
        title: '课程',
        dataIndex: 'planText',
        key: 'planText'
      },
      {
        title: '开课时间',
        dataIndex: 'start_cn_time',
        key: 'start_cn_time',
        render: text =>
          moment(text).format('YYYY-MM-DD HH:mm')
      },
      {
        title: '操作',
        dataIndex: 'operation',
        key: 'operation',
        render: (text, item) =>
          item.room_url && <Button type="primary" href={item.room_url} target="_blank">进入教室</Button>,
      },
    ];
    return(
      <div>
        <div className={`${styles.block1} marB20`}>
          <div className={styles.name}>
            <div className={styles.title}>设备检测</div>
            <span>为了您能获得更好的上课效果，请您开始检测电脑和网络。</span>
          </div>
          {/* <span className={styles.button1}>立即检测</span> */}
        </div>
        <div style={{background:'#ffffff'}}>
          {/* <Calendar 
          locale={zhCN} 
          onPanelChange={this.onPanelChange} 
          dateCellRender={this.dateCellRender}
          /> */}
          <Table
            columns={columns}
            dataSource={this.state.dataArr}
            pagination={{pageSize: 30}}
          />
        </div>
      </div>
    )
  }
}
export default PersonalTimetable