export function decrement() {
  return{
      type:DECREMENT_COUNTER
  }
}
export function increment(){
  return{
      type:INCREMENT_COUNTER
  }
}
export function personalChange(){
    return{
        type:PERSONAL_CHANGE
    }
}
export function getPersonal(){
    return{
        type:INIT_LIST_ACTION
    }
}
export function getSchool(text){
    return{
        type:SCHOOL_LIST,
        payload:text
    }
}
export function getUser(text){
    return {
        type:USER_INFO,
        payload:text
    }
}
export function incrementIfOdd(){
  return(dispatch,getState)=>{
      const {counter} = getState();
      if(counter%2===0) {
          return;
      }
      dispatch(increment());
  }
}
export function incrementAsync() {
  return dispatch => {
      setTimeout(() => {
          dispatch(increment());
      }, 1000);
  };
}



export const INCREMENT_COUNTER = 'INCREMENT_COUNTER'
export const DECREMENT_COUNTER = 'DECREMENT_COUNTER'
export const PERSONAL_CHANGE = 'PERSONAL_CHANGE'
export const INIT_LIST_ACTION = 'INIT_LIST_ACTION'
export const SCHOOL_LIST = 'SCHOOL_LIST'
export const USER_INFO = 'USER_INFO'