import React, { Component } from 'react'
import styles from './Activity.css'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Banner from '../../components/Banner/Banner'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

class List extends Component {
  constructor(props){
    super(props)
    this.state = {
      banenr:'',
      list:[],
      type: '1',
      bannerArr: []
    }
  }
  componentDidMount(){
    window.scrollTo(0, 0)
    this.getList(this.state.type)
    // banner
    fetch(global.constants.api_url + '/v1/banner/list?location=10', {
      method: 'GET'
    })
    .then( response => {
        response.json().then( data => {
        this.setState({
            bannerArr: data.data
        })
        })
    })
    .catch( error => {
        console.log(error)
    })
  }
  getList(type){
    fetch(global.constants.api_url + '/v1/match/list?type='+type, {
      method: 'GET'
    })
    .then( response => {
      response.json().then(data => {
        this.setState({
          banner: data.data.banner[0].image,
          list: data.data.list
        })
      })
    })
    .catch( error => {
      console.log(error)
    })
  }
  toLink(e){
    let id = e.target.getAttribute('data-id')
    this.props.history.push({pathname:"/details/" + id})
  }
  prevPage(){
    let p = this.state.page
    if(p>1){
      p --
      this.setState({
        page: p
      }, () => {
        const {country_id, area_id, degree_ids, school_types, major_id, page} = this.state
        this.schoolList(country_id, area_id, degree_ids, school_types, major_id, page)
      })
    }
  }
  nextPage(){
    let p = this.state.page
    if(p < this.state.last_page){
      p ++
      this.setState({
        page: p
      }, () => {
        const {country_id, area_id, degree_ids, school_types, major_id, page} = this.state
        this.schoolList(country_id, area_id, degree_ids, school_types, major_id, page)
      })
    }
  }
  handleAdd(){
    this.props.onClick()
  }
  handleChange(e){
    let type = e.currentTarget.getAttribute('data-type')
    this.setState({
      type: type
    })
    this.getList(type)
  }
  render(){
    const { list, type } = this.state
    return (
      <div className={styles.bgf6}>
        {/* 头部 */}
        <Header />
        {/* banner */}
        <div className='banner'>
          <Banner banner={this.state.bannerArr}/>
        </div>
        {/* 切换 */}
        <div className={styles.category}>
          <div className={`${styles['cate-item']} ${type === '1' ? styles.on : ''}`} onClick={this.handleChange.bind(this)} data-type='1'>
            <i className={styles.icon1}></i>
            <div className={styles.line}></div>
            <span>LBM赛事</span>
          </div>
          <div className={`${styles['cate-item']}  ${type === '2' ? styles.on : ''}`} onClick={this.handleChange.bind(this)} data-type='2'>
            <i className={styles.icon4}></i>
            <div className={styles.line}></div>
            <span>音乐活动</span>
          </div>
        </div>
        <div className="wrap">
          <div className={`${styles.content} ${styles.bgff}`}>
            <div>
              <div className={styles['list-wrap']} style={{'display': type === '1' ? 'block' : 'none'}}>
                <ul className={styles['list']}>
                  {
                    list.map((item, index) => {
                      return (
                        <li data-id={item.article_id} key={index}>
                          <div className={styles['left']}><Link to={`/details/${item.match_id}?type=match`} target="_blank"><img src={item.image} width="270" height="168" alt=""/></Link></div>
                          <div className={styles['right']}>
                            <div className={styles['title']}><Link to={`/details/${item.match_id}?type=match`} target="_blank">{item.title}</Link></div>
                            <div className={styles['info']}>主办方：{item.sponsor}</div>
                            <div className={styles['info']}>时间：{item.match_start_time} - {item.match_end_time}</div>
                            <div className={styles['info']}><span className={ item.status === 1 ? styles.ongoing : styles.end}>{item.status_text}</span></div>
                            <div className={styles.amount}>阅读量：3.5W</div>
                          </div>
                          
                        </li>
                      )
                    })
                  }
                </ul>
                {/* <div className={styles.page}>
                  <ul className={styles['page-num']}>
                    <li className={styles['pn-prev']}>上一页</li>
                    <li className={styles['pn-next']}>下一页</li>
                  </ul>
                </div> */}
              </div>
              <div className={styles['list-wrap']} style={{'display': type === '2' ? 'block' : 'none'}}>
                <ul className={styles['list']}>
                  {
                    list.map((item, index) => {
                      return (
                        <li data-id={item.article_id} key={index}>
                          <div className={styles['left']}><Link to={`/details/${item.match_id}?type=activity`} target="_blank"><img data-id={item.match_id} src={item.image} width="380" height="210" alt=""/></Link></div>
                          <div className={styles['right']}>
                            <div className={styles['title']}><Link to={`/details/${item.match_id}?type=activity`} target="_blank">{item.title}</Link></div>
                            <div className={styles['info']}>主办方：{item.sponsor}</div>
                            <div className={styles['info']}>时间：{item.match_start_time} - {item.match_end_time}</div>
                            <div className={styles['info']}>状态：<span className={ item.status === 1 ? styles.ongoing : ''}>{item.status_text}</span></div>
                          </div>
                          
                        </li>
                      )
                    })
                  }
                </ul>
                {/* <div className={styles.page}>
                  <ul className={styles['page-num']}>
                    <li className={styles['pn-prev']}>上一页</li>
                    <li className={styles['pn-next']}>下一页</li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* 底部 */}
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return ({
    data: state.counter
  })
}
const mapDispatchToProps = dispatch => ({
  onClick: id => dispatch({
    type: 'INCREMENT_COUNTER'
  }),
  onDel: item => dispatch({
    type: 'LIST_DEL',
    payload: [item]
  })
})
export default connect(mapStateToProps, mapDispatchToProps)(List)