import React, {Component} from 'react'
import styles from './Details.css'
import '../../config'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import ReactPlayer from 'react-player'
import Link from 'react-router-dom/Link';

class Details extends Component{
  constructor(props){
    super(props)
    this.state = {
      details:{},
      jury:[],
      liveList:[],
      videourl:'',
      videoPlayerShow:false,
      typeshow: 'info',
      videolist: [],
      infolist: [],
      currentUrl: '',
      typeList: '',
      videoIsShow:false,
      vurl: ''
    }
  }
  componentDidMount(){
    // console.log(this.props.location.query.typ)
    window.scrollTo(0,0)
    let arr = this.props.location.pathname.split('/'),
        typearr = this.props.location.search.split('=')
    this.setState({
      currentUrl: '/details/'+arr[arr.length-1]+this.props.location.search,
      typeList: typearr[typearr.length-1]
    })
    fetch(global.constants.api_url+'/v1/match/item?match_id='+arr[arr.length-1],{
      method:'GET'
    })
    .then(response => {
      response.json().then(data => {
        console.log(data)
        if(data.code === 0){
          this.setState({
            details: data.data,
            jury: data.data.jury.slice(0, 12)
          })
          if(data.data.live.length >= 1){
            this.setState({
              liveList: data.data.live
            })
          }
        }
        
      })
    })
    .catch(error => {
      console.log(error)
    })
    // 赛事视频
    fetch(global.constants.api_url + '/v1/match/review?match_id='+arr[arr.length-1] + '&page=1', {
        method: 'GET'
    })
    .then( response => {
        response.json().then( data => {
          console.log(data.data)
          this.setState({
            videolist: data.data.slice(0,12)
          })
        })
    })
    .catch( error => {
        console.log(error)
    })
    // 赛事资讯
    fetch(global.constants.api_url + '/v1/match/review?match_id='+arr[arr.length-1] + '&type=100&page=1', {
      method: 'GET'
    })
    .then( response => {
        response.json().then( data => {
          this.setState({
            infolist: data.data.slice(0,4)
          })
        })
    })
    .catch( error => {
        console.log(error)
    })
  }
  componentWillReceiveProps(nextProps){
    if(this.props.location !== nextProps.location){
      window.scrollTo(0,0)
    }
  }
  playerHandle(e){
    let status = e.target.getAttribute('data-status'),
        hls = e.target.getAttribute('data-hls')
    if(status === '1'){
      this.setState({
        videourl: hls,
        videoPlayerShow: true
      })
    }
    
  }
  closePlayerHandle(){
    this.setState({
      videoPlayerShow: false,
      videourl: ''
    })
  }
  stopHandle(e){
    e.stopPropagation()
  }
  toVideo(e){
    let url = e.target.getAttribute('data-video')
    this.setState({
      vurl: url,
      videoIsShow:true
    })
  }
  closeVideo(e){
    this.setState({
      vurl: '',
      videoIsShow: false
    })
  }
  typeHandle(e){
    let type = e.target.getAttribute('data-type')
    this.setState({
      typeshow: type
    })
  }
  render(){
    const { details, jury, videourl, liveList, videoPlayerShow, typeshow, infolist, videolist, currentUrl, typeList, videoIsShow, vurl} = this.state
    return(
      <div className={styles.bgf6}>
        {/* 头部 */}
        <Header/>
        {/* banner */}
        <div className={styles.banner}>
          <div className={styles.position} style={{display: typeList === 'match' ? 'block' : 'none'}}><span><Link to='/activity'>活动赛事</Link> > LBM赛事 ><em><Link to={currentUrl}>赛事详情</Link></em></span></div>
          <div className={styles.position} style={{display: typeList === 'activity' ? 'block' : 'none'}}><span><Link to='/activity'>活动赛事</Link> > 音乐活动 ><Link  to={currentUrl}>活动详情</Link></span></div>
          <div className={styles['banner-show']}>
            <div className={styles['banner-left']}><img src={details.image} alt=""/></div>
            <div className={styles['banner-right']}>
              <dl>
                <dt><img src={details.logo_image} alt=""/></dt>
                <dd className={styles.tit}>{details.title}</dd>
                <dd>主办方：{details.sponsor}</dd>
                <dd>赛事时间：{details.match_start_time}-{details.match_end_time}</dd>
                <dd className={styles.sta}>
                  <span className={ details.status === 1 ? styles.ongoing : styles.end}>{details.status_text}</span>
                  <span className={styles.fee}>报名费用<em>￥{details.entry_fee}</em></span>
                  <span className={styles.fee}>观摩费用<em>￥{details.audit_fee}</em></span>
                </dd>
              </dl>
              <div className={styles.codetwo}>
                <div className={styles.codeone}>
                  <img src={require('../../assets/images/code-app.png')} alt='code'/>
                  <p>音乐无界APP</p>
                </div>
                <div className={styles.codeone}>
                  <img src={require('../../assets/images/code-xcx.png')} alt='code'/>
                  <p>音乐无界小程序</p>
                </div>
              </div>
            </div>
            
          </div>
          {/* 直播列表 */}
          <div className={styles.lives}>
            <div className={styles.livesbtn}>直播<img src={require('../../assets/images/icon-zb.png')} alt='img'/></div>
            <div className={styles.liveslist}>
              <div className={styles.marright}>
              {
                liveList.map((item, index)=>{
                  return (
                    <div className={styles.item} key={index}  data-status={item.status} data-hls={item.play_urls.hls} onClick={this.playerHandle.bind(this)}><em>{item.title}</em> <span className={item.status === 1 ? styles.on : ''}>{item.status === 1 ? '正在直播' : '直播结束'}</span></div>
                  )
                })
              }
              </div>
            </div>
          </div>
        </div>
        {/* 评委 */}
        <div className={styles.rater}>
          <div className='wrap'>
            <div className={styles.title}><img src={require('../../assets/images/title-ss1.png')} alt='title'/></div>
            <div className={styles['rater-list']}>
              <div className={styles.listshow}>
                  {
                    jury.map((item, index)=>{
                      return (
                        <div className={styles.juryitem} key={index}>
                          <img src={item.avatar} alt=""/>
                          <p>{item.name}</p>
                          <p>{item.school}</p>
                        </div>
                      )
                    })
                  }
              </div>
              
            </div>
          </div>
        </div>
        {/* 赛事视频 */}
        <div className={styles.whiteblank}>
          <div className={styles.title} hidden={!videolist.length}><img src={require('../../assets/images/title-ss2.png')} alt='title'/></div>
          <div className={styles['video-sslist']} style={{display: videolist.length > 0 ? 'block' : 'none'}}>
              {
                videolist.map((item, index)=>{
                  return (
                    <div className={styles.item} key={index}>
                      <img src={item.image_url} alt='img'/>
                      <p>{item.title}</p>
                      <span>{item.create_time}</span>
                      <div className={styles.playbtn} onClick={this.toVideo.bind(this)} data-video={item.video_url}></div>
                    </div>
                  )
                })
              }
          </div>
          <div className={styles.title} hidden={!infolist.length}><img src={require('../../assets/images/title-ss3.png')} alt='title'/></div>
          <div className={styles.borderbot} hidden={!infolist.length}>
          <div className={styles['zx-list']}>
            {
                infolist.map((item, index)=>{
                  return (
                    <div className={styles.item} key={index}>
                        <div className={styles.left}>
                          <Link to={`/news/article/${item.id}`}><img src={item.image_url} alt='img'/></Link>
                        </div>
                        <div className={styles.right}>
                          <p><Link to={`/news/article/${item.id}`}>{item.title}</Link></p>
                          <span>{item.create_time}</span>
                        </div>
                    </div>
                  )
                })
              }
          </div>
          </div>
          <div className={styles.tabtit}>
            <span data-type='info' onClick={this.typeHandle.bind(this)} className={typeshow === 'info' ? styles.on : ''}>赛事介绍</span>
            <span data-type='rules' onClick={this.typeHandle.bind(this)} className={typeshow === 'rules' ? styles.on : ''}>赛事章程</span>
            <span data-type='mode' onClick={this.typeHandle.bind(this)} className={typeshow === 'mode' ? styles.on : ''}>报名方式</span>
          </div>
          <div className={styles.moreinfo} style={{display: typeshow === 'info' ? 'block' : 'none'}}>
            <p dangerouslySetInnerHTML={{ __html: details.intro_html }}></p>
          </div>
          <div className={styles.moreinfo} style={{display: typeshow === 'rules' ? 'block' : 'none'}}>
            <p dangerouslySetInnerHTML={{ __html: details.rules_html }}></p>
          </div>
          <div className={styles.moreinfo} style={{display: typeshow === 'mode' ? 'block' : 'none'}}>
            <p dangerouslySetInnerHTML={{ __html: details.mode_html }}></p>
          </div>
        </div>
        
        {/* 底部 */}
        <Footer/>
        {/* 直播弹层 */}
        <div className={styles.playerBg} hidden={!videoPlayerShow} onClick={this.closePlayerHandle.bind(this)}>
          <div className={styles.playerBox} onClick={this.stopHandle.bind(this)}>
            <ReactPlayer url={videourl} playing />
          </div>
        </div>
        {/* 视频弹层 */}
        <div className={styles.popbg} hidden={!videoIsShow}>
          <div className={styles.videobox}>
            <video src={vurl} controls="controls"></video>
            <div className={styles['video-close']} onClick={this.closeVideo.bind(this)}></div>
          </div>
        </div>
      </div>
    )
  }
}
export default Details