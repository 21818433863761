import React, { Component } from 'react'
import styles from '../Personal/Personal.css'
import '../../config'

const obj = {}

class PersonalSet extends Component {
  constructor(props){
    super(props)
    this.state = {
      user:{},
      localValue:{},
      gender: '1'
    }
  }
  componentDidMount(){
    fetch(global.constants.api_url + '/v1/user/profile', {
      method: 'GET',
      headers: {
        'token': this.operCookie().get('token')
      }
    })
    .then(response => {
      response.json().then(data => {
        this.setState({
          user: data.data
        })
      })
    })
    .catch(error => {
      console.log(error)
    })
  }
  changeInput(e){
    obj[e.target.name] = e.target.value
    this.setState({
      localValue: obj
    })
  }
  handleGender(e){
    let gender = e.currentTarget.getAttribute('data-gender')
    this.setState({
      gender: gender
    })
  }
  submitValue(){
    let str = JSON.stringify(this.state.localValue)
    const obj = JSON.parse(str)
    obj.gender = this.state.gender
    fetch(global.constants.api_url + '/v1/user/updateprofile', {
      method: 'POST',
      headers: {
        'token': this.operCookie().get('token')
      },
      body: JSON.stringify(obj)
    })
    .then(response => {
      response.json().then(data => {
        if(data.code === 0){
          alert('成功')
        }
      })
    })
    .catch(error => {
      console.log(error)
    })
  }
  operCookie(){
    var cookie = {
      set:function(key,val,time){//设置cookie方法
          var date=new Date(); //获取当前时间
          var expiresDays=time;  //将date设置为n天以后的时间
          date.setTime(date.getTime()+expiresDays*24*3600*1000); //格式化为cookie识别的时间
          document.cookie=key + "=" + val +";expires="+date.toGMTString();  //设置cookie
      },
      get:function(key){//获取cookie方法
          /*获取cookie参数*/
          var getCookie = document.cookie.replace(/[ ]/g,"");  //获取cookie，并且将获得的cookie格式化，去掉空格字符
          var arrCookie = getCookie.split(";")  //将获得的cookie以"分号"为标识 将cookie保存到arrCookie的数组中
          var tips;  //声明变量tips
          for(var i=0;i<arrCookie.length;i++){   //使用for循环查找cookie中的tips变量
              var arr=arrCookie[i].split("=");   //将单条cookie用"等号"为标识，将单条cookie保存为arr数组
              if(key===arr[0]){  //匹配变量名称，其中arr[0]是指的cookie名称，如果该条变量为tips则执行判断语句中的赋值操作
                  tips=arr[1];   //将cookie的值赋给变量tips
                  break;   //终止for循环遍历
              }
          }
        
          return tips;
      },
      delete:function(key){ //删除cookie方法
        var date = new Date(); //获取当前时间
        date.setTime(date.getTime()-10000); //将date设置为过去的时间
        document.cookie = key + "=v; expires =" +date.toGMTString();//设置cookie
       }
    }
    return cookie
  }
  render(){
    const { user, gender } = this.state
    return (
      <div>
        <div className={styles['title-block']}>
          <span className={styles.on}>基本资料</span>
        </div>
        <div className={styles['basic-data']}>
          <div className={styles.portrait}>
            <img src={user.avatar ? user.avatar : 'http://imagecdn.yinyuewujie.com/lbm-default-avatar.png'} alt=""/>
            {/* <span className={styles.button2}>更换头像</span> */}
          </div>
          <div className={styles['data-item']}>
            <span className={styles.name}>昵 称：</span>
            <input className={styles.input} type="text" placeholder="昵称" onChange={this.changeInput.bind(this)} name='nickname' defaultValue={user.nickname}/>
          </div>
          <div className={styles['data-item']}>
            <span className={styles.name}>真实姓名：</span>
            <input className={styles.input} type="text" placeholder="真实姓名" onChange={this.changeInput.bind(this)} name='realname' defaultValue={user.realname}/>
          </div>
          <div className={styles['data-item']}>
            <span className={styles.name}>性 别：</span>
            <div className={styles.gender}>
              <div className={styles.sex} onChange={this.handleGender.bind(this)} data-gender='1'>
                <input type="radio" name="sex" id="male" defaultChecked checked={gender === '1'} />
                <label htmlFor="male">男</label>
              </div>
              <div className={styles.sex} onChange={this.handleGender.bind(this)} data-gender='2'>
                <input type="radio" name="sex" id="female" checked={gender === '2'} />
                <label htmlFor="female">女</label>
              </div>
            </div>
          </div>
          <div className={styles['data-item']}>
            <span className={styles.name}>身份证号：</span>
            <input className={styles.input} type="text" placeholder="身份证号" onChange={this.changeInput.bind(this)} name='idcode' defaultValue={user.idcode}/>
          </div>
          <div className={styles['data-item']}>
            <span className={styles.name}>手机号码：</span>
            <input className={styles.input} type="text" placeholder="手机号码" onChange={this.changeInput.bind(this)} name='mobile' defaultValue={user.mobile}/>
          </div>
          <div className={styles['data-item']}>
            <span className={styles.name}>邮箱地址：</span>
            <input className={styles.input} type="text" placeholder="r.utrvnme@gmail.com" onChange={this.changeInput.bind(this)} name='email' defaultValue={user.email}/>
          </div>
          {/* <div className={styles['data-item']}>
            <span className={styles.name}>现居住地：</span>
            <div className={styles.place}>
                <input className={styles.input} type="text" placeholder="省/直辖市"/>
                <input className={styles.input} type="text" placeholder="地区"/>
            </div>
          </div> */}
          <div className={styles['data-item']}>
            <span className={styles.name}>就读学校：</span>
            <input className={styles.input} type="text" placeholder="中央音乐学院" onChange={this.changeInput.bind(this)} name='school' defaultValue={user.school}/>
          </div>
          <div className={styles['data-item']}>
            <span className={styles.name}>指导老师：</span>
            <input className={styles.input} type="text" placeholder="唐霞" onChange={this.changeInput.bind(this)} name='teacher' defaultValue={user.teacher}/>
          </div>
          <div className={styles['data-item']}>
            <span className={styles.name}>音乐主修：</span>
            <input className={styles.input} type="text" placeholder="钢琴演奏" onChange={this.changeInput.bind(this)} name='major' defaultValue={user.major}/>
          </div>
          <div className={styles.tijiao}>
            <span className={styles.button1} onClick={this.submitValue.bind(this)}>保存</span>
          </div>
        </div>
      </div>
    )
  }
}
export default PersonalSet