import React, { Component } from 'react'
import styles from './Course.css'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Banner from '../../components/Banner/Banner'

var onOff = true
class Course extends Component {
  constructor(props){
    super(props)
    this.state = {
      type: 'type1',
      bannerArr: []
    }
  }
  componentDidMount(){
    // banner
    fetch(global.constants.api_url + '/v1/banner/list?location=6', {
      method: 'GET'
    })
    .then( response => {
      response.json().then( data => {
        this.setState({
          bannerArr: data.data
        })
      })
    })
    .catch( error => {
      console.log(error)
    })
  }
  handleMouseOver(e){
    let type = e.currentTarget.getAttribute('data-type')
    if(onOff && type !== this.state.type){
      onOff = false
      this.setState({
        type: type
      }, ()=>{
        onOff = true
      })
    }
  }
  render(){
    return(
      <div className={styles['course-bg']}>
        {/* 头部 */}
        <Header />
        {/* banner */}
        <div className='banner'>
        <Banner banner={this.state.bannerArr}/>
        </div>
        <div className={styles.superiority}>
          <div className='wrap'>
            <div className={styles.item}>
              <div className={`${styles.icon} ${styles.icon1}`}></div>
              <div className={styles.line}></div>
              <p>音乐专业课程全覆盖</p>
            </div>
            <div className={styles.item}>
              <div className={`${styles.icon} ${styles.icon2}`}></div>
              <div className={styles.line}></div>
              <p>海外名师在线预约</p>
            </div>
            <div className={styles.item}>
              <div className={`${styles.icon} ${styles.icon3}`}></div>
              <div className={styles.line}></div>
              <p>海归高教辅助教学</p>
            </div>
            <div className={styles.item}>
              <div className={`${styles.icon} ${styles.icon4}`}></div>
              <div className={styles.line}></div>
              <p>多种福利课程</p>
            </div>
            <div className={styles.item}>
              <div className={`${styles.icon} ${styles.icon5}`}></div>
              <div className={styles.line}></div>
              <p>线上与面授课灵活转换</p>
            </div>
          </div>
        </div>
        <div className='wrap'>
          <div class="module-title">
            <strong class="col333">热门学习专业推荐</strong>
            <p class="subhead">RECOMMENDATION FOR AREAS OF STUDY</p>
          </div>
          <div className={styles.recom}>
            <div className={styles.row}>
              <div className={styles.item}>
                <div className={styles.pic}>
                  <img src={require('../../assets/images/rimg1.png')} alt='img'/>
                  <div className={styles.mark}>
                    <p>声乐</p>
                    <span>Vocal Arts</span>
                  </div>
                  <div className={styles.btn}>声乐</div>
                </div>
                <div className={styles.line}></div>
              </div>
              <div className={styles.item}>
                <div className={styles.pic}>
                  <img src={require('../../assets/images/rimg2.png')} alt='img'/>
                  <div className={styles.mark}>
                    <p>音乐剧</p>
                    <span>Musical Theatre</span>
                  </div>
                  <div className={styles.btn}>音乐剧</div>
                </div>
                <div className={styles.line}></div>
              </div>
              <div className={styles.item}>
                <div className={styles.pic}>
                  <img src={require('../../assets/images/rimg3.png')} alt='img'/>
                  <div className={styles.mark}>
                    <p>钢琴</p>
                    <span>Piano</span>
                  </div>
                  <div className={styles.btn}>钢琴</div>
                </div>
                <div className={styles.line}></div>
              </div>
              <div className={styles.item}>
                <div className={styles.pic}>
                  <img src={require('../../assets/images/rimg4.png')} alt='img'/>
                  <div className={styles.mark}>
                    <p>流行表演</p>
                    <span>Popular Music</span>
                  </div>
                  <div className={styles.btn}>流行表演</div>
                </div>
                <div className={styles.line}></div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.item}>
                <div className={styles.pic}>
                  <img src={require('../../assets/images/rimg5.png')} alt='img'/>
                  <div className={styles.mark}>
                    <p>管乐</p>
                    <span>Woodwinds</span>
                  </div>
                  <div className={styles.btn}>管乐</div>
                </div>
                <div className={styles.line}></div>
              </div>
              <div className={styles.item}>
                <div className={styles.pic}>
                  <img src={require('../../assets/images/rimg6.png')} alt='img'/>
                  <div className={styles.mark}>
                    <p>弦乐</p>
                    <span>Strings</span>
                  </div>
                  <div className={styles.btn}>弦乐</div>
                </div>
                <div className={styles.line}></div>
              </div>
              <div className={styles.item}>
                <div className={styles.pic}>
                  <img src={require('../../assets/images/rimg7.png')} alt='img'/>
                  <div className={styles.mark}>
                    <p>爵士</p>
                    <span>Jazz Music</span>
                  </div>
                  <div className={styles.btn}>爵士</div>
                </div>
                <div className={styles.line}></div>
              </div>
              <div className={styles.item}>
                <div className={styles.pic}>
                  <img src={require('../../assets/images/rimg8.png')} alt='img'/>
                  <div className={styles.mark}>
                    <p>作曲</p>
                    <span>Composition</span>
                  </div>
                  <div className={styles.btn}>作曲</div>
                </div>
                <div className={styles.line}></div>
              </div>
            </div>
          </div>
        </div>
        {/* 活动 */}
        <div className={styles.ouractivity}>
          <div className='wrap'>
            <div class="module-title">
              <strong class="col333">我们的活动</strong>
              <p class="subhead">OUR ACTIVITIES</p>
            </div>
            <div className={styles.list}>
            <div className={styles.item}>
              <a href='/activity/competition'><img src={require('../../assets/images/activities1.png')} alt='act'/></a>
              <div className={styles.btn}>国际赛事</div>
            </div>
            <div className={styles.item}>
              <a href='/activity/events'><img src={require('../../assets/images/activities2.png')} alt='act'/></a>
              <div className={styles.btn}>国际音乐节</div>
            </div>
            </div>
          </div>
        </div>
        
        {/* 底部 */}
        <Footer />
      </div>
    )
  }
}

export default Course