import React, { Component } from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import styles from './Details.css'

class CollegesDetails extends Component {
  render(){
    return (
      <div className={styles.bg}>
        {/* 头部 */}
        <Header />
        <div className={styles.collegesCard}>
          <div className='wrap'>
            <img className={styles.collegesPic} src={require('../../assets/images/sch-logo5.jpg')} alt='img'/>
            <div className={styles.collegesTit}>
              <div className={styles.tit}>罗彻斯特大学伊斯曼音乐学院</div>
              <div className={styles.info}><span>(U. of Rochester–Eastman School of Music)</span>|<span>纽约罗切斯特</span></div>
            </div>
          </div>
        </div>
        <div className='wrap'>
          <div className={styles.collItems}>
            <div className={styles.leftArea}>
              <span className={styles.on}>学校介绍</span>
              <span>专业概况</span>
              <span>专业设置</span>
              <span>杰出校友</span>
              <span>申请信息</span>
              <span>申请要求</span>
              <span>费用信息</span>
              <span>住宿信息</span>
              <span>学校资讯</span>
              <span>相关相册</span>
            </div>
            <div className={styles.rightArea}>
              {/* 块 */}
              <div className={styles.blank}>
                <div className={styles.name}>
                学校介绍：
                </div>
                <div className={styles.con}>美国罗彻斯特大学（University of Rochester）于1850年创建，在当时为一所教会学校，为美国100所最佳院校之一。其伊斯曼音乐学院（Eastman School of Music of the University of Rochester）是1921年由著名实业家、慈善家、柯达公司创始人乔治 伊斯曼创建的专业音乐学院，已经在U.S.News排名中多年稳居第一，是公认的世界最顶尖音乐学院。</div>
                <div className={styles.arrow}></div>
              </div>
              {/* 块 */}
              <div className={styles.blank}>
                <div className={styles.name}>
                专业概况：
                </div>
                <div className={styles.con}>美国罗彻斯特大学（University of Rochester）于1850年创建，在当时为一所教会学校，为美国100所最佳院校之一。其伊斯曼音乐学院（Eastman School of Music of the University of Rochester）是1921年由著名实业家、慈善家、柯达公司创始人乔治 伊斯曼创建的专业音乐学院，已经在U.S.News排名中多年稳居第一，是公认的世界最顶尖音乐学院。</div>
                <div className={styles.arrow}></div>
              </div>
              {/* 块 */}
              <div className={styles.blank}>
                <div className={styles.name}>
                专业设置：
                </div>
                <div className={styles.con}>美国罗彻斯特大学（University of Rochester）于1850年创建，在当时为一所教会学校，为美国100所最佳院校之一。其伊斯曼音乐学院（Eastman School of Music of the University of Rochester）是1921年由著名实业家、慈善家、柯达公司创始人乔治 伊斯曼创建的专业音乐学院，已经在U.S.News排名中多年稳居第一，是公认的世界最顶尖音乐学院。</div>
                <div className={styles.arrow}></div>
              </div>
              {/* 块 */}
              <div className={styles.blank}>
                <div className={styles.name}>
                申请信息：
                </div>
                <div className={styles.con}>美国罗彻斯特大学（University of Rochester）于1850年创建，在当时为一所教会学校，为美国100所最佳院校之一。其伊斯曼音乐学院（Eastman School of Music of the University of Rochester）是1921年由著名实业家、慈善家、柯达公司创始人乔治 伊斯曼创建的专业音乐学院，已经在U.S.News排名中多年稳居第一，是公认的世界最顶尖音乐学院。</div>
                <div className={styles.arrow}></div>
              </div>
            </div>
          </div>
        </div>
        {/* 底部 */}
        <Footer />
      </div>
    )
  }
}
export default CollegesDetails