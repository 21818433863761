import React, { Component } from 'react'
import Slider from "react-slick"
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Layer from '../../components/Layer/Layer'
import Achieve from '../../components/Achieve/Achieve'
import OfferList from '../../components/OfferList/OfferList'
// 引入此路径，才不会打包失败
import Swiper from 'swiper/dist/js/swiper.js'
import 'swiper/dist/css/swiper.min.css'
import styles from './Index.css'
import '../../config'
import { Link } from 'react-router-dom'

class Index extends Component {
  constructor(props){
    super(props)
    this.state = {
      overseas: [],
      overseasLenArr: [],
      logos: [],
      article: [],
      videoArr: [],
      layershow: false,
      videoIsShow:false,
      videoUrl:'',
      videoImg:'',
      bannerArr: [],
      teacherIsShow: false,
      tea_head: '',
      tea_en_name: '',
      tea_name: '',
      tea_title: '',
      tea_school: '',
      tea_intro: '',
    }
  }
  componentDidMount() {
    // banner
    fetch(global.constants.api_url + '/v1/banner/list?location=3', {
      method: 'GET'
    })
    .then( response => {
      response.json().then( data => {
        this.setState({
          bannerArr: data.data
        })
      })
    })
    .catch( error => {
      console.log(error)
    })
    
    // 海外名师
    fetch(global.constants.api_url + '/v1/jury/getlist?page_size=100', {
      method: 'GET'
    })
    .then( response => {
      response.json().then( data => {
        let str = JSON.stringify(data.data.data),
            temOverseas =JSON.parse(str),
            len = Math.floor(temOverseas.length/12),
            arr = []
        for(var i=0; i<len; i++){
          arr.push(i)
        }
        this.setState({
          overseas: temOverseas,
          overseasLenArr: arr
        })
        // new Swiper('.swiper-container2', {
        //   autoplay: {
        //     delay: 5000,
        //     stopOnLastSlide: false,
        //   },
        //   loop : {
        //     loop: true,
        //   },
        //   speed:1000,
        //   slidesPerView: 6,
        //   slidesPerColumn: 2,
        //   spaceBetween: 24,
        //   navigation: {
        //     nextEl: '.swiper-button-next',
        //     prevEl: '.swiper-button-prev',
        //   }
        // })
        new Swiper('.swiper-container2',{
          autoplay: {
            delay: 5000,
            stopOnLastSlide: false
          },
          speed:1000,
          autoplayDisableOnInteraction : false,
          loop:true,
          spaceBetween: 24,
          centeredSlides : true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }
        })
      })
    })
    .catch( error => {
      console.log(error)
    })
    // 学校logo
    fetch(global.constants.api_url + '/v1/school/selectlist', {
      method: 'GET'
    })
    .then( response => {
      response.json().then( data => {
        this.setState({
          logos: data.data
        })
      })
    })
    .catch( error => {
      console.log(error)
    })
    // 最新音乐资讯
    fetch(global.constants.api_url + '/v1/article/limitlist?limit=13&channel_id=0', {
      method: 'GET'
    })
    .then( response => {
      response.json().then( data => {
        this.setState({
          article: data.data.slice(0,4)
        })
      })
    })
    .catch( error => {
      console.log(error)
    })
    // 最新音乐资讯视频
    fetch(global.constants.api_url+'/v1/match/review?type=1',{
      method:'GET'
    })
    .then(response => {
      response.json().then(data => {
        this.setState({
          videoArr: data.data.slice(0,3)
        })
      })
    })
    
  }
  handleAssessment(e){
    e.stopPropagation()
    this.setState({
      teacherIsShow: false,
      layershow: true
    })
  }
  videoHandle(e){
    let url = e.currentTarget.getAttribute('data-video'),
        img = e.currentTarget.getAttribute('data-img')
    this.setState({
      videoUrl: url,
      videoIsShow: true,
      videoImg: img
    })
  }
  closeVideo(){
    this.setState({
      videoUrl: '',
      videoIsShow:false
    })
  }
  bannerLink(e){
    let url = e.target.getAttribute('data-url')
    window.location.href = url
  }
  handleTeacher(e){
    let tea_head = e.currentTarget.getAttribute('data-head'),
        tea_en_name = e.currentTarget.getAttribute('data-enname'),
        tea_name = e.currentTarget.getAttribute('data-name'),
        tea_title = e.currentTarget.getAttribute('data-title'),
        tea_school = e.currentTarget.getAttribute('data-school'),
        tea_intro = e.currentTarget.getAttribute('data-intro')
    
    this.setState({
      tea_head,
      tea_en_name,
      tea_name,
      tea_title,
      tea_school,
      tea_intro,
      teacherIsShow: true,
      layershow: false
    })
  }
  closeTeacher(e){
    this.setState({
      teacherIsShow: false
    })
  }
  render () {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true
    }
    const { overseas, overseasLenArr, logos, article, videoArr, layershow, videoIsShow, videoUrl, videoImg, bannerArr, teacherIsShow, tea_head, tea_en_name, tea_name, tea_title, tea_school, tea_intro } = this.state
    //console.log(this.props.school)
    return (
      <div className={styles.wrapbg}>
        {/* 头部 */}
        <Header/>
        {/* banner */}
        <div className='banner'>
          {/* <img className={styles.pic1} src={require('../../assets/images/slide1.jpg')} alt="1" /> */}
          <Slider {...settings}>
            {
              bannerArr.map((item, index)=>{
                return <div key={index}>
                  <div onClick={this.bannerLink.bind(this)} data-url={item.url} style={{height:'478px', background:`url(${item.image}) no-repeat center top`}}></div>
                </div>
              })
            }
            {/* <div className={styles.indexbanner1}>
            </div>
            <div className={styles.indexbanner2}>
            </div>
            <div className={styles.indexbanner3}>
            </div> */}
          </Slider>
        </div>
        {/* 成就 */}
        <Achieve />
        {/* 选择我们 */}
        <div className={styles.choose}>
          <div className={styles.wrap}>
            <div className={`${styles.title} ${styles.center}`}>
              <img className={styles['title-pic']} src={require('../../assets/images/title1.png')} alt=""/>
            </div>
            <ul className={styles.superior}>
              <li>
                <img src={require('../../assets/images/s1.png')} alt=""/><span className={styles.line}></span>
                <p className={styles.name}>100%海外名师授课</p>
                <div className={styles.layer}>
                  <div className={styles.name}>100%海外名师授课</div>
                  <span className={styles['b-line']}></span>
                  <p>为学员提供专业提升、作品打磨、面试强化等课程，所有课程均由与LBM合作的千位海外音乐院校教授完成授课，学员获得专业拔高的同时更早的适应美国院校教学环境</p>
                </div>
              </li>
              <li>
                <img src={require('../../assets/images/s2.png')} alt=""/><span className={styles.line}></span>
                <p className={styles.name}>1V1线上课教学</p>
                <div className={styles.layer}>
                  <div className={styles.name}>1V1线上课教学</div>
                  <span className={styles['b-line']}></span>
                  <p>自主研发的高保真线上教学系统，通过灵活的授课形式、完美的音视频传输保障，让学员足不出户就可以与海外名师学习，获得与现场学习一样的教学收获；</p>
                </div>
              </li>
              <li>
                <img src={require('../../assets/images/s3.png')} alt=""/><span className={styles.line}></span>
                <p className={styles.name}>双师教学有保障</p>
                <div className={styles.layer}>
                  <div className={styles.name}>双师教学有保障</div>
                  <span className={styles['b-line']}></span>
                  <p>聘请英美著名音乐学院海归人才作为专职辅导教师，课上辅助教学帮助学员更好吸收海外名师教学内容，课后提升学员回课与作业质量；</p>
                </div>
              </li>
              <li>
                <img src={require('../../assets/images/s4.png')} alt=""/><span className={styles.line}></span>
                <p className={styles.name}>五维专业评估体系</p>
                <div className={styles.layer}>
                  <div className={styles.name}>五维专业评估体系</div>
                  <span className={styles['b-line']}></span>
                  <p>海外名校教授、业内专家、行业大咖根据不同专业特点，分别从五个维度进行客观的专业评估，形成专属的评估建议与书面评估报告；</p>
                </div>
              </li>
              <li>
                <img src={require('../../assets/images/s5.png')} alt=""/><span className={styles.line}></span>
                <p className={styles.name}>量身定制留学与职业规划</p>
                <div className={styles.layer}>
                  <div className={styles.name}>量身定制留学与职业规划</div>
                  <span className={styles['b-line']}></span>
                  <p>结合中外音乐教育体系特点及音乐职业市场发展现状，将留学与职业发展有机结合，保证最佳留学效果并实现职业发展目标</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        {/* 名师 */}
        <div className={styles.teacher}>
          <div className={styles.wrap}>
            <div className={styles.title}>
              <img className={styles['title-pic']} src={require('../../assets/images/title2.png')} alt=""/>
              <Link className="button1" to='/teacher/list'>更多名师</Link>
            </div>
          </div>
          <div className={styles.wrap}>
            <div className={styles['teacher-list']}>
              <div className='swiper-container2'>
                <div className='swiper-wrapper'>
                {
                  overseasLenArr.map((ind) => {
                    return (
                      <div className='swiper-slide' key={ind}>
                        <div style={{margin: '-24px 0 0 -24px'}}>
                        {overseas.map( (item, index) => {
                          if(index>=12*ind && index < (ind+1)*12){
                            return (
                              <div style={{width:'180px', float: 'left',margin: '24px 0 0 24px'}} key={index}>
                                <div className={styles.pic} data-head={item.avatar} data-enname={item.en_name} data-name={item.name} data-title={item.title} data-school={item.school} data-intro={item.intro} onClick={this.handleTeacher.bind(this)}>
                                  <img src={item.avatar} alt=""/>
                                  <span className={styles.sc}><span className={styles.c}>{item.title}</span></span>
                                </div>
                                <div className={styles.info}>
                                  <p className={styles.col666}>{item.en_name}</p>
                                  <p>{item.name}</p>
                                  <p className={styles.col666}>{item.major_type_text}</p>
                                  <p>{item.school}</p>
                                </div>
                              </div>
                            )
                          }
                          return ''
                        })}
                        </div>
                      </div>
                    )
                  })
                }
                </div>
                <div className={styles.button}>
                  <div className={['swiper-button-prev']} ref="buttonprev"></div>
                  <div className={['swiper-button-next']} ref="buttonnext"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* adv */}
        <div className={styles.adv} onClick={this.handleAssessment.bind(this)}>
        </div>
        {/* offer */}
        <div className={styles.offer}>
          <div className={styles.wrap}>
            <div className={styles.title}>
              <img className={styles['title-pic']} src={require('../../assets/images/title3.png')} alt=""/>
              <Link className="button2" to='/offer'>查看更多</Link>
            </div>
            {/* 学员offer列表 */}
            <OfferList />
            
          </div>
        </div>
        {/* 课程 */}
        <div className={styles.course}>
          <div className={styles.wrap}>
            <div className={styles.super}>
              <div className={styles.name}>
                <strong>Li Mo</strong>
                <p>李莫</p>
                <span>教研总监</span>
              </div>
              <div className={styles.head}>
                <img src={require('../../assets/images/lmls.png')} alt=""/>
              </div>
              <div className={styles.info}>针对中国音乐学生特点，李莫博士与伊斯特曼、茱莉亚、新英格兰、伯克利等多所音乐院校不同专业的著名音乐教育家组成LBM教研团队，共同研发制定了独有的音乐教育与留学七大体系，最大化发挥LBM海外资源优势，保障学生提升专业水平的同时，更好地与国际音乐教育接轨。</div>
            </div>
            <div className={styles.title}>
              <img className={styles['title-pic']} src={require('../../assets/images/title4.png')} alt=""/>
            </div>
            <ul className='products-list'>
              <li className='long'>
                <img src={require('../../assets/images/pr1.png')} alt=''/>
                <div className='tit tit1'></div>
                <div className='info'>量身定制的留学规划方案，留学前、中、后贴心的全流程服务体系，强大的院校和教授资源是您留学成功的有力保障</div>
              </li>
              <li className='long'>
                <img src={require('../../assets/images/pr2.png')} alt=''/>
                <div className='tit tit2'></div>
                <div className='info'>海外音乐院校教授教授专业课程，全面提升音乐专业水平，更早的适应美国院校教学环境</div>
              </li>
              <li className='short'>
                <img src={require('../../assets/images/pr3.png')} alt=''/>
                <div className='tit tit3'></div>
                <div className='info'>自主研发高保真授课系统，让学员足不出户就可以与海外名师学习，获得与现场学习一样的教学收获</div>
              </li>
              <li className='short'>
                <img src={require('../../assets/images/pr4.png')} alt=''/>
                <div className='tit tit4'></div>
                <div className='info'>音乐留学直通车，再获得美国教授比赛专业评语的同时，还有机会直接获得美国院校现场颁发的《专业录取通知书》</div>
              </li>
              <li className='short'>
                <img src={require('../../assets/images/pr5.png')} alt=''/>
                <div className='tit tit5'></div>
                <div className='info'>精彩难忘的音乐学习之旅，美国音乐留学成功的摇篮</div>
              </li>
            </ul>
          </div>
        </div>
        {/* 院校库 */}
        <div className={styles.colleges}>
          <div className={styles.wrap}>
            <div className={styles.title}>
              <img className={styles['title-pic']} src={require('../../assets/images/title5.png')} alt=""/>
              <Link className="button2" to='/colleges/shcool'>更多院校</Link>
            </div>
            <ul className={styles['colleges-list']}>
              {
                logos.map((item, index) => {
                  if(index < 16){
                    return (
                      <li key={index}>
                        <img src={item.logo} alt=""/>
                        <div className={styles.name}>
                          <strong>{item.name}</strong>
                          <span></span>
                        </div>
                      </li>
                    )
                  }
                  return ''
                })
              }
            </ul>
          </div>
        </div>
        {/* 音乐资讯 */}
        <div className={styles.latest}>
          <div className={styles.wrap}>
            <div className={styles.title}>
              <img className={styles['title-pic']} src={require('../../assets/images/title6.png')} alt=""/>
              <Link className={styles.morevideo} to='/news/list'>查看视频</Link>
            </div>
            <div className={styles.infor}>
              <div className={styles.news}>
                {
                  videoArr.map((item, index) => {
                    return (
                      <div className={styles['news-item']} key={index} onClick={this.videoHandle.bind(this)} data-video={item.video_url} data-img={item.image_url}>
                        <img src={item.image_url} alt=""/>
                        <div className={styles.text}>
                          <p>{item.title}</p>
                          <i></i>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
              <div className={styles.morenews}>
                <div className={styles.line}></div>
                <Link className={styles.more} to='/news/list'>更多资讯</Link>
              </div>
              <div className={styles.newslist}>
                {
                  article.map((item, index)=>{
                    return (
                      <div className={styles.item} key={index}>
                        <div className={styles.pic}><Link to={`/news/article/${item.article_id}`}><img src={item.image[0].image_url} alt='img'/></Link></div>
                        <div className={styles.text}>
                          <div className={styles.tit}><Link to={`/news/article/${item.article_id}`}>{item.title}</Link></div>
                          <div className={styles.yuan}>
                            <span>{item.channel.name}</span>{item.create_time}
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
                
              </div>
            </div>
          </div>
        </div>
        {/* 底部 */}
        <Footer/>
       {/* 评估 */}
       <Layer show={layershow}/>
       {/* 视频弹层 */}
        <div className={styles.popbg} style={{'display': videoIsShow ? 'block' : 'none'}}>
          <div className={styles.videobox}>
            <span className={styles['video-close']} onClick={this.closeVideo.bind(this)}></span>
            <video src={videoUrl} controls="controls" poster={videoImg}></video>
          </div>
        </div>
        {/* 导师简介弹层 */}
        <div className={styles.popbg} hidden={!teacherIsShow}>
          <div className='teacherInfo'>
            <div className='left'>
              <img src={tea_head} alt='head'/>
              <div className='teainfo'>
                <span>{tea_en_name}</span>
                <p>{tea_name}</p>
                <span>{tea_title}</span>
                <p>{tea_school}</p>
              </div>
            </div>
            <div className='right'>
              <div className='jjname'>导师简介</div>
              <div className='jjcon' dangerouslySetInnerHTML={{ __html: tea_intro }}></div>
            </div>
            <div className='tBtns'>
              <div className='btn1' onClick={this.handleAssessment.bind(this)}>预约大师课</div>
              <div className='btn2' onClick={this.handleAssessment.bind(this)}>请TA评估</div>
            </div>
            <div className='pop-close' onClick={this.closeTeacher.bind(this)}></div>
          </div>
        </div>
      </div>
    )
  }
}

export default Index