import React, { Component } from 'react'
import Slider from "react-slick"

class Banner extends Component {
    componentDidMount(){
    }
    bannerLink(e){
        let url = e.target.getAttribute('data-url')
        window.location.href = url
    }
    render(){
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true
          }
        return (
            <Slider {...settings}>
                {
                this.props.banner.map((item, index)=>{
                    return <div key={index}><div onClick={this.bannerLink.bind(this)} data-url={item.url} style={{height:'340px', background:`url(${item.image}) no-repeat center top`, cursor: 'pointer'}}></div></div>
                })
                }
            </Slider>
            
        )
    }
}
export default Banner