import React, { Component } from 'react'

export default class Counter extends Component{
  render(){
      const { counter, increment, decrement, incrementIfOdd, incrementAsync } = this.props;
      return(
          <p>
              Clicked:{counter} times<br/>
              <button onClick={increment}>+</button><br/>
              <button onClick={decrement}>-</button><br/>
              <button onClick={incrementIfOdd}>increment if Odd</button><br/>
              <button onClick={incrementAsync}>increment async</button>
          </p>
      )
  }
}
