import React, { Component } from 'react'
import styles from './Competition.css'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'

class Competition extends Component {
    render(){
        return(
            <div className={styles.bgf0}>
                {/* 头部 */}
                <Header />
                {/* banner */}
                <div className={styles.banner1}></div>
                <div className={styles.banner2}></div>
                <div className={styles.banner3}></div>
                <div className={styles.banner4}></div>
                <div className={styles.banner5}></div>
                <div className={styles.banner6}></div>
                {/* 简介 */}
                <div className='wrap'>
                    <img src={require('../../assets/images/gjss_11.jpg')} alt='img'/>
                    <img src={require('../../assets/images/gjss_12.jpg')} alt='img'/>
                </div>
                {/*  */}
                <div className={styles.sblank}>
                    <div className={styles.linethree}>
                        <div className={styles.item}>
                            <i className={styles.icon1}></i>
                            <div className={styles.name}>美国音乐院校来华评委</div>
                            <div className={styles.slogen}>比赛结果公平公正</div>
                            <div className={styles.line}></div>
                        </div>
                        <div className={styles.item}>
                            <i className={styles.icon2}></i>
                            <div className={styles.name}>顶尖音乐名校教授大师课</div>
                            <div className={styles.slogen}>跟国际名师面对面学习</div>
                            <div className={styles.line}></div>
                        </div>
                        <div className={styles.item}>
                            <i className={styles.icon3}></i>
                            <div className={styles.name}>顶尖音乐名校教授小课</div>
                            <div className={styles.slogen}>更有针对性，专业获得快速提升</div>
                            <div className={styles.line}></div>
                        </div>
                    </div>
                    <div className={styles.linetwo}>
                        <div className={styles.item}>
                            <i className={styles.icon4}></i>
                            <div className={styles.name}>美国音乐院校来华评委</div>
                            <div className={styles.slogen}>比赛结果公平公正</div>
                            <div className={styles.line}></div>
                        </div>
                        <div className={styles.item}>
                            <i className={styles.icon5}></i>
                            <div className={styles.name}>顶尖音乐名校教授大师课</div>
                            <div className={styles.slogen}>跟国际名师面对面学习</div>
                            <div className={styles.line}></div>
                        </div>
                    </div>
                </div>
                {/* 比赛 */}
                <div className={styles.matchbg}>
                    <div className={`${styles.match} ${styles.match1}`}>
                        <div className={`${styles.matchwrap} ${styles.matchright}`}>
                            <div className={styles.video}><img src={require('../../assets/images/gjss_11.jpg')} alt='img'/></div>
                            <a className={styles.btn} href='/'>了解更多</a>
                        </div>
                    </div>
                </div>
                {/* 比赛 */}
                <div className={styles.matchbg}>
                    <div className={`${styles.match} ${styles.match2}`}>
                        <div className={`${styles.matchwrap} ${styles.matchleft}`}>
                            <div className={styles.video}><img src={require('../../assets/images/gjss_11.jpg')} alt='img'/></div>
                            <a className={styles.btn} href='/'>了解更多</a>
                        </div>
                    </div>
                </div>
                 {/* 比赛 */}
                 <div className={styles.matchbg}>
                    <div className={`${styles.match} ${styles.match3}`}>
                        <div className={`${styles.matchwrap} ${styles.matchright}`}>
                            <div className={styles.video}><img src={require('../../assets/images/gjss_11.jpg')} alt='img'/></div>
                            <a className={styles.btn} href='/'>了解更多</a>
                        </div>
                    </div>
                </div>
                 {/* 比赛 */}
                 <div className={styles.matchbg}>
                    <div className={`${styles.match} ${styles.match4}`}>
                        <div className={`${styles.matchwrap} ${styles.matchleft}`}>
                            <div className={styles.video}><img src={require('../../assets/images/gjss_11.jpg')} alt='img'/></div>
                            <a className={styles.btn} href='/'>了解更多</a>
                        </div>
                    </div>
                </div>
                 {/* 比赛 */}
                 <div className={styles.matchbg}>
                    <div className={`${styles.match} ${styles.match5}`}>
                        <div className={`${styles.matchwrap} ${styles.matchright}`}>
                            <div className={styles.video}><img src={require('../../assets/images/gjss_11.jpg')} alt='img'/></div>
                            <a className={styles.btn} href='/'>了解更多</a>
                        </div>
                    </div>
                </div>
                {/* 底部 */}
                <Footer />
            </div>
        )
    }
}
export default Competition