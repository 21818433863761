import React, { Component } from 'react'
import styles from './MyMenu.css'
import { connect } from 'react-redux';

class MyMenu extends Component {
  constructor(props){
    super(props)
    this.state = {
      tab: 'home'
    }
  }
  tabHandle(item){
    this.props.handlePersonal(item)
  }
  render(){
    return (
      <div>
        <ul className="my-menu">
          <li className={ this.props.data === 'home' ? 'on' : '' } onClick={this.tabHandle.bind(this, 'home')} data-type='home'><i className="icon1"></i><span>我的主页</span></li>
          <li className={ this.props.data === 'course' ? 'on' : '' } onClick={this.tabHandle.bind(this, 'course')} data-type='course'><i className="icon2"></i><span>我的课堂</span></li>
          <li className={ this.props.data === 'timetable' ? 'on' : '' } onClick={this.tabHandle.bind(this, 'timetable')} data-type='timetable'><i className="icon3"></i><span>我的课表</span></li>
          {/* <li className={ this.props.data === 'order' ? 'on' : '' } onClick={this.tabHandle.bind(this, 'order')} data-type='order'><i className="icon4"></i><span>我的订单</span></li> */}
          <li className={ this.props.data === 'message' ? 'on' : '' } onClick={this.tabHandle.bind(this, 'message')} data-type='message'><i className="icon5"></i><span>我的消息</span></li>
          <li className={ this.props.data === 'setup' ? 'on' : '' } onClick={this.tabHandle.bind(this, 'setup')} data-type='setup'><i className="icon6"></i><span>个人设置</span></li>
        </ul>
        <div className={styles['bottom-border']}></div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return ({
    data: state.personalTab
  })
}
const mapDispatchToProps = dispatch => ({
  handlePersonal: item => dispatch({
    type: 'PERSONAL_CHANGE',
    payload: item
  })
})
export default connect(mapStateToProps, mapDispatchToProps)(MyMenu)