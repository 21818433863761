import React, { Component } from 'react'
import styles from './AboutUs.css'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'

class AboutUs extends Component {
  componentDidMount(){
    window.scrollTo(0,0)
  }
  render(){
    return (
      <div>
        {/* 头部 */}
        <Header/>
        <div className={styles.main}>
          <img src={require('../../assets/images/about_02.jpg')} alt=""/>
          <img src={require('../../assets/images/about_03.jpg')} alt=""/>
          <img src={require('../../assets/images/about_04.jpg')} alt=""/>
          <img src={require('../../assets/images/about_05.jpg')} alt=""/>
          <img src={require('../../assets/images/about_06.jpg')} alt=""/>
          <img src={require('../../assets/images/about_07.jpg')} alt=""/>
          <img src={require('../../assets/images/about_08.jpg')} alt=""/>
          <img src={require('../../assets/images/about_09.jpg')} alt=""/>
          <img src={require('../../assets/images/about_10.jpg')} alt=""/>
          <img src={require('../../assets/images/about_11.jpg')} alt=""/>
          <img src={require('../../assets/images/about_12.jpg')} alt=""/>
          <img src={require('../../assets/images/about_13.jpg')} alt=""/>
          <img src={require('../../assets/images/about_14.jpg')} alt=""/>
          <img src={require('../../assets/images/about_15.jpg')} alt=""/>
          <img src={require('../../assets/images/about_16.jpg')} alt=""/>
        </div>
        {/* 底部 */}
        <Footer/>
      </div>
    )
  }
}
export default AboutUs