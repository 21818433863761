import React, { Component } from 'react'
import styles from './List.css'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import { Link } from 'react-router-dom'
import Slider from "react-slick"

class List extends Component {
    constructor(props){
        super(props)
        this.state = {
            banner:[],
            channel: [],
            list: [],
            channel_id: 4,
            page: 1,
            bannerArr: []
        }
    }
    componentDidMount(){
        window.scrollTo(0,0)
        // banner
        fetch(global.constants.api_url + '/v1/banner/list?location=11', {
            method: 'GET'
        })
        .then( response => {
            response.json().then( data => {
            this.setState({
                bannerArr: data.data
            })
            })
        })
        .catch( error => {
            console.log(error)
        })
        this.getData(this.state.channel_id, this.state.page)
    }
    handleChannel(e){
        let id = e.currentTarget.getAttribute('data-id')
        this.setState({
            channel_id: Number(id),
            page: 1
        })
        this.getData(id, this.state.page)
    }
    prevPage(){
        let page = this.state.page
        if(page > 1){
            page--
            this.setState({
                page
            })
            this.getData(this.state.channel_id, page)
        }
    }
    nextPage(){
        let page = this.state.page
        page++
        this.setState({
            page
        })
        this.getData(this.state.channel_id, page)
    }
    getData(id,page){
        fetch(global.constants.api_url + '/v1/article/list?channel_id='+id+'&page='+page, {
        method: 'GET'
        })
        .then( response => {
            response.json().then(data => {
                if(data.data.banner.length > 0){
                    this.setState({
                        banner: data.data.banner,
                        channel: data.data.channel.slice(0,8),
                        list: data.data.list
                    })
                }else{
                    this.setState({
                        list: data.data.list
                    })
                }
                
            })
        })
        .catch( error => {
            console.log(error)
        })
    }
    bannerLink(e){
        let url = e.currentTarget.getAttribute('data-url')
        window.location.href = url
    }
    render(){
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true
          }
        const { channel, list, channel_id, bannerArr } = this.state
        return(
            <div className={styles.bgf6}>
                {/* 头部 */}
                <Header />
                <div className={styles.wraplist}>
                    <div className={styles.leftarea}>
                        {/* 切换标签 */}
                        <div className={styles.listnav}>
                            <div className={styles.topmenu}>
                            {
                                channel.map((item, index)=>{
                                    return (
                                        <div className={`${styles.item} ${channel_id === item.channel_id ? styles.on : ''}`} key={index} data-id={item.channel_id} onClick={this.handleChannel.bind(this)}>
                                        <span>{item.name}</span>
                                        <div className={styles.line}></div>
                                        </div>
                                    )
                                })
                            }
                            </div>
                        </div>
                        {/* banner */}
                        <div className={styles.banner}>
                        <Slider {...settings}>
                            {
                                bannerArr.map((item, index)=>{
                                    return <div key={index} className={styles.banneritem} onClick={this.bannerLink.bind(this)} data-url={item.url}><img src={item.image} alt=""/><div className={styles.bannertit}>{item.title}</div></div>
                                })
                            }
                            
                        </Slider>
                        </div>
                        {/* 文章 */}
                        <div className={styles.special}>
                            <div className={styles.top}></div>
                            <div className={styles.bottom}>
                                {
                                    list.map((item, index)=>{
                                        if(index < 3){
                                            return (
                                                <div className={styles.imgitem}>
                                                    <Link to={`/news/article/${item.article_id}`} key={index} target="_blank"><img src={item.image[0].image_url}  width='274' height='133' alt='img'/></Link>
                                                    <div className={styles.imgtit}>{item.title}</div>
                                                </div>
                                            )
                                        }
                                        return ''
                                    })
                                }
                            </div>
                        </div>
                        
                        
                        {/* 列表 */}
                        <div className={styles['list-wrap']}>
                            <ul className={styles['list']}>
                                {
                                    list.map((item, index)=>{
                                        if(index >= 3){
                                        return (
                                            <li  key={index}>
                                                <div className={styles.borderBot}>
                                                <div className={styles['left']}><Link to={`/news/article/${item.article_id}`} target="_blank"><img src={item.image[0].image_url} width="214" height="133" alt=""/></Link></div>
                                                <div className={styles['right']}>
                                                    <div className={styles['title']}><Link to={`/news/article/${item.article_id}`} target="_blank">{item.title}</Link></div>
                                                    <div className={styles['info']}>{item.info}</div>
                                                    <div className={styles['type']}>{item.channel.name}</div>
                                                    <div className={styles['time']}>{item.create_time}</div>
                                                    <div className={styles.amount}>阅读量：3.5W</div>
                                                </div>
                                                </div>
                                            </li>
                                        )
                                        }
                                        return ''
                                    })
                                }
                                
                            </ul>
                            <div className={styles.page}>
                            <ul className={styles['page-num']}>
                                <li className={styles['pn-prev']} onClick={this.prevPage.bind(this)}>上一页</li>
                                <li className={styles['pn-next']} onClick={this.nextPage.bind(this)}>下一页</li>
                            </ul>
                            </div>
                        </div>
                    </div>
                    <div className={styles.rightarea}>
                        <div className={styles.blankwhite}>
                            <div className={styles.tit}>
                                <span><img src={require('../../assets/images/tit1-list.png')} alt='img'/></span>
                                <em>Hot Tags</em>
                            </div>
                            <div className={styles.tags}>
                                <a href='http://lbmedu.com/news/article/3191' target='_blank' rel="noopener noreferrer">视频录制</a>
                                <a href='http://lbmedu.com/news/article/3087' target='_blank' rel="noopener noreferrer">作曲解析</a>
                                <a href='http://lbmedu.com/news/article/2554' target='_blank' rel="noopener noreferrer">伯克利</a>
                                <a href='http://lbmedu.com/news/article/2834' target='_blank' rel="noopener noreferrer">耶鲁大学</a>
                                <a href='http://lbmedu.com/news/article/2834' target='_blank' rel="noopener noreferrer">雅各布斯</a>
                                <a href='http://lbmedu.com/news/article/1344' target='_blank' rel="noopener noreferrer">面试经验</a>
                                <a href='http://lbmedu.com/news/article/2686' target='_blank' rel="noopener noreferrer">伊斯特曼</a>
                                <a href='http://lbmedu.com/news/article/1423' target='_blank' rel="noopener noreferrer">百老汇</a>
                                <a href='http://lbmedu.com/news/article/2859' target='_blank' rel="noopener noreferrer">花腔高音</a>
                                <a href='http://lbmedu.com/news/article/2149' target='_blank' rel="noopener noreferrer">商务音乐</a>
                            </div>
                        </div>
                        <div className={styles.blankwhite}>
                            <div className={styles.tit}>
                                <span><img src={require('../../assets/images/tit2-list.png')} alt='img'/></span>
                            </div>
                            <div className={styles.videos}>
                                <div className={styles.item}>
                                    <div className={styles.pic}>
                                        <img src={require('../../assets/images/banner-s.jpg')} alt='img'/>
                                        <div className={styles.playbtn}></div>
                                    </div>
                                    <div className={styles.title}>这里是摘要，这里是摘要这里是 是摘要这里是摘要这里是摘要</div>
                                    <div className={styles.line}>来源：音乐无界</div>
                                    <div className={styles.line}>2019-04-13 15:11</div>
                                </div>
                                <div className={styles.item}>
                                    <div className={styles.pic}>
                                        <img src={require('../../assets/images/banner-s.jpg')} alt='img'/>
                                        <div className={styles.playbtn}></div>
                                    </div>
                                    <div className={styles.title}>这里是摘要，这里是摘要这里是 是摘要这里是摘要这里是摘要</div>
                                    <div className={styles.line}>来源：音乐无界</div>
                                    <div className={styles.line}>2019-04-13 15:11</div>
                                </div>
                                <div className={styles.item}>
                                    <div className={styles.pic}>
                                        <img src={require('../../assets/images/banner-s.jpg')} alt='img'/>
                                        <div className={styles.playbtn}></div>
                                    </div>
                                    <div className={styles.title}>这里是摘要，这里是摘要这里是 是摘要这里是摘要这里是摘要</div>
                                    <div className={styles.line}>来源：音乐无界</div>
                                    <div className={styles.line}>2019-04-13 15:11</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* 底部 */}
                <Footer />
            </div>
        )
    }
}
export default List