import React, { Component } from 'react'
import styles from '../Personal/Personal.css'
import '../../config'

class PersonalNews extends Component {
  constructor(props){
    super(props)
    this.state = {
      message: []
    }
  }
  componentDidMount(){
    fetch(global.constants.api_url + '/v1/message/list', {
      method: 'GET',
      headers: {
        'token':this.operCookie().get('token')
      }
    })
    .then(response => {
      response.json().then(data => {
        this.setState({
          message: data.data
        })
      })
    })
    .catch(error => {
      console.log(error)
    })
  }
  operCookie(){
    var cookie = {
      set:function(key,val,time){//设置cookie方法
          var date=new Date(); //获取当前时间
          var expiresDays=time;  //将date设置为n天以后的时间
          date.setTime(date.getTime()+expiresDays*24*3600*1000); //格式化为cookie识别的时间
          document.cookie=key + "=" + val +";expires="+date.toGMTString();  //设置cookie
      },
      get:function(key){//获取cookie方法
          /*获取cookie参数*/
          var getCookie = document.cookie.replace(/[ ]/g,"");  //获取cookie，并且将获得的cookie格式化，去掉空格字符
          var arrCookie = getCookie.split(";")  //将获得的cookie以"分号"为标识 将cookie保存到arrCookie的数组中
          var tips;  //声明变量tips
          for(var i=0;i<arrCookie.length;i++){   //使用for循环查找cookie中的tips变量
              var arr=arrCookie[i].split("=");   //将单条cookie用"等号"为标识，将单条cookie保存为arr数组
              if(key===arr[0]){  //匹配变量名称，其中arr[0]是指的cookie名称，如果该条变量为tips则执行判断语句中的赋值操作
                  tips=arr[1];   //将cookie的值赋给变量tips
                  break;   //终止for循环遍历
              }
          }
        
          return tips;
      },
      delete:function(key){ //删除cookie方法
        var date = new Date(); //获取当前时间
        date.setTime(date.getTime()-10000); //将date设置为过去的时间
        document.cookie = key + "=v; expires =" +date.toGMTString();//设置cookie
       }
    }
    return cookie
  }
  render(){
    const { message } = this.state
    return (
      <div className={`${styles.block} ${styles.radius5}`}>
        <div className={styles['title-block']}>
          <span className={styles.name}>消息通知</span>
        </div>
        <ul className={styles['per-list2']}>
          {
            message.map((item, index) => {
              return (
                <li key={index}>
                  <img className={styles.pic} src={require('../../assets/images/m-logo.jpg')} alt=""/>
                  <div className={styles.info}>
                    <div className={styles.top}>
                      <div className={styles.name}>{item.title}</div>
                      <div className={styles.time}>{item.create_time}</div>
                    </div>
                    <div className={styles.intr}>Ant Design是一个服务于企业级产品的设计体系，基于『确定』和『自然』的设计价值观和模块化的解决方案，让设计者专注于更好的用户体验，设计价值观和模块化的解决方案，让设计者专注于更好的用户体验设计价值观和模块化</div>
                  </div>
                </li>
              )
            })
          }
        </ul>
      </div>
    )
  }
}
export default PersonalNews