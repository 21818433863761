import { combineReducers } from 'redux'
// import todos from './todos'
// import visibilityFilter from './visibilityFilter'

import {INCREMENT_COUNTER, DECREMENT_COUNTER, PERSONAL_CHANGE, SCHOOL_LIST, USER_INFO} from '../actions';

const counter = (state = 0, action) => {
    switch (action.type){
        case INCREMENT_COUNTER:
            return state+1;
        case DECREMENT_COUNTER:
            return state-1;
        default:
            return state;
    }
}
const personalTab = (state = 'home', action) => {
    switch(action.type){
        case PERSONAL_CHANGE:
            return action.payload
        default:
            return state
    }
}
const schoolData = (state=[], action) => {
    switch(action.type){
        case SCHOOL_LIST:
            return action.payload;
        default:
            return state;
    }
}
const userData = (state='', action) => {
    switch(action.type){
        case USER_INFO:
            return JSON.stringify(action.payload);
        default:
            return state;
    }
}


const rootReducer = combineReducers({
    counter,
    personalTab,
    schoolData,
    userData
});
export default rootReducer;