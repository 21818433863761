import React, { Component } from 'react'
import styles from './Planning.css'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Layer from '../../components/Layer/Layer'
import OfferList from '../../components/OfferList/OfferList'
import Banner from '../../components/Banner/Banner'
import { Link } from 'react-router-dom'

class Planning extends Component {
  constructor(props){
    super(props)
    this.state = {
      show:false,
      typeShow: '1',
      bannerArr: []
    }
  }
  componentDidMount(){
    window.scrollTo(0, 0)
    // banner
    fetch(global.constants.api_url + '/v1/banner/list?location=5', {
      method: 'GET'
    })
    .then( response => {
      response.json().then( data => {
        this.setState({
          bannerArr: data.data
        })
      })
    })
    .catch( error => {
      console.log(error)
    })
  }
  handleTypeShow(e){
    let type = e.currentTarget.getAttribute('data-type')
    this.setState({
      typeShow: type
    })
  }
  handleAssessment(){
    this.setState({
      show: true
    })
  }
  render(){
    const { show, typeShow } = this.state
    return(
      <div className={styles.ghbg}>
        {/* 头部 */}
        <Header />
        {/* banner */}
        <Banner banner={this.state.bannerArr}/>
        <div className={styles.ghbg1}></div>
        <div className={styles.ghbg2}></div>
        <div className={styles.cpblock}>
          <div className={styles.left}>
            <img src={require('../../assets/images/gh_05.jpg')} alt='pg'/>
          </div>
          <div className={styles.right}>
            <div className={styles.tab}>
              <div className={`${styles.item} ${typeShow === '1' ? styles.on : ''}`} data-type='1' onClick={this.handleTypeShow.bind(this)}>
                <span>声乐／歌剧／音乐剧表演</span>
                <em>Voice/Opera/Musical Theatre</em>
              </div>
              <div className={`${styles.item} ${typeShow === '2' ? styles.on : ''}`} data-type='2' onClick={this.handleTypeShow.bind(this)}>
                <span>乐器演奏</span>
                <em>Instrument Performance</em>
              </div>
              <div className={`${styles.item} ${typeShow === '3' ? styles.on : ''}`} data-type='3' onClick={this.handleTypeShow.bind(this)}>
                <span>音乐科技</span>
                <em>Music Tech</em>
              </div>
            </div>
            <div className={styles.show} style={{display: typeShow === '1' ? 'block' : 'none'}}>
              <img src={require('../../assets/images/yyby.png')} alt='by'/>
            </div>
            <div className={styles.show} style={{display: typeShow === '2' ? 'block' : 'none'}}>
              <img src={require('../../assets/images/yyyz.png')} alt='yz'/>
            </div>
            <div className={styles.show} style={{display: typeShow === '3' ? 'block' : 'none'}}>
              <img src={require('../../assets/images/yykj.png')} alt='kj'/>
            </div>
          </div>
        </div>
        <div className={styles.ghbg6}>
          <div className="main" onClick={this.handleAssessment.bind(this)} style={{ width:'1200px', margin:'0 auto', height: '111px', cursor: 'pointer'}}></div>
        </div>
        <div className={styles.ghbg7}></div>
        <div className={styles.ghbg8}></div>
        <div className={styles.ghbg9}></div>
        <div className={styles.ghbg10}></div>
        <div className={styles.ghbg11}></div>
        <div className={styles.ghbg12}>
          <div className="main" onClick={this.handleAssessment.bind(this)} style={{ width:'1200px', margin:'0 auto', height: '127px', cursor: 'pointer'}}></div>
        </div>
        <div className={styles.ghbg13}></div>
        <div className={styles.ghbg14}></div>
        <div className={styles.ghbg15}></div>
        <div className={styles.ghbg16}></div>
        <div className={styles.ghbg17}></div>
        <div className={styles.ghbg18}></div>
        <div className={styles.ghbg19}><div className="main" style={{ width:'1200px', height:'152px',margin:'0 auto',position:'relative'}}><Link style={{ position: 'absolute', right:'0px', top:'0',width:'200px', height:'152px'}} to='/offer'></Link></div></div>
        
        <div className="main">
          {/* offer */}
          <div className={styles.offer}>
            <div className={styles.main}>
              <OfferList />
            </div>
          </div>
        </div>
        <Layer show={show} />
        {/* 底部 */}
        <Footer />
      </div>
    )
  }
}
export default Planning