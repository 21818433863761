import React, { Component } from 'react'
import styles from './Article.css'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import { Link } from 'react-router-dom'
import '../../config'

class Article extends Component {
  constructor(props){
    super(props)
    this.state = {
      article: {},
      recommend: [],
      collegesList:[],
      colleges:'意向院校',
      collegesShow: false,
      majorList:[],
      major:'申请专业',
      majorShow: false,
      major_id:'',
      school_id:'',
      tipsMessage:'',
      tipsShow:false
    }
  }
  handleNameChange(e){
    this.setState({
      realname: e.target.value
    })
  }
  handleTelChange(e){
    this.setState({
      mobile: e.target.value
    })
  }
  majorHandle(){
    fetch(global.constants.api_url + '/v1/major/selectlist',{
      method:'GET'
    })
    .then((response) => {
      response.json().then((data) => {
        this.setState({
          majorShow:true,
          majorList:data.data
        })
        
      })
    })
    .catch((error) => {
      console.log(error)
    })
  }
  majorItem(e){
    e.nativeEvent.stopImmediatePropagation()
    let id = e.target.getAttribute('data-id'),
        name = e.target.getAttribute('data-name')
    this.setState({
      major: name,
      majorShow: false,
      major_id: id
    })
  }
  collegesHandle(){
    fetch(global.constants.api_url + '/v1/school/selectlist', {
      method: 'GET'
    })
    .then((response) => {
      response.json().then((data) => {
        this.setState({
          collegesShow: true,
          collegesList: data.data
        })
      })
    })
    .catch((error) => {
      console.log(error)
    })
  }
  collegesItem(e){
    e.nativeEvent.stopImmediatePropagation()
    let name = e.target.getAttribute('data-name'),
        id = e.target.getAttribute('data-id')
    this.setState({
      colleges: name,
      collegesShow: false,
      school_id: id
    })
  }
  assessHandle(){
    const { realname, mobile, major_id, school_id } = this.state
    console.log(this.state)
    let data = {
      'realname': realname,
      'mobile': mobile,
      'major_id': major_id,
      'school_id': school_id
    }
    console.log(data)
    fetch(global.constants.api_url + '/v1/assess/apply', {
      method: 'POST',
      headers: {
        'token': this.operCookie().get('token')
      },
      body: JSON.stringify(data)
    })
    .then((response) => {
      response.json().then((data) => {
        //console.log(data)
        if(data.code === 0){
          this.setState({
            layerShow: false
          })
        }
        //alert(data.message)
        this.setState({
          tipsShow: true,
          tipsMessage: data.message
        })
        setTimeout(()=>{
          this.setState({
            tipsShow: false
          })
        },1000)
        
      })
    })
    .catch((error) => {
      console.log(error)
    })
  }
  operCookie(){
    var cookie = {
      set:function(key,val,time){//设置cookie方法
          var date=new Date(); //获取当前时间
          var expiresDays=time;  //将date设置为n天以后的时间
          date.setTime(date.getTime()+expiresDays*24*3600*1000); //格式化为cookie识别的时间
          document.cookie=key + "=" + val +";expires="+date.toGMTString();  //设置cookie
      },
      get:function(key){//获取cookie方法
          /*获取cookie参数*/
          var getCookie = document.cookie.replace(/[ ]/g,"");  //获取cookie，并且将获得的cookie格式化，去掉空格字符
          var arrCookie = getCookie.split(";")  //将获得的cookie以"分号"为标识 将cookie保存到arrCookie的数组中
          var tips;  //声明变量tips
          for(var i=0;i<arrCookie.length;i++){   //使用for循环查找cookie中的tips变量
              var arr=arrCookie[i].split("=");   //将单条cookie用"等号"为标识，将单条cookie保存为arr数组
              if(key===arr[0]){  //匹配变量名称，其中arr[0]是指的cookie名称，如果该条变量为tips则执行判断语句中的赋值操作
                  tips=arr[1];   //将cookie的值赋给变量tips
                  break;   //终止for循环遍历
              }
          }
        
          return tips;
      },
      delete:function(key){ //删除cookie方法
        var date = new Date(); //获取当前时间
        date.setTime(date.getTime()-10000); //将date设置为过去的时间
        document.cookie = key + "=v; expires =" +date.toGMTString();//设置cookie
       }
    }
    return cookie
  }
  componentDidMount(){
    window.scrollTo(0,0);
    // window.location.reload()
    let url = window.location.href,
        arrUrl = url.split('/'),
        id = arrUrl[arrUrl.length-1]
    
    fetch(global.constants.api_url + '/v1/article/item?id='+id , {
      method: 'GET'
    })
    .then( response => {
      response.json().then( data => {
        console.log(data)
        this.setState({
          article: data.data.article,
          channelName: data.data.article.channel.name,
          recommend: data.data.recommend,
          data:data.data
        })
      })
    })
    .catch( error => {
      console.log(error)
    })
  }
  componentWillReceiveProps(nextProps){
    // console.log(nextProps)
    // console.log(this.props.location)
    // console.log(nextProps.location)
    if(this.props.location !== nextProps.location){
        window.scrollTo(0,0)
        window.location.reload()
    }
  }
  render(){
    const { article, recommend, tipsMessage, tipsShow } = this.state
    return (
      <div className={styles.bgf0}>
        {/* 头部 */}
        <Header />
        <div className={styles.banner}><img src={require('../../assets/images/banner-details.jpg')} alt=""/></div>
        <div className="wrap">
          <div className={styles.article}>
            <div className={styles.left}>
              <div className={styles.position}>
                <span><Link to='/news/list'>音乐资讯</Link> > 文章资讯 > <em>正文</em></span>
              </div>
              <h1>{article.title}</h1>
              <div className={styles.source}>
              <p>来源：{article.source}</p> | <em>{article.create_time}</em>
              </div>
              <div className={styles.intr}>{article.summary}</div>
              <div className={styles.content} 
              dangerouslySetInnerHTML={{ __html: article.content }}
              >
              </div>
            </div>
            <div className={styles.right}>
              <div className={styles.blankwhite}>
                  <div className={styles.tit}>
                      <span><img src={require('../../assets/images/tit3-list.png')} alt='img'/></span>
                  </div>
                  <div className={styles.padT20}>
                  {
                    recommend.map((item, index)=>{
                      return (
                        <div className={styles.aboutnews}>
                          <div className={styles.anleft}>
                            <span>{index+1}</span>
                          </div>
                          <div className={styles.text}>
                            <div className={styles.bt}><Link to={`/news/article/${item.article_id}`}>{item.title}</Link></div>
                            <div className={styles.time}>{item.create_time}</div>
                          </div>
                          <div className={styles.pic}>
                          <Link to={`/news/article/${item.article_id}`}><img src={item.image[0].image_url} alt="img"/></Link>
                          </div>
                        </div>
                      )
                    })
                  }
                  </div>
              </div>
              <div className={styles.blankwhite} style={{display:'none'}}>
                  <div className={styles.tit}>
                      <span><img src={require('../../assets/images/tit2-list.png')} alt='img'/></span>
                  </div>
                  <div className={styles.videos}>
                      <div className={styles.item}>
                          <div className={styles.pic}>
                              <img src={require('../../assets/images/banner-s.jpg')} alt='img'/>
                              <div className={styles.playbtn}></div>
                          </div>
                          <div className={styles.title}>这里是摘要，这里是摘要这里是 是摘要这里是摘要这里是摘要</div>
                          <div className={styles.line}>来源：音乐无界</div>
                          <div className={styles.line}>2019-04-13 15:11</div>
                      </div>
                      <div className={styles.item}>
                          <div className={styles.pic}>
                              <img src={require('../../assets/images/banner-s.jpg')} alt='img'/>
                              <div className={styles.playbtn}></div>
                          </div>
                          <div className={styles.title}>这里是摘要，这里是摘要这里是 是摘要这里是摘要这里是摘要</div>
                          <div className={styles.line}>来源：音乐无界</div>
                          <div className={styles.line}>2019-04-13 15:11</div>
                      </div>
                      <div className={styles.item}>
                          <div className={styles.pic}>
                              <img src={require('../../assets/images/banner-s.jpg')} alt='img'/>
                              <div className={styles.playbtn}></div>
                          </div>
                          <div className={styles.title}>这里是摘要，这里是摘要这里是 是摘要这里是摘要这里是摘要</div>
                          <div className={styles.line}>来源：音乐无界</div>
                          <div className={styles.line}>2019-04-13 15:11</div>
                      </div>
                  </div>
              </div>
            </div>
            <div className="layer-tips" style={{'display': tipsShow === true ? 'block' : 'none'}}>{tipsMessage}</div>
          </div>
        </div>
        {/* 底部 */}
        <Footer />
      </div>
    )
  }
}
export default Article