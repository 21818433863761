import React, {Component} from 'react'
import './Achieve.css'

class Achieve extends Component {
  render(){
    return(
      <div className='achieve'>
        <div className='wrap'>
          <ul>
            <li className='li1'>
              <span>300+</span>
              <p>国际音乐名校合作</p>
            </li>
            <li className='li2'>
              <span>1000+</span>
              <p>名校教授</p>
            </li>
            <li className='li3'>
              <span>800+</span>
              <p>份成功留学offer</p>
            </li>
            <li className='li4'>
              <span>3000+</span>
              <p>评估规划报告</p>
            </li>
            <li className='li5'>
              <span>99%</span>
              <p>留学成功率</p>
            </li>
            <li className='li6'>
              <span>75%</span>
              <p>奖学金成功申请率</p>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}
export default Achieve