import React, { Component } from 'react'
import styles from '../Personal/Personal.css'
import '../../config'

class PersonalMy extends Component {
  constructor(props){
    super(props)
    this.state = {
      studyList:[],
      collectionList:[],
      showList: 1,
      showTel: ''
    }
  }
  componentDidMount(){
    document.addEventListener('click',() => {
      this.setState({
        showTel: ''
      })
    })
    // 最近学习
    fetch(global.constants.api_url + '/v1/course/my?page=1', {
      method: 'GET',
      headers: {
        'Accept':'application/json',
        'token': this.operCookie().get('token')
      },
      
    })
    .then( response => {
      response.json().then( data => {
        this.setState({
          studyList: data.data
        })
      })
    })
    .catch( error => {
      console.log(error)
    })
    // 我的收藏
    fetch(global.constants.api_url + '/v1/course/followlist', {
      method: 'GET',
      headers: {
        'token': this.operCookie().get('token')
      }
    })
    .then(response => {
      response.json().then(data => {
        this.setState({
          collectionList: data.data
        })
      })
    })
    .catch(error => {
      console.log(error)
    })
  }
  toTab(e){
    let type = parseInt(e.target.getAttribute('data-type'))
    this.setState({
      showList: type
    })
  }
  showTel(e){
    e.nativeEvent.stopImmediatePropagation()
    this.setState({
      showTel: 'on'
    })
  }
  operCookie(){
    var cookie = {
      set:function(key,val,time){//设置cookie方法
          var date=new Date(); //获取当前时间
          var expiresDays=time;  //将date设置为n天以后的时间
          date.setTime(date.getTime()+expiresDays*24*3600*1000); //格式化为cookie识别的时间
          document.cookie=key + "=" + val +";expires="+date.toGMTString();  //设置cookie
      },
      get:function(key){//获取cookie方法
          /*获取cookie参数*/
          var getCookie = document.cookie.replace(/[ ]/g,"");  //获取cookie，并且将获得的cookie格式化，去掉空格字符
          var arrCookie = getCookie.split(";")  //将获得的cookie以"分号"为标识 将cookie保存到arrCookie的数组中
          var tips;  //声明变量tips
          for(var i=0;i<arrCookie.length;i++){   //使用for循环查找cookie中的tips变量
              var arr=arrCookie[i].split("=");   //将单条cookie用"等号"为标识，将单条cookie保存为arr数组
              if(key===arr[0]){  //匹配变量名称，其中arr[0]是指的cookie名称，如果该条变量为tips则执行判断语句中的赋值操作
                  tips=arr[1];   //将cookie的值赋给变量tips
                  break;   //终止for循环遍历
              }
          }
        
          return tips;
      },
      delete:function(key){ //删除cookie方法
        var date = new Date(); //获取当前时间
        date.setTime(date.getTime()-10000); //将date设置为过去的时间
        document.cookie = key + "=v; expires =" +date.toGMTString();//设置cookie
       }
    }
    return cookie
  }
  render(){
    const { studyList, collectionList, showList, showTel } = this.state
    return (
      <div>
        <div className={`${styles.block1} marB20`}>
          <div className={styles.name}>
            <div className={styles.title}>设备检测</div>
            <span>为了您能获得更好的上课效果，请您开始检测电脑和网络。</span>
          </div>
          {/* <span className={styles.button1}>立即检测</span> */}
        </div>
        <div className={styles.block1}>
          <div className={styles.name}>
            <div className={styles.title}>体验免费课程</div>
          </div>
          <span className={styles.button2} onClick={this.showTel.bind(this)}>立即预约</span>
          <div className={styles.pop} style={{'display': showTel === 'on' ? 'block' : 'none'}}>
            <div className={styles.title}>预约电话</div>
            <div className={styles.tel}>010-57026290 / 13681053579</div>
          </div>
        </div>
        {/* 列表 */}
        <div className={`${styles['title-block']} marT20`}>
          <span className={showList === 1 ? styles.on : ''} onClick={this.toTab.bind(this)} data-type='1'>最近学习</span>
          <span className={showList === 2 ? styles.on : ''} onClick={this.toTab.bind(this)} data-type='2'>我的收藏</span>
        </div>
        <ul className={`${styles['per-list']}`} style={{'display': showList === 1 ? 'block' : 'none'}}>
          {/* <li key={index}>
            <div className={styles.left}>
              <div className={styles.itemline}>
                <span>课程：{item.plan_text}</span>
                <span>老师：{item.teacher_text}</span>
              </div>
              <div className={styles.itemline}>
                <span>时间：{item.datetime}</span>
                <span>高教：{item.professor_text}</span>
              </div>
              <div className={styles.work}>作业：专业能力提高水平课程作业，专业能力提高水平课程作业专业能力提高水平课程作业专业能力 提高水平课程作业</div>
              <div className={styles.jd}>课程进度：2/20节<span className={styles.linestep}><em></em></span></div>
            </div>
            <div className={styles.right}>
              <span className={styles.btn1}>课程回放</span>
            </div>
          </li> */}
          
          {
            studyList.map((item, index) => {
              return (
                <li key={index}>
                <img className={styles.pic} src={item.image} alt=""/>
                <div className={styles.intr}>
                  <div className={styles.name}>课程: {item.title}</div>
                  <div className={styles.info}>时间: {item.create_time}</div>
                  <div className={styles.info}>老师: {item.lecturer.name}</div>
                </div>
                {/* <span className={styles.button2}>课程回顾</span>
                <i className={styles.icon1}></i> */}
              </li>
              )
            })
          }
        </ul>
        <ul className={styles['per-list']} style={{'display': showList === 2 ? 'block' : 'none'}}>
          {
            collectionList.map((item, index) => {
              return (
                <li key={index}>
                <img className={styles.pic} src={item.image} alt=""/>
                <div className={styles.intr}>
                  <div className={styles.name}>课程: {item.title}</div>
                  <div className={styles.info}>学习人数: {item.learn_num}</div>
                  <div className={styles.info}>老师: {item.lecturer.name}</div>
                </div>
                {/* <span className={styles.button2}>取消收藏</span> */}
              </li>
              )
            })
          }
        </ul>
      </div>
    )
  }
}
export default PersonalMy