import React, { Component } from 'react'
import './OfferList.css'

import Swiper from 'swiper/dist/js/swiper.js'
import 'swiper/dist/css/swiper.min.css'

class OfferList extends Component{
    componentDidMount(){
        new Swiper('.swiper-container-offer',{
          //autoplay: true,
          speed:1000,
          autoplayDisableOnInteraction : false,
          loop:true,
          spaceBetween: 35,
          centeredSlides : true,
          pagination: {
            el: '.swiper-pagination-offer',
            clickable: true,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }
        })
    }
    render(){
        return (
            <div className='offer-list'>
              <div className='swiper-container-offer'>
                <div className='swiper-wrapper'>
                  <div className='swiper-slide'>
                    <ul>
                      <li className='licol1'>
                        <div className='pic'>
                          <div className='c-block'></div>
                          <img src={require('../../assets/images/st1.jpg')} alt=""/>
                        </div>
                        <div className='info'>
                          <div className='name'>蔡同学</div>
                          <span className='line'></span>
                          <p className='school'>伯克利音乐学院</p>
                          <p className='major'>声乐硕士</p>
                        </div>
                      </li>
                      <li className='licol2'>
                        <div className='pic'>
                          <div className='c-block'></div>
                          <img src={require('../../assets/images/offer1.jpg')} alt=""/>
                        </div>
                        <div className='info'>
                          <div className='name'>陈同学</div>
                          <span className='line'></span>
                          <p className='school'>瓦格纳学院</p>
                          <p className='major'>声乐本科</p>
                        </div>
                      </li>
                      <li className='licol3'>
                        <div className='pic'>
                          <div className='c-block'></div>
                          <img src={require('../../assets/images/st2.jpg')} alt=""/>
                        </div>
                        <div className='info'>
                          <div className='name'>陈同学</div>
                          <span className='line'></span>
                          <p className='school'>新英格兰音乐学院</p>
                          <p className='major'>声乐本科</p>
                        </div>
                      </li>
                      <li className='licol4'>
                        <div className='pic'>
                          <div className='c-block'></div>
                          <img src={require('../../assets/images/offer2.jpg')} alt=""/>
                        </div>
                        <div className='info'>
                          <div className='name'>陈同学</div>
                          <span className='line'></span>
                          <p className='school'>长岛大学</p>
                          <p className='major'>音乐剧本科</p>
                        </div>
                      </li>
                      <li className='licol1'>
                        <div className='pic'>
                          <div className='c-block'></div>
                          <img src={require('../../assets/images/st3.jpg')} alt=""/>
                        </div>
                        <div className='info'>
                          <div className='name'>姚同学</div>
                          <span className='line'></span>
                          <p className='school'>曼哈顿音乐学院</p>
                          <p className='major'>硕士</p>
                        </div>
                      </li>
                      <li className='licol2'>
                        <div className='pic'>
                          <div className='c-block'></div>
                          <img src={require('../../assets/images/offer3.jpg')} alt=""/>
                        </div>
                        <div className='info'>
                          <div className='name'>张同学</div>
                          <span className='line'></span>
                          <p className='school'>旧金山音乐学院</p>
                          <p className='major'>声乐本科</p>
                        </div>
                      </li>
                      <li className='licol3'>
                        <div className='pic'>
                          <div className='c-block'></div>
                          <img src={require('../../assets/images/st4.jpg')} alt=""/>
                        </div>
                        <div className='info'>
                          <div className='name'>沙同学</div>
                          <span className='line'></span>
                          <p className='school'>加州艺术学院</p>
                          <p className='major'>声乐硕士</p>
                        </div>
                      </li>
                      <li className='licol4'>
                        <div className='pic'>
                          <div className='c-block'></div>
                          <img src={require('../../assets/images/offer4.jpg')} alt=""/>
                        </div>
                        <div className='info'>
                          <div className='name'>刘同学</div>
                          <span className='line'></span>
                          <p className='school'>纽约大学</p>
                          <p className='major'>声乐本科</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className='swiper-slide'>
                    <ul>
                      <li className='licol1'>
                        <div className='pic'>
                          <div className='c-block'></div>
                          <img src={require('../../assets/images/st5.jpg')} alt=""/>
                        </div>
                        <div className='info'>
                          <div className='name'>王同学</div>
                          <span className='line'></span>
                          <p className='school'>隆基音乐学院</p>
                          <p className='major'>钢琴硕士</p>
                        </div>
                      </li>
                      <li className='licol2'>
                        <div className='pic'>
                          <div className='c-block'></div>
                          <img src={require('../../assets/images/offer5.jpg')} alt=""/>
                        </div>
                        <div className='info'>
                          <div className='name'>彭同学</div>
                          <span className='line'></span>
                          <p className='school'>欧柏林音乐学院</p>
                          <p className='major'>声乐本科</p>
                        </div>
                      </li>
                      <li className='licol3'>
                        <div className='pic'>
                          <div className='c-block'></div>
                          <img src={require('../../assets/images/st6.jpg')} alt=""/>
                        </div>
                        <div className='info'>
                          <div className='name'>张同学</div>
                          <span className='line'></span>
                          <p className='school'>茱莉亚音乐学院</p>
                          <p className='major'>钢琴预科</p>
                        </div>
                      </li>
                      <li className='licol4'>
                        <div className='pic'>
                          <div className='c-block'></div>
                          <img src={require('../../assets/images/offer6.jpg')} alt=""/>
                        </div>
                        <div className='info'>
                          <div className='name'>王同学</div>
                          <span className='line'></span>
                          <p className='school'>皮博迪音乐学院</p>
                          <p className='major'>声乐硕士</p>
                        </div>
                      </li>
                      <li className='licol1'>
                        <div className='pic'>
                          <div className='c-block'></div>
                          <img src={require('../../assets/images/st7.jpg')} alt=""/>
                        </div>
                        <div className='info'>
                          <div className='name'>左同学</div>
                          <span className='line'></span>
                          <p className='school'>伯克利音乐学院</p>
                          <p className='major'>声乐本科</p>
                        </div>
                      </li>
                      <li className='licol2'>
                        <div className='pic'>
                          <div className='c-block'></div>
                          <img src={require('../../assets/images/offer7.jpg')} alt=""/>
                        </div>
                        <div className='info'>
                          <div className='name'>张同学</div>
                          <span className='line'></span>
                          <p className='school'>索诺马州大学</p>
                          <p className='major'>音乐剧本科</p>
                        </div>
                      </li>
                      <li className='licol3'>
                        <div className='pic'>
                          <div className='c-block'></div>
                          <img src={require('../../assets/images/st8.jpg')} alt=""/>
                        </div>
                        <div className='info'>
                          <div className='name'>张同学</div>
                          <span className='line'></span>
                          <p className='school'>新英格兰音乐学院</p>
                          <p className='major'>声乐本科</p>
                        </div>
                      </li>
                      <li className='licol4'>
                        <div className='pic'>
                          <div className='c-block'></div>
                          <img src={require('../../assets/images/offer8.jpg')} alt=""/>
                        </div>
                        <div className='info'>
                          <div className='name'>李同学</div>
                          <span className='line'></span>
                          <p className='school'>辛辛那提</p>
                          <p className='major'>声乐本科</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className='swiper-slide'>
                    <ul>
                      <li className='licol1'>
                        <div className='pic'>
                          <div className='c-block'></div>
                          <img src={require('../../assets/images/st9.jpg')} alt=""/>
                        </div>
                        <div className='info'>
                          <div className='name'>王同学</div>
                          <span className='line'></span>
                          <p className='school'>曼哈顿音乐学院</p>
                          <p className='major'>声乐本科</p>
                        </div>
                      </li>
                      <li className='licol2'>
                        <div className='pic'>
                          <div className='c-block'></div>
                          <img src={require('../../assets/images/offer9.jpg')} alt=""/>
                        </div>
                        <div className='info'>
                          <div className='name'>马同学</div>
                          <span className='line'></span>
                          <p className='school'>新英格兰音乐学院</p>
                          <p className='major'>声乐本科</p>
                        </div>
                      </li>
                      <li className='licol3'>
                        <div className='pic'>
                          <div className='c-block'></div>
                          <img src={require('../../assets/images/st10.jpg')} alt=""/>
                        </div>
                        <div className='info'>
                          <div className='name'>徐同学</div>
                          <span className='line'></span>
                          <p className='school'>洛杉矶音乐学院</p>
                          <p className='major'>钢琴本科</p>
                        </div>
                      </li>
                      <li className='licol4'>
                        <div className='pic'>
                          <div className='c-block'></div>
                          <img src={require('../../assets/images/offer10.jpg')} alt=""/>
                        </div>
                        <div className='info'>
                          <div className='name'>张同学</div>
                          <span className='line'></span>
                          <p className='school'>休斯顿大学音乐学院</p>
                          <p className='major'>作曲本科</p>
                        </div>
                      </li>
                      <li className='licol1'>
                        <div className='pic'>
                          <div className='c-block'></div>
                          <img src={require('../../assets/images/st11.jpg')} alt=""/>
                        </div>
                        <div className='info'>
                          <div className='name'>秦同学</div>
                          <span className='line'></span>
                          <p className='school'>曼尼斯音乐学院</p>
                          <p className='major'>声乐本科</p>
                        </div>
                      </li>
                      <li className='licol2'>
                        <div className='pic'>
                          <div className='c-block'></div>
                          <img src={require('../../assets/images/offer11.jpg')} alt=""/>
                        </div>
                        <div className='info'>
                          <div className='name'>黄同学</div>
                          <span className='line'></span>
                          <p className='school'>雅各布斯音乐学院</p>
                          <p className='major'>声乐硕士</p>
                        </div>
                      </li>
                      <li className='licol3'>
                        <div className='pic'>
                          <div className='c-block'></div>
                          <img src={require('../../assets/images/st12.jpg')} alt=""/>
                        </div>
                        <div className='info'>
                          <div className='name'>方同学</div>
                          <span className='line'></span>
                          <p className='school'>伯克利音乐学院</p>
                          <p className='major'>声乐本科</p>
                        </div>
                      </li>
                      <li className='licol4'>
                        <div className='pic'>
                          <div className='c-block'></div>
                          <img src={require('../../assets/images/offer12.jpg')} alt=""/>
                        </div>
                        <div className='info'>
                          <div className='name'>陈同学</div>
                          <span className='line'></span>
                          <p className='school'>伊萨卡音乐学院</p>
                          <p className='major'>声乐硕士</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={['swiper-pagination swiper-pagination-offer']}></div>
                <div className='button'>
                  <div className={['swiper-button-prev swiper-button-prev-white']}></div>
                  <div className={['swiper-button-next swiper-button-next-white']}></div>
                </div>
              </div>
            </div>
        )
    }
}
export default OfferList