import React, { Component } from 'react'
import styles from './JoinUs.css'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'

class JoinUs extends Component {
  componentDidMount(){
    window.scrollTo(0, 0)
  }
  render(){
    return (
      <div className={styles.bg}>
        {/* 头部 */}
        <Header/>
        <div class={styles.banner1}></div>
        <div class={styles.banner2}></div>
        <div class={styles.banner3}></div>
        <div class={styles.banner4}></div>
        <div class={styles.banner5}></div>
        <div class={styles.banner6}></div>
        <div class={styles.main}>
          <img src={require('../../assets/images/ru_09.jpg')} alt=""/>
          <img src={require('../../assets/images/ru_11.jpg')} alt=""/>
          <img src={require('../../assets/images/ru_12.jpg')} alt=""/>
          <img src={require('../../assets/images/ru_13.jpg')} alt=""/>
          <img src={require('../../assets/images/ru_14.jpg')} alt=""/>
          <img src={require('../../assets/images/ru_15.jpg')} alt=""/>
          <img src={require('../../assets/images/ru_16.jpg')} alt=""/>
        </div>
        <div class={styles.pay1}></div>
        <div class={styles.pay2}></div>
        <div class={styles.pay3}></div>
        <div class={styles.pay4}></div>
        <div class={styles.pay5}></div>
        <div class={styles.pay6}></div>
        <div class={styles.main}>
          <img src={require('../../assets/images/ru_24.jpg')} alt=""/>
          <img src={require('../../assets/images/ru_26.jpg')} alt=""/>
          <img src={require('../../assets/images/ru_27.jpg')} alt=""/>
          <img src={require('../../assets/images/ru_28.jpg')} alt=""/>
          <img src={require('../../assets/images/ru_29.jpg')} alt=""/>
          <img src={require('../../assets/images/ru_30.jpg')} alt=""/>
          <img src={require('../../assets/images/ru_31.jpg')} alt=""/>
          <img src={require('../../assets/images/ru_32.jpg')} alt=""/>
          <img src={require('../../assets/images/ru_33.jpg')} alt=""/>
          <img src={require('../../assets/images/ru_34.jpg')} alt=""/>
          <img src={require('../../assets/images/ru_35.jpg')} alt=""/>
          <img src={require('../../assets/images/ru_36.jpg')} alt=""/>
          <img src={require('../../assets/images/ru_37.jpg')} alt=""/>
          <img src={require('../../assets/images/ru_38.jpg')} alt=""/>
          <img src={require('../../assets/images/ru_39.jpg')} alt=""/>
          <img src={require('../../assets/images/ru_40.jpg')} alt=""/>
          <img src={require('../../assets/images/ru_41.jpg')} alt=""/>
          <img src={require('../../assets/images/ru_42.jpg')} alt=""/>
        </div>
        {/* 底部 */}
        <Footer/>
      </div>
    )
  }
}
export default JoinUs