import React, { Component } from 'react'
import styles from './header.css'
import { NavLink, Link, withRouter } from 'react-router-dom'
import '../../config'
require('es6-promise').polyfill()
require('isomorphic-fetch')

class Header extends Component {
  constructor(props){
    super(props)
    this.state = {
      isShow: false,
      display:'none',
      msg_id:0,
      tel:0,
      avatar:'http://imagecdn.yinyuewujie.com/lbm-default-avatar.png',
      nickname:'',
      token:'',
      tmptoken:'',
      tmpavatar:'http://imagecdn.yinyuewujie.com/lbm-default-avatar.png',
      timpnickname:'',
      currentPage:'index',
      getCodeTxt: '获取验证码',
      count: 60,
      codeLiked: true,
      menuShow: false,
      collegesUrl: 'colleges/majors'
    }
    this.popShow = this.popShow.bind(this)
    this.popHide = this.popHide.bind(this)
    this.smsSend = this.smsSend.bind(this)
    this.toLogin = this.toLogin.bind(this)
    this.operCookie = this.operCookie.bind(this)
    this.countDown = this.countDown.bind(this)
  }
  popShow(){
    this.setState({
      isShow:true
    }, ()=>{
      this.setState({
        display:this.state.isShow ? 'block' : 'none'
      })
    })
    
  }
  popHide(){
    this.setState({
      isShow:false
    }, ()=>{
      this.setState({
        display:this.state.isShow ? 'block' : 'none'
      })
    })
  }
  countDown(){
    const {count} = this.state
    if(count === 1){
      this.setState({
        count: 60,
        codeLiked: true
      })
    } else {
      this.setState({
        count: count - 1,
        codeLiked: false
      })
      setTimeout(this.countDown, 1000)
    }
  }
  smsSend(e){
    e.stopPropagation()
    this.setState({
      tel: this.refs.tel.value
    })
    const data = {mobile:this.refs.tel.value, event:'login'}
    fetch(global.constants.api_url + '/v1/sms/send', {
      method: 'POST',
      body:JSON.stringify(data)
    })
    .then( response => {
      return response.json()
    })
    .then( res => {
      if(res.code === 0){
        this.setState({
          msg_id:res.data.msg_id
        })
        this.countDown()
      }else if(res.code === 1001){
        console.log(res.message)
      }
      
    })
  }
  toLogin(){
    let code = this.refs.code.value
    const data = {mobile:this.state.tel, code:code, msg_id:this.state.msg_id}

    fetch(global.constants.api_url + '/v1/user/login', {
      method: 'POST',
      body:JSON.stringify(data)
    })
    .then( response => {
      return response.json()
    })
    .then( res => {
      if(res.code === 0){
        this.setState({
          avatar: res.data.user.avatar,
          nickname: res.data.user.nickname,
          tmpavatar: res.data.user.avatar,
          timpnickname: res.data.user.nickname,
          token:res.data.token,
          isShow:false
        }, ()=>{
          this.setState({
            display:this.state.isShow ? 'block' : 'none'
          })
          this.operCookie().set('token',this.state.token,30)
          this.operCookie().set('userAvatar',this.state.avatar,30)
          this.operCookie().set('userNickname',this.state.nickname,30)
          //window.location.reload()
        })
      }else if(res.code === 1001){
        console.log(res.message)
      }
      
    })
  }
  toLoginOut(){
    fetch(global.constants.api_url + '/v1/user/logout', {
      method: 'POST',
      headers: {
        'token': this.operCookie().get('token')
      }
    })
    .then(response => {
      response.json().then(data => {
        if(data.code === 0){
          this.setState({
            timpnickname: '',
            tmpavatar:'http://imagecdn.yinyuewujie.com/lbm-default-avatar.png'
          })
        }
        
      })
    })
    .catch(error => {
      console.log(error)
    })
  }
  toPersonal(){
    this.props.history.push({pathname:"/personal"})
  }
  handleMenuShow(){
    this.setState({
      menuShow: true
    })
  }
  handleMenuHide(){
    this.setState({
      menuShow: false
    })
  }
  operCookie(){
    // var storage = window.localStorage
    // storage.setItem('aaaaaaaaaa', 3);
    // var a = storage.getItem('aaaaaaaaaa');
    // console.log(a)
    // storage.removeItem('');
    var cookie = {
      set:function(key,val,time){//设置cookie方法
          var date=new Date(); //获取当前时间
          var expiresDays=time;  //将date设置为n天以后的时间
          date.setTime(date.getTime()+expiresDays*24*3600*1000); //格式化为cookie识别的时间
          document.cookie=key + "=" + val +";expires="+date.toGMTString();  //设置cookie
      },
      get:function(key){//获取cookie方法
          /*获取cookie参数*/
          var getCookie = document.cookie.replace(/[ ]/g,"");  //获取cookie，并且将获得的cookie格式化，去掉空格字符
          var arrCookie = getCookie.split(";")  //将获得的cookie以"分号"为标识 将cookie保存到arrCookie的数组中
          var tips;  //声明变量tips
          for(var i=0;i<arrCookie.length;i++){   //使用for循环查找cookie中的tips变量
              var arr=arrCookie[i].split("=");   //将单条cookie用"等号"为标识，将单条cookie保存为arr数组
              if(key===arr[0]){  //匹配变量名称，其中arr[0]是指的cookie名称，如果该条变量为tips则执行判断语句中的赋值操作
                  tips=arr[1];   //将cookie的值赋给变量tips
                  break;   //终止for循环遍历
              }
          }
        
          return tips;
      },
      delete:function(key){ //删除cookie方法
        var date = new Date(); //获取当前时间
        date.setTime(date.getTime()-10000); //将date设置为过去的时间
        document.cookie = key + "=v; expires =" +date.toGMTString();//设置cookie
       }
    }
    return cookie
  }
  componentDidMount() {
    let url = window.location.href
    let urlArr = url.split('/')
    if(urlArr[urlArr.length-1] === 'major'){
      this.setState({
        collegesUrl: 'colleges/major'
      })
    }
    if(urlArr[urlArr.length-1] === 'shcool'){
      this.setState({
        collegesUrl: 'colleges/shcool'
      })
    }
    fetch(global.constants.api_url + '/v1/user/profile', {
      method: 'GET',
      headers: {
        'Accept':'application/json',
        'token': this.operCookie().get('token')
      }
    })
    .then(response => {
      response.json().then(data => {
        if(data.code === 1000){
          // console.log(data.message)
        }else if(data.code === 0){
          this.setState({
            tmpavatar: data.data.avatar,
            timpnickname: data.data.nickname
          })
        }
        
      })
    })
    .catch(error => {
      console.log(error)
    })
  }
  // componentWillReceiveProps(nextProps) {
  //   console.log(nextProps)
  // }
  // shouldComponentUpdate(nextProps, nextState) {
  //   console.log(nextProps)
  //   console.log(nextState)
  //   if (nextState.isLogin == this.state.isLogin) {
  //     return false
  //   }
  //   return true
  // }
  render () {
    const {display, tmpavatar, timpnickname, getCodeTxt, codeLiked, count, menuShow, collegesUrl} = this.state
    return (
      <div>
      <div className={styles.header}>
        <div className={styles.wrap}>
          <a href="/" className={styles.logo}><img src={require('../../assets/images/logo.png')} alt=""/></a>
          <div className={styles['nav-login']}>
            <div className={styles.login}>
              {/* <a className={styles.toload} href="/">下载APP</a> */}
              {
                timpnickname ? 
                <div className={styles.name}><span onClick={this.toPersonal.bind(this)}>{timpnickname}</span> | <span onClick={this.toLoginOut.bind(this)}>退出</span></div> : 
                <div className={styles['to-login']} onClick={this.popShow}>
                  <span>登录 / 注册</span>
                </div>
              }
              {
                timpnickname ? 
                <img className={styles['login-head']} onClick={this.toPersonal.bind(this)} src={tmpavatar ? tmpavatar : 'http://imagecdn.yinyuewujie.com/lbm-default-avatar.png'} alt="head"/> :
                <img className={styles['login-head']} src='http://imagecdn.yinyuewujie.com/lbm-default-avatar.png' alt="head"/>
              }
            </div>
            <div className={styles.nav}>
              <NavLink exact activeClassName={styles.on} to="/">首页</NavLink>
              <NavLink activeClassName={styles.on} to="/teacher">音乐导师</NavLink>
              <NavLink activeClassName={styles.on} to="/planning">评估规划</NavLink>
              <NavLink activeClassName={styles.on} to="/course">专业课程</NavLink>
              <NavLink activeClassName={styles.on} to="/service">留学申请</NavLink>
              <div className={styles['dropdown-menu']} onMouseEnter={this.handleMenuShow.bind(this)} onMouseLeave={this.handleMenuHide.bind(this)}>
              <NavLink activeClassName={styles.on} to={`/${collegesUrl}`}>专业/院校</NavLink>
              <div className={styles.dropdown} style={{display: menuShow ? 'block' : 'none'}}>
                <Link to="/colleges/majors">音乐专业</Link>
                <Link to="/colleges/shcool">音乐院校</Link>
              </div>
              </div>
              <NavLink activeClassName={styles.on} to="/offer">学员offer</NavLink>
              <NavLink activeClassName={styles.on} to="/activity">活动赛事</NavLink>
              <NavLink activeClassName={styles.on} to="/news/list">音乐资讯</NavLink>
            </div>
          </div>
        </div>
      </div>
      {/* 登录弹层 */}
      <div className={styles['pop-bg']} style={{display:display}}>
        <div className={styles.pop}>
          <div className={styles.name}><img src={require('../../assets/images/logo-login.png')} alt="logo"/></div>
          <i className={styles.close} onClick={this.popHide}></i>
          <div className={styles.login}>
            <div className={styles.item}><input className={styles['tel-inp']} ref='tel' type="text" placeholder="请输入您的手机号"/></div>
            <div className={styles.item}>
              <input className={styles['code-inp']} ref='code' type="text" placeholder="请输入验证码"/>
              {
                codeLiked ?
                <span onClick={this.smsSend}>{getCodeTxt}</span> : 
                <span>{count + 's'}</span>
              }
              
            </div>
          </div>
          <span className={styles.btn} onClick={this.toLogin}>登录</span>
          <p className={styles.loginTips}>*未注册过的手机号将自动创建LBM账号</p>
          {/* <div className={styles['other-ways']}>
            <div className={styles.tit}>第三方登录</div>
            <ul className={styles.ways}>
              <li><img src={require('../../assets/images/qq.png')} alt=""/></li>
              <li><img src={require('../../assets/images/weixin.png')} alt=""/></li>
              <li><img src={require('../../assets/images/weibo.png')} alt=""/></li>
            </ul>
          </div> */}
        </div>
      </div>
      </div>
    )
  }
}

// mapStateToProps
// const mapStateToProps =(state)=> {
//   return {
//       user: state.userData
//   }
// }
// // mapDispatchToProps
// const mapDispatchToProps =(dispatch)=> {
//   return {
//       getUser: ()=>{
//         dispatch(getUserData())
//       }
//   }
// }

export default withRouter(Header)