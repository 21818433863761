import React, {Component} from 'react'
import styles from '../Personal/Personal.css'
import ReactPlayer from 'react-player'

class PersonalClassroom extends Component {
  constructor(props){
    super(props)
    this.state = {
      classList: [],
      feedbackArr: [],
      feedbackShow: false,
      feedbackIndex: 1,
      progressid: 0,
      confirmstatus: null,
      videoPlayerShow: false,
      videourl: ''
    }
  }
  componentDidMount(){
    // 最近学习
    fetch(global.constants.api_url + '/v2/progress/history', {
      method: 'GET',
      headers: {
        'Accept':'application/json',
        'token': this.operCookie().get('token')
      },
      
    })
    .then( response => {
      response.json().then( data => {
        console.log(data.data)
        this.setState({
          classList: data.data
        })
      })
    })
    .catch( error => {
      console.log(error)
    })
  }
  handleFeedback(e){
    let str = e.currentTarget.getAttribute('data-feedback'),
    progressid = e.currentTarget.getAttribute('data-progressid'),
    confirmstatus = e.currentTarget.getAttribute('data-confirmstatus')
    let obj = JSON.parse(str)
    this.setState({
      feedbackArr: obj,
      feedbackShow: true,
      feedbackIndex: 1,
      progressid,
      confirmstatus
    })
  }
  handleCloseFeedback(){
    this.setState({
      feedbackShow: false
    })
  }
  handleTab(e){
    let index = e.currentTarget.getAttribute('data-index')
    this.setState({
      feedbackIndex: Number(index)
    })
  }
  playerHandle(e){
    let hls = e.target.getAttribute('data-replay')
      this.setState({
        videourl: hls,
        videoPlayerShow: true
      })
  }
  closePlayerHandle(){
    console.log('hhh')
    this.setState({
      videoPlayerShow: false,
      videourl: ''
    })
  }
  stopHandle(e){
    e.stopPropagation()
  }
  handleConfirm(e){
    let id = e.currentTarget.getAttribute('data-progressid')
    let that = this
    // 最近学习
    fetch(global.constants.api_url + '/v2/progress/confirm', {
      method: 'POST',
      headers: {
        'Accept':'application/json',
        'token': this.operCookie().get('token')
      },
      body: JSON.stringify({
        progress_id: id
      })
    })
    .then( response => {
      response.json().then( data => {
        if(data.code === 0){
          that.setState({
            feedbackShow: false
          })
        }
        
      })
    })
    .catch( error => {
      console.log(error)
    })
  }
  operCookie(){
    var cookie = {
      set:function(key,val,time){//设置cookie方法
          var date=new Date(); //获取当前时间
          var expiresDays=time;  //将date设置为n天以后的时间
          date.setTime(date.getTime()+expiresDays*24*3600*1000); //格式化为cookie识别的时间
          document.cookie=key + "=" + val +";expires="+date.toGMTString();  //设置cookie
      },
      get:function(key){//获取cookie方法
          /*获取cookie参数*/
          var getCookie = document.cookie.replace(/[ ]/g,"");  //获取cookie，并且将获得的cookie格式化，去掉空格字符
          var arrCookie = getCookie.split(";")  //将获得的cookie以"分号"为标识 将cookie保存到arrCookie的数组中
          var tips;  //声明变量tips
          for(var i=0;i<arrCookie.length;i++){   //使用for循环查找cookie中的tips变量
              var arr=arrCookie[i].split("=");   //将单条cookie用"等号"为标识，将单条cookie保存为arr数组
              if(key===arr[0]){  //匹配变量名称，其中arr[0]是指的cookie名称，如果该条变量为tips则执行判断语句中的赋值操作
                  tips=arr[1];   //将cookie的值赋给变量tips
                  break;   //终止for循环遍历
              }
          }
        
          return tips;
      },
      delete:function(key){ //删除cookie方法
        var date = new Date(); //获取当前时间
        date.setTime(date.getTime()-10000); //将date设置为过去的时间
        document.cookie = key + "=v; expires =" +date.toGMTString();//设置cookie
       }
    }
    return cookie
  }
  render(){
    const { classList, feedbackArr, feedbackShow, feedbackIndex, progressid, confirmstatus, videoPlayerShow, videourl } = this.state
    return(
      <div>
        <div className={`${styles.block1} marB20`}>
          <div className={styles.name}>
            <div className={styles.title}>设备检测</div>
            <span>为了您能获得更好的上课效果，请您开始检测电脑和网络。</span>
          </div>
          <span className={styles.button1}>立即检测</span>
        </div>
        <div className={styles.classtitle}>已上课程</div>
        <div>
        <ul className={`${styles['per-list']} ${styles['per-clsslist']}`}>
          {
            classList.map((item, index) => {
              return (
                <li key={index}>
                <div className={styles.pic}>
                  <img src={require('../../assets/images/ydy.png')} alt=""/>
                  <span>{item.type_text}</span>
                </div>
                <div className={styles.intr}>
                  <div className={styles.name}>课程: {item.plan_text}</div>
                  <div className={styles.name}>老师: {item.teacher_text}</div>
                  <div className={styles.info}>时间: {item.datetime}</div>
                </div>
                <div className={styles.btns}>
                <span className={styles.button1}
                style={{display: item.replay ? 'block' : 'none'}}
                data-replay={item.replay}
                onClick={this.playerHandle.bind(this)}
                >课程回放</span>
                <span 
                className={styles.button2} 
                style={{display: item.feedback.length > 0 ? 'block' : 'none'}}
                data-feedback={JSON.stringify(item.feedback)}
                data-progressid={item.progress_id}
                data-confirmstatus={item.confirm_status}
                onClick={this.handleFeedback.bind(this)}
                >查看反馈</span>
                </div>
                {/* <span className={styles.button2}>课程回顾</span>
                <i className={styles.icon1}></i> */}
              </li>
              )
            })
          }
        </ul>
        </div>
        {/* 弹层 */}
        <div className={styles.popbg} style={{display: feedbackShow ? 'block' : 'none'}}>
        <div className={styles.pop}>
          <span className={styles.close} onClick={this.handleCloseFeedback.bind(this)}></span>
          <div className={styles.tabtitle}>
            {
              feedbackArr.map((item, index)=>{
                return <span 
                key={index} 
                className={`${feedbackIndex === index+1 ? styles.on : ''}`}
                data-index={index+1}
                onClick={this.handleTab.bind(this)}
                >{item.type_name}</span>
              })
            }
          </div>
          <p className={styles.yd}>请阅读授课老师给与的课程反馈。点击下方按钮，表示您已经阅读并确认本节课程完成。</p>
          {
            feedbackArr.map((item, index)=>{
              return (
                <div className={styles.content} key={index} style={{display: feedbackIndex === index+1 ? 'block' : 'none'}}>
                  <div className={styles.item}>
                    <div className={styles.name}>标题：</div>
                    <div className={styles.tit}>{item.title}</div>
                  </div>
                  <div className={styles.item}>
                    <div className={styles.name}>授课内容：</div>
                    <div className={styles.info}>{item.content}</div>
                  </div>
                  <div className={styles.item}>
                    <div className={styles.name}>完成情况：</div>
                    <div className={styles.info}>{item.completion}</div>
                  </div>
                  <div className={styles.item}>
                    <div className={styles.name}>问题：</div>
                    <div className={styles.info}>{item.question}</div>
                  </div>
                  <div className={styles.item}>
                    <div className={styles.name}>作业：</div>
                    <div className={styles.info}>{item.remark}</div>
                  </div>
                  <div className={styles.item}>
                    <div className={styles.name}>创建人：</div>
                    <div className={styles.info}>{item.teacherText}</div>
                  </div>
                </div>
              )
            })
          }
          <div className={styles.btnsub} style={{display: confirmstatus === 1 ? 'block' : 'none'}} data-progressid={progressid} onClick={this.handleConfirm.bind(this)}>确认完成课程</div>
        </div>
        </div>
        {/* 直播弹层 */}
        <div className={styles.playerBg} hidden={!videoPlayerShow} onClick={this.closePlayerHandle.bind(this)}>
          <div className={styles.playerBox} onClick={this.stopHandle.bind(this)}>
            <span className={styles.close} onClick={this.closePlayerHandle.bind(this)}></span>
            <ReactPlayer url={videourl} playing controls />
          </div>
        </div>
      </div>
    )
  }
}
export default PersonalClassroom