import React, { Component } from 'react'
import './Layer.css'

class Layer extends Component {
  constructor(props){
    super(props)
    this.state = {
      layerShow: false,
      majorList:[],
      major:'申请专业',
      majorShow: false,
      collegesList:[],
      colleges:'意向院校',
      collegesShow: false,
      realname:'',
      mobile:'',
      major_id:'',
      school_id:'',
      tipsMessage:'',
      tipsShow:false
    }
    this.majorHandle = this.majorHandle.bind(this)
  }
  componentDidMount(){
    let layer = this.refs.layer
    layer.addEventListener('click',() => {
      this.setState({
        majorShow: false,
        collegesShow: false
      })
    })
  }
  closeLayer(){
    this.setState({
      layerShow: false,
      major:'申请专业',
      colleges:'意向院校',
      collegesShow: false,
      majorShow: false
    })
  }
  handleNameChange(e){
    this.setState({
      realname: e.target.value
    })
  }
  handleTelChange(e){
    this.setState({
      mobile: e.target.value
    })
  }
  majorHandle(){
    fetch(global.constants.api_url + '/v1/major/selectlist',{
      method:'GET'
    })
    .then((response) => {
      response.json().then((data) => {
        this.setState({
          majorShow:true,
          majorList:data.data
        })
        
      })
    })
    .catch((error) => {
      console.log(error)
    })
  }
  majorItem(e){
    e.nativeEvent.stopImmediatePropagation()
    let id = e.target.getAttribute('data-id'),
        name = e.target.getAttribute('data-name')
    this.setState({
      major: name,
      majorShow: false,
      major_id: id
    })
  }
  collegesHandle(){
    fetch(global.constants.api_url + '/v1/school/selectlist', {
      method: 'GET'
    })
    .then((response) => {
      response.json().then((data) => {
        this.setState({
          collegesShow: true,
          collegesList: data.data
        })
      })
    })
    .catch((error) => {
      console.log(error)
    })
  }
  collegesItem(e){
    e.nativeEvent.stopImmediatePropagation()
    let name = e.target.getAttribute('data-name'),
        id = e.target.getAttribute('data-id')
    this.setState({
      colleges: name,
      collegesShow: false,
      school_id: id
    })
  }
  assessHandle(){
    const { realname, mobile, major_id, school_id } = this.state
    let data = {
      'realname': realname,
      'mobile': mobile,
      'major_id': major_id,
      'school_id': school_id
    }
    fetch(global.constants.api_url + '/v1/assess/apply', {
      method: 'POST',
      headers: {
        'token': this.operCookie().get('token')
      },
      body: JSON.stringify(data)
    })
    .then((response) => {
      response.json().then((data) => {
        if(data.code === 0){
          this.setState({
            layerShow: false
          })
        }
        this.setState({
          tipsShow: true,
          tipsMessage: data.message
        })
        setTimeout(()=>{
          this.setState({
            tipsShow: false
          })
        },1000)
        
      })
    })
    .catch((error) => {
      console.log(error)
    })
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.show !== this.state.layerShow) {
      this.setState({
        layerShow: true
      })
    }
  }
  operCookie(){
    var cookie = {
      set:function(key,val,time){//设置cookie方法
          var date=new Date(); //获取当前时间
          var expiresDays=time;  //将date设置为n天以后的时间
          date.setTime(date.getTime()+expiresDays*24*3600*1000); //格式化为cookie识别的时间
          document.cookie=key + "=" + val +";expires="+date.toGMTString();  //设置cookie
      },
      get:function(key){//获取cookie方法
          /*获取cookie参数*/
          var getCookie = document.cookie.replace(/[ ]/g,"");  //获取cookie，并且将获得的cookie格式化，去掉空格字符
          var arrCookie = getCookie.split(";")  //将获得的cookie以"分号"为标识 将cookie保存到arrCookie的数组中
          var tips;  //声明变量tips
          for(var i=0;i<arrCookie.length;i++){   //使用for循环查找cookie中的tips变量
              var arr=arrCookie[i].split("=");   //将单条cookie用"等号"为标识，将单条cookie保存为arr数组
              if(key===arr[0]){  //匹配变量名称，其中arr[0]是指的cookie名称，如果该条变量为tips则执行判断语句中的赋值操作
                  tips=arr[1];   //将cookie的值赋给变量tips
                  break;   //终止for循环遍历
              }
          }
        
          return tips;
      },
      delete:function(key){ //删除cookie方法
        var date = new Date(); //获取当前时间
        date.setTime(date.getTime()-10000); //将date设置为过去的时间
        document.cookie = key + "=v; expires =" +date.toGMTString();//设置cookie
       }
    }
    return cookie
  }
  // shouldComponentUpdate(nextProps, nextState) {
  //   console.log(nextProps)
  //   console.log(nextState)
  //   if (nextState.show === 'true') {
  //     this.setState({
  //       layerShow: true
  //     })
  //   }
  //   return true
  // }
  render(){
    const { layerShow, majorList, majorShow, major, collegesList, collegesShow, colleges, tipsMessage, tipsShow } = this.state
    return(
      <div>
        <div className="layer" ref="layer" style={{'display': layerShow === true ? 'block' : 'none'}}>
          <div className="title"><span>提交个人信息</span></div>
          <i className="close" onClick={this.closeLayer.bind(this)}></i>
          <div className="content">
            <div className="item"><span className="name">姓名：</span><input onChange={this.handleNameChange.bind(this)} type="text" placeholder="请输入您的名字"/></div>
            <div className="item"><span className="name">电话：</span><input onChange={this.handleTelChange.bind(this)} type="text" placeholder="请输入您的电话"/></div>
            <div className="item">
              <span className="name">意向院校：</span>
              <div className="drop-down">
                <span onClick={this.collegesHandle.bind(this)}>{colleges}</span>
                <ul className='list'  style={{'display': collegesShow ? 'block' : 'none'}}>
                  {
                    collegesList.map((item, index) => {
                      return <li onClick={this.collegesItem.bind(this)} key={index} data-id={item.id} data-name={item.name}>{item.name}</li>
                    })
                  }
                </ul>
              </div>
            </div>
            <div className="item">
              <span className="name">申请专业：</span>
              <div className="drop-down">
                <span onClick={this.majorHandle}>{major}</span>
                <ul className='list' style={{'display': majorShow ? 'block' : 'none'}}>
                  {
                    majorList.map((item, index) => {
                      return <li onClick={this.majorItem.bind(this)} key={index} data-id={item.id} data-name={item.name}>{item.name}</li>
                    })
                  }
                </ul>
              </div>
            </div>
          </div>
          <div className="bottom">
            <span className="button" onClick={this.assessHandle.bind(this)}>提交</span>
          </div>
        </div>
        <div className="layer-tips" style={{'display': tipsShow === true ? 'block' : 'none'}}>{tipsMessage}</div>
      </div>
    )
  }
}
export default Layer