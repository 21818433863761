import React, { Component } from 'react'
import styles from './Personal.css'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import MyMenu from '../../components/MyMenu/MyMenu'
import PersonalMy from '../PersonalMy/PersonalMy'
import PersonalNews from '../PersonalNews/PersonalNews'
import PersonalSet from '../PersonalSet/PersonalSet'
import PersonalClassroom from '../PersonalClassroom/PersonalClassroom'
import PersonalTimetable from '../PersonalTimetable/PersonalTimetable'
import '../../config'
import { connect } from 'react-redux';

class Personal extends Component {
  constructor(props){
    super(props)
    this.state = {
      tmpavatar:'https://image-smteat.oss-cn-beijing.aliyuncs.com/static/pc/images/login-head.png',
      timpnickname:'未设置昵称',
      major:'',
      gender:1
    }
  }
  componentDidMount(){
    window.scrollTo(0, 0)
    fetch(global.constants.api_url + '/v1/user/profile', {
      method: 'GET',
      headers: {
        'Accept':'application/json',
        'token': this.operCookie().get('token')
      }
    })
    .then(response => {
      response.json().then(data => {
        if(data.code === 1000){
          console.log(data.message)
        }else if(data.code === 0){
          this.setState({
            tmpavatar: data.data.avatar,
            timpnickname: data.data.nickname,
            major: data.data.major,
            gender: data.data.gender
          })
        }
        
      })
    })
    .catch(error => {
      console.log(error)
    })
  }
  operCookie(){
    // var storage = window.localStorage
    // storage.setItem('aaaaaaaaaa', 3);
    // var a = storage.getItem('aaaaaaaaaa');
    // console.log(a)
    // storage.removeItem('');
    var cookie = {
      set:function(key,val,time){//设置cookie方法
          var date=new Date(); //获取当前时间
          var expiresDays=time;  //将date设置为n天以后的时间
          date.setTime(date.getTime()+expiresDays*24*3600*1000); //格式化为cookie识别的时间
          document.cookie=key + "=" + val +";expires="+date.toGMTString();  //设置cookie
      },
      get:function(key){//获取cookie方法
          /*获取cookie参数*/
          var getCookie = document.cookie.replace(/[ ]/g,"");  //获取cookie，并且将获得的cookie格式化，去掉空格字符
          var arrCookie = getCookie.split(";")  //将获得的cookie以"分号"为标识 将cookie保存到arrCookie的数组中
          var tips;  //声明变量tips
          for(var i=0;i<arrCookie.length;i++){   //使用for循环查找cookie中的tips变量
              var arr=arrCookie[i].split("=");   //将单条cookie用"等号"为标识，将单条cookie保存为arr数组
              if(key===arr[0]){  //匹配变量名称，其中arr[0]是指的cookie名称，如果该条变量为tips则执行判断语句中的赋值操作
                  tips=arr[1];   //将cookie的值赋给变量tips
                  break;   //终止for循环遍历
              }
          }
        
          return tips;
      },
      delete:function(key){ //删除cookie方法
        var date = new Date(); //获取当前时间
        date.setTime(date.getTime()-10000); //将date设置为过去的时间
        document.cookie = key + "=v; expires =" +date.toGMTString();//设置cookie
       }
    }
    return cookie
  }
  handleAdd(){
    this.props.onClick()
  }
  render(){
    const {tmpavatar, timpnickname, major, gender} = this.state
    return (
      <div>
        {/* 头部 */}
        <Header />
        <div className={styles['wrap-bg']}>
          {/* 名片 */}
          <div className={styles['visiting-card']}>
            <div className={styles.card}>
              <img src={tmpavatar ? tmpavatar : 'http://imagecdn.yinyuewujie.com/lbm-default-avatar.png'} alt=""/>
              <div className={styles.info}>
                <div className={styles.name}>{timpnickname}</div>
                <div className={styles.sub}><span>{major}</span><span>{gender === 1 ? '男' : '女'}</span></div>
              </div>
            </div>
          </div>
          <div className={styles['wrap-over']}>
            {/* 内容 */}
            <div className={styles['left-area']}>
              <MyMenu />
            </div>
            <div className={styles['right-area']}>
              {/* 我的主页 */}
              <div style={{'display':this.props.tab === 'home' ? 'block' : 'none'}}>
                <PersonalMy />
              </div>
              {/* 我的课堂 */}
              <div style={{'display':this.props.tab === 'course' ? 'block' : 'none'}}>
                <PersonalClassroom />
              </div>
              {/* 我的课表 */}
              <div style={{'display':this.props.tab === 'timetable' ? 'block' : 'none'}}>
                <PersonalTimetable />
              </div>
              {/* 消息 */}
              <div style={{'display':this.props.tab === 'message' ? 'block' : 'none'}}>
                <PersonalNews />
              </div>
              {/* 个人设置 */}
              <div style={{'display':this.props.tab === 'setup' ? 'block' : 'none'}}>
              <PersonalSet />
              </div>
              
            </div>
          </div>
        </div>
        {/* 底部 */}
        <Footer />
      </div>
    )
  }
}

// const mapStateToProps = state => {
//   return ({
//     data: state.counter
//   })
// }
// const mapDispatchToProps = dispatch => ({
//   onClick: id => dispatch({
//     type: 'INCREMENT_COUNTER'
//   }),
//   onDel: item => dispatch({
//     type: 'LIST_DEL',
//     payload: [item]
//   })
// })
const mapState = state => {
  return ({
    tab: state.personalTab
  })
}
const mapDispatch = dispatch => ({
  handlePersonal: item => dispatch({
    type: 'PERSONAL_CHANGE',
    payload: item
  })
})
export default connect(mapState, mapDispatch)(Personal)