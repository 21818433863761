import React, { Component } from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import styles from './List.css'
import '../../config'

class List extends Component {
    constructor(props){
        super(props)
        this.state = {
            categoryArr: [],
            list: [],
            type: 1,
            page: 1,
            teacherIsShow: false,
            tea_head: '',
            tea_en_name: '',
            tea_name: '',
            tea_title: '',
            tea_school: '',
            tea_intro: ''
        }
    }
    componentDidMount(){
        // 分类
        fetch(global.constants.api_url + '/lbm/jury/majortypelist', {
            method: 'GET'
        })
        .then( response => {
            response.json().then( data => {
            this.setState({
                categoryArr: data.data
            })
            })
        })
        .catch( error => {
            console.log(error)
        })
        // 列表
        this.getList(this.state.type, this.state.page)
        
    }
    handleType(e){
        let id = e.target.getAttribute('data-id')
        this.getList(id, this.state.page)
        this.setState({
            type: Number(id)
        })
    }
    handleTeacher(e){
    let tea_head = e.currentTarget.getAttribute('data-head'),
        tea_en_name = e.currentTarget.getAttribute('data-enname'),
        tea_name = e.currentTarget.getAttribute('data-name'),
        tea_title = e.currentTarget.getAttribute('data-title'),
        tea_school = e.currentTarget.getAttribute('data-school'),
        tea_intro = e.currentTarget.getAttribute('data-intro')
    
    this.setState({
        tea_head,
        tea_en_name,
        tea_name,
        tea_title,
        tea_school,
        tea_intro,
        teacherIsShow: true
    })
    }
    closeTeacher(e){
    this.setState({
        teacherIsShow: false
    })
    }
    getList(type, page){
        // 列表
        fetch(global.constants.api_url + '/v1/jury/getlist?page_size=60&major_type='+type+'&page='+page, {
            method: 'GET'
        })
        .then( response => {
            response.json().then( data => {
            console.log(data.data)
            this.setState({
                list: data.data.data
            })
            })
        })
        .catch( error => {
            console.log(error)
        })
    }
    render(){
        const { categoryArr, list, type, teacherIsShow, tea_head, tea_en_name, tea_name, tea_title, tea_school, tea_intro } = this.state
        return (
            <div className={styles.bg}>
                {/* 头部 */}
                <Header />
                <div className='wrap'>
                    <div className={styles.position}>首页 > <span>更多名师</span></div>
                    <div className={styles.topmenu}>
                        {
                            categoryArr.map((item, index)=>{
                                return <span className={type === item.id ? styles.on : ''} onClick={this.handleType.bind(this)} key={index} data-id={item.id}>{item.name}</span>
                            })
                        }
                    </div>
                    {/* 列表 */}
                    <div className={styles.list}>
                        {
                            list.map((item, index)=>{
                                return (
                                    <div className={styles.item} key={index}>
                                        <div className={styles.pic} data-head={item.avatar} data-enname={item.en_name} data-name={item.name} data-title={item.title} data-school={item.school} data-intro={item.intro} onClick={this.handleTeacher.bind(this)}>
                                            <img src={item.avatar} width='180' height='185' alt='img'/>
                                        </div>
                                        <div className={styles.info}>
                                            <span>{item.en_name}</span>
                                            <p>{item.name}</p>
                                            <span>{item.title}</span>
                                            <p>{item.school}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        
                        
                    </div>
                </div>
                {/* 底部 */}
                <Footer />
                {/* 导师简介弹层 */}
                <div className={styles.popbg} hidden={!teacherIsShow}>
                <div className='teacherInfo'>
                    <div className='left'>
                    <img src={tea_head} alt='head'/>
                    <div className='teainfo'>
                        <span>{tea_en_name}</span>
                        <p>{tea_name}</p>
                        <span>{tea_title}</span>
                        <p>{tea_school}</p>
                    </div>
                    </div>
                    <div className='right'>
                    <div className='jjname'>导师简介</div>
                    <div className='jjcon' dangerouslySetInnerHTML={{ __html: tea_intro }}></div>
                    </div>
                    <div className='tBtns'>
                    <div className='btn1'>预约大师课</div>
                    <div className='btn2'>请TA评估</div>
                    </div>
                    <div className='pop-close' onClick={this.closeTeacher.bind(this)}></div>
                </div>
                </div>
            </div>
        )
    }
}
export default List