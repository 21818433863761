import React, { Component } from 'react'
import styles from './footer.css'
import '../../config'
import { Link } from 'react-router-dom'

class Footer extends Component {
  constructor(props){
    super(props)
    this.state = {
      majorList:[],
      major:'申请专业',
      majorShow: false,
      collegesList:[],
      colleges:'申请学校',
      collegesShow: false,
      realname:'',
      mobile:'',
      major_id:'',
      school_id:'',
      tipsMessage:'',
      tipsShow:false,
      isCompanyShow: '1',
      isfMenuShow: '1',
      assessShow: false
    }
    this.majorHandle = this.majorHandle.bind(this)
  }
  componentDidMount(){
    // 在 document 上绑定点击事件，隐藏弹出层
    document.addEventListener('click', (e) => {
        this.setState({
          majorShow: false,
          collegesShow: false
        });
    });
  }
  majorHandle(e){
    e.nativeEvent.stopImmediatePropagation()
    fetch(global.constants.api_url + '/v1/major/selectlist',{
      method:'GET'
    })
    .then((response) => {
      response.json().then((data) => {
        this.setState({
          majorShow:true,
          majorList:data.data
        })
        
      })
    })
    .catch((error) => {
      console.log(error)
    })
  }
  majorItem(e){
    let id = e.target.getAttribute('data-id'),
        name = e.target.getAttribute('data-name')
    e.nativeEvent.stopImmediatePropagation()
    this.setState({
      major: name,
      majorShow: false,
      major_id: id
    })
  }
  collegesHandle(e){
    e.nativeEvent.stopImmediatePropagation()
    fetch(global.constants.api_url + '/v1/school/selectlist', {
      method: 'GET'
    })
    .then((response) => {
      response.json().then((data) => {
        this.setState({
          collegesShow: true,
          collegesList: data.data
        })
      })
    })
    .catch((error) => {
      console.log(error)
    })
  }
  collegesItem(e){
    let name = e.target.getAttribute('data-name'),
        id = e.target.getAttribute('data-id')
    e.nativeEvent.stopImmediatePropagation()
    this.setState({
      colleges: name,
      collegesShow: false,
      school_id: id
    })
  }
  assessHandle(){
    const { realname, mobile, major_id, school_id } = this.state
    let data = {
      'realname': realname,
      'mobile': mobile,
      'major_id': major_id,
      'school_id': school_id
    }
    fetch(global.constants.api_url + '/v1/assess/apply', {
      method: 'POST',
      headers: {
        'token': this.operCookie().get('token')
      },
      body: JSON.stringify(data)
    })
    .then((response) => {
      response.json().then((data) => {
        if(data.code === 0){
          this.setState({
            layerShow: false
          })
        }
        this.setState({
          tipsShow: true,
          tipsMessage: data.message
        })
        setTimeout(()=>{
          this.setState({
            tipsShow: false
          })
        },1000)
      })
    })
    .catch((error) => {
      console.log(error)
    })
  }
  handleNameChange(e){
    this.setState({
      realname: e.target.value
    })
  }
  handleTelChange(e){
    this.setState({
      mobile: e.target.value
    })
  }
  handleCompanyEnter(e){
    let company = e.currentTarget.getAttribute('data-company')
    this.setState({
      isCompanyShow: company
    })
  }
  handleFootMenu(e){
    let type = e.currentTarget.getAttribute('data-type')
    this.setState({
      isfMenuShow: type
    })
  }
  closeAssess(){
    this.setState({
      assessShow: true
    })
  }
  operCookie(){
    // var storage = window.localStorage
    // storage.setItem('aaaaaaaaaa', 3);
    // var a = storage.getItem('aaaaaaaaaa');
    // console.log(a)
    // storage.removeItem('');
    var cookie = {
      set:function(key,val,time){//设置cookie方法
          var date=new Date(); //获取当前时间
          var expiresDays=time;  //将date设置为n天以后的时间
          date.setTime(date.getTime()+expiresDays*24*3600*1000); //格式化为cookie识别的时间
          document.cookie=key + "=" + val +";expires="+date.toGMTString();  //设置cookie
      },
      get:function(key){//获取cookie方法
          /*获取cookie参数*/
          var getCookie = document.cookie.replace(/[ ]/g,"");  //获取cookie，并且将获得的cookie格式化，去掉空格字符
          var arrCookie = getCookie.split(";")  //将获得的cookie以"分号"为标识 将cookie保存到arrCookie的数组中
          var tips;  //声明变量tips
          for(var i=0;i<arrCookie.length;i++){   //使用for循环查找cookie中的tips变量
              var arr=arrCookie[i].split("=");   //将单条cookie用"等号"为标识，将单条cookie保存为arr数组
              if(key===arr[0]){  //匹配变量名称，其中arr[0]是指的cookie名称，如果该条变量为tips则执行判断语句中的赋值操作
                  tips=arr[1];   //将cookie的值赋给变量tips
                  break;   //终止for循环遍历
              }
          }
        
          return tips;
      },
      delete:function(key){ //删除cookie方法
        var date = new Date(); //获取当前时间
        date.setTime(date.getTime()-10000); //将date设置为过去的时间
        document.cookie = key + "=v; expires =" +date.toGMTString();//设置cookie
       }
    }
    return cookie
  }
  render () {
    const { majorList, majorShow, major, collegesList, collegesShow, colleges, tipsMessage, tipsShow, isCompanyShow, isfMenuShow, assessShow } = this.state
    return (
      <div>
      {/* 评估 */}
      <div className={styles.assess} hidden={assessShow}>
        <div className='wrap'>
          <img className={styles.lu} src={require('../../assets/images/lu.png')} alt=""/>
          <div className={styles['input-box']}>
            <input type="text" onChange={this.handleNameChange.bind(this)} placeholder='姓名：某某某'/>
            <input className={styles['long-inp']} type="text" onChange={this.handleTelChange.bind(this)} placeholder="电话：1356XXXXXXX"/>
            <div className={styles['drop-down']}>
              <span onClick={this.majorHandle}><em>{major}</em><i></i></span>
              <ul className={styles.list} style={{'display': majorShow ? 'block' : 'none'}}>
                {
                  majorList.map((item, index) => {
                    return <li onClick={this.majorItem.bind(this)} key={index} data-id={item.id} data-name={item.name}>{item.name}</li>
                  })
                }
              </ul>
            </div>
            <div className={styles['drop-down']}>
              <span onClick={this.collegesHandle.bind(this)}><em>{colleges}</em><i></i></span>
              <ul className={styles.list} style={{'display': collegesShow ? 'block' : 'none'}}>
                {
                  collegesList.map((item, index) => {
                    return <li onClick={this.collegesItem.bind(this)} key={index} data-id={item.id} data-name={item.name}>{item.name}</li>
                  })
                }
              </ul>
            </div>
            <span className={styles.button} onClick={this.assessHandle.bind(this)}>立即评估</span>
          </div>
          <div className={styles.close} onClick={this.closeAssess.bind(this)}></div>
        </div>
      </div>
      <div className="layer-tips" style={{'display': tipsShow === true ? 'block' : 'none'}}>{tipsMessage}</div>
      {/* 底部 */}
      <div className={styles[`footer`]}>
        <div className={styles['foot-info']}>
          <div className={styles['footer-logo']}>
            <img src={require('../../assets/images/footer-logo.png')} alt='img'/>
          </div>
          <div className={styles.intr}>
            <div className={styles['foot-title']}>
              <div 
              className={`${styles.tit} ${isfMenuShow === '1' ? styles.on : ''}`}
              onMouseEnter={this.handleFootMenu.bind(this)}
              data-type='1'
              >
                <p>联系我们</p>
                <span>Contact us</span>
              </div>
              |
              <div 
              className={`${styles.tit} ${isfMenuShow === '2' ? styles.on : ''}`}
              onMouseEnter={this.handleFootMenu.bind(this)}
              data-type='2'
              >
                <p>友情链接</p>
                <span>Friendship link</span>
              </div>
              |
              <div className={styles.tit}>
                <p><Link to="/join">加入我们</Link></p>
                <span>Join us</span>
              </div>
              |
              <div className={styles.tit}>
                <p><Link to="/about">关于我们</Link></p>
                <span>About us</span>
              </div>
            </div>
            <div className={styles['company-info']} style={{display: isfMenuShow === '1' ? 'block' : 'none'}}>
              <div className={styles['company-name']}>
                <span className={isCompanyShow === '1' ? styles.on : ''} 
                data-company='1'
                onMouseEnter={this.handleCompanyEnter.bind(this)}
                >中国公司</span>
                <div className={styles.line}></div>
                <span  className={isCompanyShow === '2' ? styles.on : ''}
                data-company='2'
                onMouseEnter={this.handleCompanyEnter.bind(this)}
                >美国公司</span>
              </div>
              <div className={styles['company-one']} style={{display: isCompanyShow === '1' ? 'block' : 'none'}}>
                <p className={styles['company-item']}>办公电话：010-57026290 / 13681053579</p>
                <p className={styles['company-item']}>电子邮箱：info@lbmedu.com</p>
                <p className={styles['company-item']}>公司地址：北京市朝阳区大成国际中心A2座16A09室</p>
              </div>
              <div className={styles['company-one']} style={{display: isCompanyShow === '2' ? 'block' : 'none'}}>
                <p className={styles['company-item']}>联系电话：+1（646）651-7262</p>
                <p className={styles['company-item']}>电子邮箱：moorelee1212@gmail.com</p>
                <p className={styles['company-item']}>公司地址：1230 Amsterdam Avenue Suite 903,New York, New York 10027</p>
              </div>
            </div>
            <div style={{display: isfMenuShow === '2' ? 'block' : 'none'}}>
            <ul className={styles.partner}>
              <li><a href="http://www.ccmusic.edu.cn/" target="_blank" rel="noopener noreferrer" title="中国音乐学院">中国音乐学院</a></li>
              <li><a href="http://www.shcmusic.edu.cn/" target="_blank" rel="noopener noreferrer" title="上海音乐学院">上海音乐学院</a></li>
              <li><a href="http://www.shmusic.org/" target="_blank" rel="noopener noreferrer" title="上海音乐家协会">上海音乐家协会</a></li>
              <li><a href="http://www.ccom.edu.cn/" target="_blank" rel="noopener noreferrer" title="中央音乐学院">中央音乐学院</a></li>
              <li><a href="http://www.xhcom.edu.cn/" target="_blank" rel="noopener noreferrer" title="星海音乐学院">星海音乐学院</a></li>
              <li><a href="http://www.tjcm.edu.cn/" target="_blank" rel="noopener noreferrer" title="天津音乐学院">天津音乐学院</a></li>
              <li><a href="http://www.sccm.cn/" target="_blank" rel="noopener noreferrer" title="四川音乐学院">四川音乐学院</a></li>
              <li><a href="http://www.sycm.com.cn/" target="_blank" rel="noopener noreferrer" title="沈阳音乐学院">沈阳音乐学院</a></li>
              <li><a href="http://www.zjcm.edu.cn/" target="_blank" rel="noopener noreferrer" title="浙江音乐学院">浙江音乐学院</a></li>
              <li><a href="http://www.xacom.edu.cn/" target="_blank" rel="noopener noreferrer" title="西安音乐学院">西安音乐学院</a></li>
              <li><a href="http://www.whcm.edu.cn/" target="_blank" rel="noopener noreferrer" title="武汉音乐学院">武汉音乐学院</a></li>
            </ul>
            </div>
          </div>
          <div className={styles['code-pos']}>
            <div className={styles['qr-code']}>
              <img src={require('../../assets/images/xiaochengxu.png')} alt=""/>
              <p>音乐无界小程序</p>
            </div>
            <div className={styles['qr-code']}>
              <img src={require('../../assets/images/WeChat.png')} alt=""/>
              <p>微信官方公众号</p>
            </div>
          </div>
          
        </div>
        <div className={styles['foot-copyright']}><p><span>© 京ICP备13001741号</span><span><img src={require('../../assets/images/police.png')} alt='police' />京公网安备 11010502037027号</span> 版权所有：利博莫国际教育咨询（北京）有限公司</p></div>
      </div>
      </div>
    )
  }
}
export default Footer
