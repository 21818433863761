import React, { Component } from 'react'
import styles from './Events.css'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'

class Events extends Component {
    render(){
        return(
            <div className={styles.bgf0}>
                {/* 头部 */}
                <Header />
                {/* banner */}
                <div className={styles.banner1}></div>
                <div className={styles.banner2}></div>
                <div className={styles.banner3}></div>
                <div className={styles.banner4}></div>
                <div className={styles.banner5}></div>
                <div className={styles.banner6}></div>
                {/* 简介 */}
                <div className='wrap'>
                    <img src={require('../../assets/images/yyj_09.jpg')} alt='img'/>
                    <img src={require('../../assets/images/yyj_10.jpg')} width='590' alt='img'/>
                    <img src={require('../../assets/images/yyj_12.jpg')} alt='img'/>
                    <img src={require('../../assets/images/yyj_13.jpg')} alt='img'/>
                </div>
                {/* 音乐节 */}
                <div className={`${styles.yyj} ${styles.yyj1}`}></div>
                <div className={`${styles.yyj} ${styles.yyj2}`}></div>
                <div className={`${styles.yyj} ${styles.yyj3}`}></div>
                <div className={`${styles.yyj} ${styles.yyj4}`}></div>
                <div className={`${styles.yyj} ${styles.yyj5}`}></div>
                <div className={`${styles.yyj} ${styles.yyj6}`}></div>
                <div className={`${styles.yyj} ${styles.yyj7}`}></div>
                <div className={`${styles.yyj} ${styles.yyj8}`}></div>
                <div className={`${styles.yyj} ${styles.yyj9}`}></div>
                <div className={`${styles.yyj} ${styles.yyj10}`}></div>

                {/* 底部 */}
                <Footer />
            </div>
        )
    }
}
export default Events