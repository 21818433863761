import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Counter from './page/redux/App'
import Index from './page/Index/Index'
import Colleges from './page/Colleges/Colleges'
import CollegesDetails from './page/Colleges/Details'
import Teacher from './page/Teacher/Teacher'
import TeacherList from './page/Teacher/List'
import Activity from './page/Activity/Activity'
import Competition from './page/Competition/Competition'
import Events from './page/Events/Events'
import Article from './page/Article/Article'
import Details from './page/Details/Details'
import AboutUs from './page/AboutUs/AboutUs'
import JoinUs from './page/JoinUs/JoinUs'
import Personal from './page/Personal/Personal'
import PersonalNews from './page/PersonalNews/PersonalNews'
import PersonalSet from './page/PersonalSet/PersonalSet'
import Service from './page/Service/Service'
import Course from './page/Course/Course'
import Planning from './page/Planning/Planning'
import Offer from './page/Offer/Offer'
import Major from './page/Major/Major'
import List from './page/List/List'
import { Route, Router, Switch } from 'react-router-dom'
// import { HashRouter } from 'react-router-dom'
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import 'babel-polyfill'
import store from './model/store/store'
// import { createStore, applyMiddleware } from 'redux'
// import rootReducer from './model/reducers'
// import thunk from 'redux-thunk'


// HTML5 history, 推荐
import createHistory from 'history/createBrowserHistory'

// 内存 history （如：node环境）
// import createHistory from 'history/lib/createMemoryHistory'

// import createHistory from 'history/createHashHistory'
const history = createHistory()

// const store = createStore(
//   rootReducer,
//   applyMiddleware(thunk)
// )

ReactDOM.render((
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <Route path="/news/article/:id" component={Article}/>
        <Route path="/activity/competition" component={Competition}/>
        <Route path="/activity/events" component={Events}/>
        <Route path="/activity" component={Activity}/>
        <Route path="/news/list" component={List}/>
        <Route path="/colleges/details/:id" component={CollegesDetails}/>
        <Route path="/colleges/majors" component={Major}/>
        <Route path="/details/:id" component={Details}/>
        <Route path="/teacher/list" component={TeacherList}/>
        <Route path="/teacher" component={Teacher}/>
        <Route path="/colleges/shcool" component={Colleges}/>
        <Route path="/about" component={AboutUs}/>
        <Route path="/join" component={JoinUs}/>
        <Route path="/personal" component={Personal}/>
        <Route path="/news" component={PersonalNews}/>
        <Route path="/set" component={PersonalSet}/>
        <Route path="/service" component={Service}/>
        <Route path="/course" component={Course}/>
        <Route path="/planning" component={Planning}/>
        <Route path="/offer" component={Offer}/>
        <Route exact path="/" component={Index}/>
        <Route path="/counter" component={Counter}/>
        <Route path="/app" component={App}/>
      </Switch>
    </Router>
  </Provider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
