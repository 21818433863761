import React, { Component } from 'react'
import styles from './Major.css'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Banner from '../../components/Banner/Banner'

class Major extends Component {
    constructor(props){
        super(props)
        this.state = {
          bannerArr: []
        }
      }
    componentDidMount(){
        // banner
        fetch(global.constants.api_url + '/v1/banner/list?location=8', {
            method: 'GET'
        })
        .then( response => {
            response.json().then( data => {
            this.setState({
                bannerArr: data.data
            })
            })
        })
        .catch( error => {
            console.log(error)
        })
    }
    render(){
        return(
            <div className={styles.bgff}>
                {/* 头部 */}
                <Header />
                {/* banner */}
                <div className='banner'>
                <Banner banner={this.state.bannerArr}/>
                </div>
                <div className={styles.classify}>
                    <div className="wrap">
                        <div className={styles.classlist}>
                        <div className={styles.item}>
                            <p>声乐</p>
                            <span>Vocal Arts</span>
                        </div>
                        |
                        <div className={styles.item}>
                            <p>键盘</p>
                            <span>Keyboard</span>
                        </div>
                        |
                        <div className={styles.item}>
                            <p>弦乐</p>
                            <span>Strings</span>
                        </div>
                        |
                        <div className={styles.item}>
                            <p>木管乐器</p>
                            <span>Woodwinds</span>
                        </div>
                        |
                        <div className={styles.item}>
                            <p>铜管乐器</p>
                            <span>Brass</span>
                        </div>
                        |
                        <div className={styles.item}>
                            <p>作曲</p>
                            <span>Composition</span>
                        </div>
                        |
                        <div className={styles.item}>
                            <p>制作</p>
                            <span>Production</span>
                        </div>
                        |
                        <div className={styles.item}>
                            <p>其他专业</p>
                            <span>Other</span>
                        </div>
                        </div>
                    </div>
                </div>
                {/* 声乐 */}
                <div className={styles.part1}>
                    <div className='wrap'>
                        <div className="module-title">
                            <strong>声乐</strong>
                            <p className="subhead">VOCAL ARTS</p>
                        </div>
                        <div className={styles.threelist}>
                            <div className={styles.item}>
                                <div className={styles.pic}>
                                    <img src={require('../../assets/images/sy1.png')} alt='img'/>
                                </div>
                                <div className={styles.name}>
                                    <strong>古典演唱</strong>
                                    Classical Voice
                                </div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.pic}>
                                    <img src={require('../../assets/images/sy2.png')} alt='img'/>
                                </div>
                                <div className={styles.name}>
                                    <strong>流行演唱</strong>
                                    Popular Music Performance
                                </div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.pic}>
                                    <img src={require('../../assets/images/sy3.png')} alt='img'/>
                                </div>
                                <div className={styles.name}>
                                    <strong>音乐剧</strong>
                                    Musical Theatre
                                </div>
                            </div>
                        </div>
                        <div className="module-title">
                            <strong>键盘</strong>
                            <p className="subhead">KEYBOARD</p>
                        </div>
                        <div className={styles.threelist}>
                            <div className={styles.item}>
                                <div className={styles.pic}>
                                    <img src={require('../../assets/images/jp1.png')} alt='img'/>
                                </div>
                                <div className={styles.name}>
                                    <strong>钢琴</strong>
                                    Piano
                                </div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.pic}>
                                    <img src={require('../../assets/images/jp2.png')} alt='img'/>
                                </div>
                                <div className={styles.name}>
                                    <strong>钢琴伴奏</strong>
                                    Piano Accompaniment
                                </div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.pic}>
                                    <img src={require('../../assets/images/jp3.png')} alt='img'/>
                                </div>
                                <div className={styles.name}>
                                    <strong>大键琴</strong>
                                    Harpsichord
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* 弦乐 */}
                <div className='wrap'>
                    <div className={styles.padB50}>
                    <div className="module-title">
                        <strong className='col333'>弦乐</strong>
                        <p className="subhead">STRINGS</p>
                    </div>  
                    <div className={styles.fivelist}>
                        <div className={styles.item}>
                            <div className={styles.pic}>
                                <img src={require('../../assets/images/xy1.png')} alt='img'/>
                            </div>
                            <div className={styles.name}>
                                <p>大提琴</p>
                                Cello
                            </div>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.pic}>
                                <img src={require('../../assets/images/xy2.png')} alt='img'/>
                            </div>
                            <div className={styles.name}>
                                <p>小提琴</p>
                                Violin
                            </div>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.pic}>
                                <img src={require('../../assets/images/xy3.png')} alt='img'/>
                            </div>
                            <div className={styles.name}>
                                <p>低音提琴</p>
                                Double Bass
                            </div>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.pic}>
                                <img src={require('../../assets/images/xy4.png')} alt='img'/>
                            </div>
                            <div className={styles.name}>
                                <p>竖琴</p>
                                Harp
                            </div>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.pic}>
                                <img src={require('../../assets/images/xy5.png')} alt='img'/>
                            </div>
                            <div className={styles.name}>
                                <p>中提琴</p>
                                Viola
                            </div>
                        </div>
                    </div>
                    <div className="module-title">
                        <strong className='col333'>木管乐器</strong>
                        <p className="subhead">WOODWINDS</p>
                    </div>
                    <div className={styles.fivelist}>
                        <div className={styles.item}>
                            <div className={styles.pic}>
                                <img src={require('../../assets/images/mg1.png')} alt='img'/>
                            </div>
                            <div className={styles.name}>
                                <p>长笛</p>
                                Flute
                            </div>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.pic}>
                                <img src={require('../../assets/images/mg2.png')} alt='img'/>
                            </div>
                            <div className={styles.name}>
                                <p>单簧管</p>
                                Clarinet
                            </div>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.pic}>
                                <img src={require('../../assets/images/mg3.png')} alt='img'/>
                            </div>
                            <div className={styles.name}>
                                <p>双簧管</p>
                                Oboe
                            </div>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.pic}>
                                <img src={require('../../assets/images/mg4.png')} alt='img'/>
                            </div>
                            <div className={styles.name}>
                                <p>巴松管</p>
                                Bassoon
                            </div>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.pic}>
                                <img src={require('../../assets/images/mg5.png')} alt='img'/>
                            </div>
                            <div className={styles.name}>
                                <p>萨克斯管</p>
                                Saxophone
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* 铜管乐器 */}
                <div className={styles.part2}>
                    <div className='wrap'>
                        <div className="module-title">
                            <strong>铜管乐器</strong>
                            <p className="subhead">BRASS</p>
                        </div>
                        <div className={styles.fourlist}>
                            <div className={styles.item}>
                                <div className={styles.pic}>
                                    <img src={require('../../assets/images/tg1.png')} alt='img'/>
                                </div>
                                <div className={styles.name}>
                                    <strong>圆号</strong>
                                    Horn
                                </div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.pic}>
                                    <img src={require('../../assets/images/tg2.png')} alt='img'/>
                                </div>
                                <div className={styles.name}>
                                    <strong>长号</strong>
                                    Trombone
                                </div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.pic}>
                                    <img src={require('../../assets/images/tg3.png')} alt='img'/>
                                </div>
                                <div className={styles.name}>
                                    <strong>小号</strong>
                                    Trumpet
                                </div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.pic}>
                                    <img src={require('../../assets/images/tg4.png')} alt='img'/>
                                </div>
                                <div className={styles.name}>
                                    <strong>大号</strong>
                                    Tuba
                                </div>
                            </div>
                        </div>
                        <div className="module-title">
                            <strong>作曲</strong>
                            <p className="subhead">COMPOSITION</p>
                        </div>
                        <div className={styles.fourlist}>
                            <div className={styles.item}>
                                <div className={styles.pic}>
                                    <img src={require('../../assets/images/zq1.png')} alt='img'/>
                                </div>
                                <div className={styles.name}>
                                    <p>古典作品</p>
                                    Classical Composition
                                </div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.pic}>
                                    <img src={require('../../assets/images/zq2.png')} alt='img'/>
                                </div>
                                <div className={styles.name}>
                                    <p>电声音乐作曲</p>
                                    Electronic Music Composition
                                </div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.pic}>
                                    <img src={require('../../assets/images/zq3.png')} alt='img'/>
                                </div>
                                <div className={styles.name}>
                                    <p>流行音乐作曲</p>
                                    Popular Music Composition
                                </div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.pic}>
                                    <img src={require('../../assets/images/zq4.png')} alt='img'/>
                                </div>
                                <div className={styles.name}>
                                    <p>现代媒体音乐创作</p>
                                    Media Music Composition
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* 制作 */}
                <div className='wrap'>
                    <div className="module-title">
                        <strong className='col333'>制作</strong>
                        <p className="subhead">PRODUCTION</p>
                    </div>
                    <div className={styles.padB50}>
                    <div className={`${styles.fourlist} ${styles.col333}`}>
                        <div className={styles.item}>
                            <div className={styles.pic}>
                                <img src={require('../../assets/images/zz1.png')} alt='img'/>
                            </div>
                            <div className={styles.name}>
                                <p>录音艺术</p>
                                Recording Art
                            </div>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.pic}>
                                <img src={require('../../assets/images/zz2.png')} alt='img'/>
                            </div>
                            <div className={styles.name}>
                                <p>音乐制作</p>
                                Music Production
                            </div>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.pic}>
                                <img src={require('../../assets/images/zz3.png')} alt='img'/>
                            </div>
                            <div className={styles.name}>
                                <p>音乐技术</p>
                                Music Technology
                            </div>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.pic}>
                                <img src={require('../../assets/images/zz4.png')} alt='img'/>
                            </div>
                            <div className={styles.name}>
                                <p>影视多媒体配乐</p>
                                Film and TV Multimedia Music
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* 其他专业 */}
                <div className={styles.part3}>
                    <div className='wrap'>
                        <div className="module-title">
                            <strong className='col333'>其他专业</strong>
                            <p className="subhead">OTHER MAJORS</p>
                        </div>
                        <div className={`${styles.fourlist} ${styles.col333}`}>
                            <div className={styles.item}>
                                <div className={styles.pic}>
                                    <img src={require('../../assets/images/qt1.png')} alt='img'/>
                                </div>
                                <div className={styles.name}>
                                    <p>音乐学</p>
                                    Musicology
                                </div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.pic}>
                                    <img src={require('../../assets/images/qt2.png')} alt='img'/>
                                </div>
                                <div className={styles.name}>
                                    <p>音乐教育</p>
                                    Music Education
                                </div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.pic}>
                                    <img src={require('../../assets/images/qt3.png')} alt='img'/>
                                </div>
                                <div className={styles.name}>
                                    <p>音乐治疗</p>
                                    Music Therapy
                                </div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.pic}>
                                    <img src={require('../../assets/images/qt4.png')} alt='img'/>
                                </div>
                                <div className={styles.name}>
                                    <p>指挥</p>
                                    Conducting
                                </div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.pic}>
                                    <img src={require('../../assets/images/qt5.png')} alt='img'/>
                                </div>
                                <div className={styles.name}>
                                    <p>打击乐</p>
                                    Percussion
                                </div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.pic}>
                                    <img src={require('../../assets/images/qt6.png')} alt='img'/>
                                </div>
                                <div className={styles.name}>
                                    <p>室内乐</p>
                                    Chamber Music
                                </div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.pic}>
                                    <img src={require('../../assets/images/qt7.png')} alt='img'/>
                                </div>
                                <div className={styles.name}>
                                    <p>音乐理论</p>
                                    Music Theory
                                </div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.pic}>
                                    <img src={require('../../assets/images/qt8.png')} alt='img'/>
                                </div>
                                <div className={styles.name}>
                                    <p>艺术管理</p>
                                    Arts Managemen
                                </div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.pic}>
                                    <img src={require('../../assets/images/qt9.png')} alt='img'/>
                                </div>
                                <div className={styles.name}>
                                    <p>音乐管理</p>
                                    Music Management
                                </div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.pic}>
                                    <img src={require('../../assets/images/qt10.png')} alt='img'/>
                                </div>
                                <div className={styles.name}>
                                    <p>音乐商务</p>
                                    Music Business
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* 活动 */}
                <div className={styles.ouractivity}>
                <div className='wrap'>
                    <div class="module-title">
                    <strong>我们的活动</strong>
                    <p class="subhead">OUR ACTIVITIES</p>
                    </div>
                    <div className={styles.list}>
                    <div className={styles.item}>
                    <a href='/activity/competition'><img src={require('../../assets/images/activities1.png')} alt='act'/></a>
                    <div className={styles.btn}>国际赛事</div>
                    </div>
                    <div className={styles.item}>
                    <a href='/activity/events'><img src={require('../../assets/images/activities2.png')} alt='act'/></a>
                    <div className={styles.btn}>国际音乐节</div>
                    </div>
                    </div>
                </div>
                </div>
                {/* 底部 */}
                <Footer />
            </div>
        )
    }
}
export default Major