import React, { Component } from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Swiper from 'swiper/dist/js/swiper.js'
import 'swiper/dist/css/swiper.min.css'
import styles from './Teacher.css'
import '../../config'
import { Link } from 'react-router-dom'
import Banner from '../../components/Banner/Banner'
import Layer from '../../components/Layer/Layer'

class Teacher extends Component {
  constructor(props){
    super(props)
    this.state = {
      videoList:[],
      freevideo:[],
      videoUrl:'',
      videoIsShow:false,
      videoImg:'',
      allTeacher:[],
      overseasLenArr: [], // 导师分页数组
      teacherIsShow: false,
      tea_head: '',
      tea_en_name: '',
      tea_name: '',
      tea_title: '',
      tea_school: '',
      tea_intro: '',
      bannerArr: [],
      layershow: false // 提交个人信息弹层显示隐藏
    }
  }
  toVideo(e){
    let url = e.target.getAttribute('data-video')
    this.setState({
      videoUrl: url,
      videoIsShow:true
    })
  }
  closeVideo(e){
    this.setState({
      videoUrl: '',
      videoIsShow: false
    })
  }
  handleAssessment(e){
    e.stopPropagation()
    this.setState({
      teacherIsShow: false,
      layershow: true
    })
  }
  handleTeacher(e){
    let tea_head = e.currentTarget.getAttribute('data-head'),
        tea_en_name = e.currentTarget.getAttribute('data-enname'),
        tea_name = e.currentTarget.getAttribute('data-name'),
        tea_title = e.currentTarget.getAttribute('data-title'),
        tea_school = e.currentTarget.getAttribute('data-school'),
        tea_intro = e.currentTarget.getAttribute('data-intro')
    
    this.setState({
      tea_head,
      tea_en_name,
      tea_name,
      tea_title,
      tea_school,
      tea_intro,
      teacherIsShow: true,
      layershow: false
    })
  }
  closeTeacher(e){
    this.setState({
      teacherIsShow: false
    })
  }
  componentDidMount(){
    // banner
    fetch(global.constants.api_url + '/v1/banner/list?location=4', {
      method: 'GET'
    })
    .then( response => {
      response.json().then( data => {
        this.setState({
          bannerArr: data.data
        })
      })
    })
    .catch( error => {
      console.log(error)
    })
    // 访谈视频
    fetch(global.constants.api_url + '/v1/article/limitlist?limit=3&channel_id=91', {
      method: 'GET'
    })
    .then( response => {
      response.json().then( data => {
        this.setState({
          videoList: data.data
        })
        new Swiper('.swiper-container4', {
          centeredSlides: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        });

      })
    })
    .catch( error => {
      console.log(error)
    })
    // 名师
    fetch(global.constants.api_url + '/v1/jury/getlist?page_size=100', {
      method: 'GET'
    })
    .then( response => {
      response.json().then( data => {
        let len = Math.floor(data.data.data.length/15),
            arr = []
        for(var i=0; i<len; i++){
          arr.push(i)
        }
        this.setState({
          allTeacher: data.data.data,
          overseasLenArr: arr
        })
        // new Swiper('.swiper-container-s2', {
        //   slidesPerView: 5,
        //   slidesPerColumn: 3,
        //   spaceBetween: 20,
        //   pagination: {
        //     el: '.swiper-pagination-s2',
        //     clickable: true,
        //   },
        //   navigation: {
        //     nextEl: '.swiper-button-next',
        //     prevEl: '.swiper-button-prev',
        //   }
        // })
        new Swiper('.swiper-container-s2',{
          autoplay: {
            delay: 5000,
            stopOnLastSlide: false
          },
          speed:1000,
          autoplayDisableOnInteraction : false,
          loop:true,
          spaceBetween: 20,
          centeredSlides : true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }
        })
      })
    })
    .catch( error => {
      console.log(error)
    })
    // 精选课程
    fetch(global.constants.api_url + '/v1/course/getfreevideo', {
      method: 'GET'
    })
    .then( response => {
      response.json().then( data => {
        this.setState({
          freevideo: data.data
        })
      })
    })
    .catch( error => {
      console.log(error)
    })
  
  }
  componentWillReceiveProps(nextProps){
    if(this.props.location !== nextProps.location){
      window.scrollTo(0,0)
    }
  }
  render(){
    const { videoUrl, videoIsShow, allTeacher, teacherIsShow, tea_head, tea_en_name, tea_name, tea_title, tea_school, tea_intro, bannerArr, layershow, overseasLenArr }  = this.state
    return (
      <div>
        {/* 头部 */}
        <Header />
        {/* baner */}
        <div className='banner'>
          <Banner banner={bannerArr}/>
        </div>
        <div className={styles.bg2e}>
          <div className="wrap">
            {/* 导师优势 */}
            <div className="module-title">
              <strong>导师优势</strong>
              <p className="subhead">MENTORS FEATURE</p>
            </div>
            <div className={styles.advantage}>
              <ul>
                <li>
                  <i className={styles.icon1}></i>
                  <span>师资背景</span>
                  <p>1000+海外知名教授（来自茱莉亚音乐学院、曼哈顿音乐学院、新英格兰音乐学院、伊斯特曼音乐学院等300多所合作院校的在职专业教授）</p>
                </li>
                <li>
                  <i className={styles.icon2}></i>
                  <span>专业覆盖</span>
                  <p>24个院系，235个专业（覆盖声乐、音乐剧、钢琴、流行表演、管弦乐、爵士、作曲、音乐制作、音乐教育、艺术管理等全部理论和表演专业）</p>
                </li>
                <li>
                  <i className={styles.icon3}></i>
                  <span>教学成果</span>
                  <p>800+成功案例（著名音乐院校导师按照国外院校录取标准教授专业课程，量身定制选择作品集和面试曲目，有效提升您的留学成功几率）</p>
                </li>
                <li>
                  <i className={styles.icon4}></i>
                  <span>授课模式</span>
                  <p>线上与面授灵活选择（身临其境的在线课与面授课灵活转化与搭配，个性化选择适合自身学习目标的授课形式，起到事半功倍的效果）</p>
                </li>
              </ul>
            </div>
            
          </div>
        </div>
        <div className="wrap">
          {/* 访谈视频 */}
          <div className="module-title">
            <strong className="col333">访谈视频</strong>
            <p className="subhead">VIDEO INTERVIEW</p>
          </div>
        </div>
        <div className={styles['video-list']}>
          <div className="swiper-container4">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className={styles.videos}>
                  <div className={styles.nr}>
                    <div className={styles.pic}>
                      <img src='http://imagecdn.yinyuewujie.com/FjVtoddK_i1JT5RISvfUgxRXpb6Q.png' data-video='http://cdn.yinyuewujie.com/lg3tBSUBhvGpoK39Qv71aNMxl-q-.mp4' onClick={this.toVideo.bind(this)} alt='img'/>
                    </div>
                    <div className={styles.bz}>
                      <span>罗斯福大学芝加哥表演艺术学院-亚当·内曼（Adam Neiman）-访谈视频</span>
                      <div className={styles.playbtn} data-video='http://cdn.yinyuewujie.com/lg3tBSUBhvGpoK39Qv71aNMxl-q-.mp4' onClick={this.toVideo.bind(this)}></div>
                    </div>
                  </div>
                  <div className={styles.nr}>
                    <div className={styles.pic}>
                      <img src='http://imagecdn.yinyuewujie.com/FsgB6fOWz3miCJyc-DiHprwI-tkj.png' data-video='http://cdn.yinyuewujie.com/lrwR0KnZP4clGr_ABm6Lvfpm0JW-.mp4' onClick={this.toVideo.bind(this)} alt='img'/>
                    </div>
                    <div className={styles.bz}>
                      <span>德克萨斯基督大学（沃思堡）-塔玛什·翁格（Tamás Ungár）-访谈视频</span>
                      <div className={styles.playbtn} data-video='http://cdn.yinyuewujie.com/lrwR0KnZP4clGr_ABm6Lvfpm0JW-.mp4' onClick={this.toVideo.bind(this)}></div>
                    </div>
                  </div>
                  <div className={styles.nr}>
                    <div className={styles.pic}>
                      <img src='http://imagecdn.yinyuewujie.com/FlNt1oQH1SGNtaUkXo1lRuVq1uJh.png' data-video='http://cdn.yinyuewujie.com/lvErcjAgDOj7XK3roYkH7Hdl9wjq.mp4' onClick={this.toVideo.bind(this)} alt='img'/>
                    </div>
                    <div className={styles.bz}>
                      <span>波士顿大学音乐学院-吉拉·戈德斯坦（Gila Goldstein）-访谈视频</span>
                      <div className={styles.playbtn} data-video='http://cdn.yinyuewujie.com/lvErcjAgDOj7XK3roYkH7Hdl9wjq.mp4' onClick={this.toVideo.bind(this)}></div>
                    </div>
                  </div>
                  <div className={styles.nr}>
                    <div className={styles.pic}>
                      <img src={require('../../assets/images/v1.png')} data-video='http://cdn.yinyuewujie.com/lpjEjrdMZn7zL7rksmwNRiVmXyBc.mp4' onClick={this.toVideo.bind(this)} alt='img'/>
                    </div>
                    <div className={styles.bz}>
                      <span>中佛罗里达州立大学-许韵玲-访谈视频</span>
                      <div className={styles.playbtn} data-video='http://cdn.yinyuewujie.com/lpjEjrdMZn7zL7rksmwNRiVmXyBc.mp4' onClick={this.toVideo.bind(this)}></div>
                    </div>
                  </div>
                  <div className={styles.nr}>
                    <div className={styles.pic}>
                      <img src={require('../../assets/images/v2.png')} data-video='http://cdn.yinyuewujie.com/lvcABAcwyaCimE-0XwCzJjf1eAA4.mp4' onClick={this.toVideo.bind(this)} alt='img'/>
                    </div>
                    <div className={styles.bz}>
                      <span>俄克拉荷马大学-韩贞媛-访谈视频</span>
                      <div className={styles.playbtn} data-video='http://cdn.yinyuewujie.com/lvcABAcwyaCimE-0XwCzJjf1eAA4.mp4' onClick={this.toVideo.bind(this)}></div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
            <div className="swiper-button-next"></div>
            <div className="swiper-button-prev"></div>
          </div>
          
        </div>
        {/* 教研总监 */}
        <div className={styles.director}>
          <div className="wrap">
              <div className="module-title">
                <strong>教研总监</strong>
                <p className="subhead">DIRECTOR OF TEACHING AND RESEARCH </p>
              </div>
              <div className={styles.personage}>
                <img className={styles.head} src={require('../../assets/images/lm.png')} alt=""/>
                <div className={styles['per-info']}>
                  <div className={styles.title}><span>李莫</span><span>Li Mo</span></div>
                  <p>李莫博士，美国俄亥俄州立大学音乐博士，哥伦比亚大学嗓音病理学硕士，现为多所美国著名音乐院校特聘声乐教授、嗓音病理学专家。</p>
                  <p>针对中国音乐学生特点，李莫博士与伊斯特曼、茱莉亚、新英格兰、伯克利等多所音乐院校不同专业的著名音乐教育家组成LBM教研团队，共同研发制定了独有的音乐教育与留学七大体系，最大化发挥LBM海外资源优势，保障学生提升专业水平的同时，更好地与国际音乐教育接轨。</p>
                </div>
              </div>
          </div>
        </div>
        {/* 海外名师 */}
        <div className={styles['ramous-teacher']}>
          <div className="wrap">  
            <div className="module-title">
                <strong>海外名师</strong>
                <p className="subhead">MORE TEACHERS</p>
              <Link className="button2" to='/teacher/list'>更多名师</Link>
            </div>
            <div className={`list-four list2 marRes`}>
              <div className="swiper-container-s2">
                <ul className="swiper-wrapper">
                  {
                    overseasLenArr.map((page) => {
                      return (
                        <li className="swiper-slide" key={page} style={{background: 'none', height: 'auto'}}>
                          <ul style={{margin: '-20px 0 0 -20px'}}>
                            {
                            allTeacher.map((item, index) => {
                              if(index >= page*15 && index < (page + 1)*15){
                                return (
                                  <li key={index} style={{width: '224px', margin: '20px 0 0 20px', float: 'left'}}>
                                    <div className="pic" data-head={item.avatar} data-enname={item.en_name} data-name={item.name} data-title={item.title} data-school={item.school} data-intro={item.intro} onClick={this.handleTeacher.bind(this)}>
                                      <img src={item.avatar} alt=""/>
                                    </div>
                                    <div className="info">
                                      <p className="col666">{item.en_name}</p>
                                      <p className="bold">{item.name}</p>
                                      <p>{item.title}</p>
                                      <p>{item.school}</p>
                                    </div>
                                  </li>
                                )
                              }
                              return ''
                            })
                            }
                          </ul>
                        </li>
                      )
                    })
                  }
                </ul>
                {/* <div className={['swiper-pagination swiper-pagination-s2']}></div> */}
                <div className="swiper-button-next"></div>
                <div className="swiper-button-prev"></div>
              </div>
            </div>
          </div>
        </div>
        {/* 精选课程 */}
        <div className={styles['course-list']}>
          <div className="wrap">
            <div className="module-title">
                <strong className="col333">精选产品</strong>
                <p className="subhead">SELECTED COURSES</p>
            </div>
            <ul className='products-list'>
              <li className='long'>
                <img src={require('../../assets/images/pr1.png')} alt=''/>
                <div className='tit tit1'></div>
                <div className='info'>量身定制的留学规划方案，留学前、中、后贴心的全流程服务体系，强大的院校和教授资源是您留学成功的有力保障</div>
              </li>
              <li className='long'>
                <img src={require('../../assets/images/pr2.png')} alt=''/>
                <div className='tit tit2'></div>
                <div className='info'>海外音乐院校教授教授专业课程，全面提升音乐专业水平，更早的适应美国院校教学环境</div>
              </li>
              <li className='short'>
                <img src={require('../../assets/images/pr3.png')} alt=''/>
                <div className='tit tit3'></div>
                <div className='info'>自主研发高保真授课系统，让学员足不出户就可以与海外名师学习，获得与现场学习一样的教学收获</div>
              </li>
              <li className='short'>
                <img src={require('../../assets/images/pr4.png')} alt=''/>
                <div className='tit tit4'></div>
                <div className='info'>音乐留学直通车，再获得美国教授比赛专业评语的同时，还有机会直接获得美国院校现场颁发的《专业录取通知书》</div>
              </li>
              <li className='short'>
                <img src={require('../../assets/images/pr5.png')} alt=''/>
                <div className='tit tit5'></div>
                <div className='info'>精彩难忘的音乐学习之旅，美国音乐留学成功的摇篮</div>
              </li>
            </ul>
          </div>
        </div>
        {/* 底部 */}
        <Footer />
        {/* 评估 */}
       <Layer show={layershow}/>
        {/* 视频弹层 */}
        <div className={styles.popbg} hidden={!videoIsShow}>
          <div className={styles.videobox}>
            <video src={videoUrl} controls="controls"></video>
            <div className={styles['video-close']} onClick={this.closeVideo.bind(this)}></div>
          </div>
        </div>
        {/* 导师简介弹层 */}
        <div className={styles.popbg} hidden={!teacherIsShow}>
          <div className='teacherInfo'>
            <div className='left'>
              <img src={tea_head} alt='head'/>
              <div className='teainfo'>
                <span>{tea_en_name}</span>
                <p>{tea_name}</p>
                <span>{tea_title}</span>
                <p>{tea_school}</p>
              </div>
            </div>
            <div className='right'>
              <div className='jjname'>导师简介</div>
              <div className='jjcon' dangerouslySetInnerHTML={{ __html: tea_intro }}></div>
            </div>
            <div className='tBtns'>
              <div className='btn1' onClick={this.handleAssessment.bind(this)}>预约大师课</div>
              <div className='btn2' onClick={this.handleAssessment.bind(this)}>请TA评估</div>
            </div>
            <div className='pop-close' onClick={this.closeTeacher.bind(this)}></div>
          </div>
        </div>
      </div>
    )
  }
}

export default Teacher